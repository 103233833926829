import { type HTMLAttributes } from "react";
import { IconsList } from "../../components";

export enum ButtonVariant {
  primary = "tw-bg-green-500 tw-text-black",
  secondary = "tw-bg-gray-700 tw-text-gray-200",
  secondary2 = "tw-bg-gray-500 tw-text-white",
  tertiary = "tw-bg-black tw-text-gray-300",
  danger = "tw-bg-red-500 tw-text-black",
  primary2 = "tw-bg-blue-600 tw-text-white",
}

// Add more icon variants as needed in the design
export enum OutlinedIconButtonVariant {
  secondary = "tw-border tw-border-gray-500 tw-text-gray-400 hover:tw-bg-gray-500 hover:tw-bg-opacity-20",
}

export enum OutlinedButtonVariant {
  secondary = "tw-border tw-border-gray-500 tw-bg-gray-700 tw-text-gray-200",
}

export enum IconVariant {
  primary = "tw-text-black",
  secondary = "tw-text-gray-200",
  secondary2 = "tw-text-gray-100",
  tertiary = "tw-text-gray-300",
  danger = "tw-text-black",
  primary2 = "tw-text-white",
}

export enum ButtonSize {
  sm = "tw-px-2.5 tw-py-1 tw-text-xs",
  md = "tw-px-3 tw-py-1.5 tw-text-sm",
  lg = "tw-px-4 tw-py-2.5 tw-text-sm",
}

export enum IconButtonSize {
  xs = "tw-px-0.5 tw-py-0.5 tw-text-xs",
  sm = "tw-px-1 tw-py-1 tw-text-xs",
  md = "tw-px-1.5 tw-py-1.5 tw-text-sm",
  lg = "tw-px-2.5 tw-py-2.5 tw-text-sm",
}

interface CommonButtonProps extends HTMLAttributes<HTMLButtonElement> {
  variant?: keyof typeof ButtonVariant;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
}

export interface ButtonProps extends CommonButtonProps {
  label: string;
  size?: keyof typeof ButtonSize;
  leftIcon?: IconsList;
  rightIcon?: IconsList;
  outlined?: boolean;
  dataTestId: string; // keeping it mandatory so that it can not be missed
}

export interface IconButtonProps extends CommonButtonProps {
  icon: IconsList;
  size?: keyof typeof IconButtonSize;
  outlined?: boolean;
  dataTestId: string; // keeping it mandatory so that it can not be missed
}
