import React, { useEffect, useLayoutEffect, type FC, useState } from "react";
import { useAtom } from "jotai";
import { useLocation } from "@reach/router";

import SidebarHeader from "./components/sidebarHeader";
import SidebarBody from "./components/sidebarBody";
import SidebarFooter from "./components/sidebarFooter";
import { NavigationType } from "./sidebar-types";

import {
  sidebarCollapsedAtom,
  sidebarHoveredAtom,
} from "../../stores/page.store";

interface SidebarProps {
  activeUrlPath: string;
  isQuickAppStackCreationRouteActive: boolean;
  isAccountSettingsRouteActive: boolean;
}

const Sidebar: FC<SidebarProps> = ({
  activeUrlPath,
  isQuickAppStackCreationRouteActive = false,
  isAccountSettingsRouteActive = false,
}) => {
  const [isSidebarHovered, setIsSidebarHovered] = useAtom(sidebarHoveredAtom);
  const [sidebarCollapsed, setSidebarCollapsed] = useAtom(sidebarCollapsedAtom);
  const [hideSidebarBody, setHideSidebarBody] = useState(false);
  const [navigationType, setNavigationType] =
    useState<NavigationType>("DEFAULT");
  const location = useLocation();

  // redirect to profile page as default route when no sub-route is provided by user user tries to navigate to account-settings page
  useLayoutEffect(() => {
    setHideSidebarBody(false);
    if (isAccountSettingsRouteActive) {
      setSidebarCollapsed(false); // open sidebar by default when navigating to account settings
      setNavigationType("ACCOUNT_SETTINGS");
    } else if (isQuickAppStackCreationRouteActive) {
      setHideSidebarBody(true);
      setSidebarCollapsed(true);
    } else {
      setNavigationType("DEFAULT");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (sidebarCollapsed) {
      setIsSidebarHovered(false); // collapsing sidebar without hovering out
    }
  }, [sidebarCollapsed]);

  const toggleSidebarCollapse = () => {
    if (!sidebarCollapsed) setIsSidebarHovered(false);
    setSidebarCollapsed(!sidebarCollapsed);
  };
  return (
    <aside
      className={`tw-h-full tw-z-10 tw-duration-150 ${
        !isSidebarHovered && sidebarCollapsed
          ? "tw-w-14 tw-min-w-14"
          : "tw-w-1/5 tw-min-w-72"
      }`}
      onMouseEnter={() => sidebarCollapsed && setIsSidebarHovered(true)}
      onMouseLeave={() => sidebarCollapsed && setIsSidebarHovered(false)}
    >
      <div className="tw-h-full tw-flex tw-justify-between tw-flex-col tw-bg-gray-800 tw-border-r tw-border-gray-700 tw-gap-2">
        <SidebarHeader
          toggleSidebarCollapse={toggleSidebarCollapse}
          navigationType={navigationType}
        />
        <div className="tw-flex-1 tw-overflow-auto">
          {!hideSidebarBody && (
            <SidebarBody
              activeUrlPath={activeUrlPath}
              navigationType={navigationType}
            />
          )}
        </div>
        {navigationType === "DEFAULT" && <SidebarFooter />}
      </div>
    </aside>
  );
};

export default Sidebar;
