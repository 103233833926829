import { type ReactNode } from "react";
import { AWSIconsList, IconsList } from "../../components";

export interface SelectableTileProps {
  title: string;
  icon?: AWSIconsList | IconsList;
  type: SelectionType;
  name: string;
  id: string;
  value: string;
  isSelected?: boolean;
  disabled?: boolean;
  onSelectionToggle?: (value: boolean) => void;
  viewDetailsLink?: string;
  customSubtitleRenderer?: ReactNode;
  customIconRenderer?: ReactNode;
  defaultTileContentRenderer?: ReactNode;
  dataTestId: string;
  onTileClick?: (value: string) => void;
}

export enum SelectionType {
  SWITCH = "SWITCH",
  RADIO = "RADIO",
  DEFAULT = "DEFAULT",
  CHECKBOX = "CHECKBOX",
}
