import { ResponseError, RequiredError, FetchError } from "../apis/appcd";
import { goToLogin } from "./";

export const ErrorHandler = async (
  error: ResponseError | RequiredError | FetchError | any
): Promise<any> => {
  const errorName = error?.name;
  if (errorName === "ResponseError") {
    if (error?.response?.status === 404) {
      return { code: 404, msg: "Not Found" };
    } else if (error?.response?.status === 500) {
      return {
        msg: "Internal Server Error",
        extras: ["Something went wrong, please try after sometime."],
      };
    } else if (
      error?.response?.status === 401 ||
      error?.response?.status === 403
    ) {
      goToLogin(); // logout user if user session is expired
    }
    const err_resp = await (error as ResponseError).response.json();
    return err_resp;
  } else if (errorName === "RequiredError") {
    return (error as RequiredError).field;
  } else if (errorName === "FetchError") {
    return (error as FetchError).cause;
  } else {
    console.error(error);
    return error;
  }
};
