/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Variable } from './Variable';
import {
    VariableFromJSON,
    VariableFromJSONTyped,
    VariableToJSON,
} from './Variable';

/**
 * 
 * @export
 * @interface CustomTemplateReq
 */
export interface CustomTemplateReq {
    /**
     * 
     * @type {string}
     * @memberof CustomTemplateReq
     */
    resourceType: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTemplateReq
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTemplateReq
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTemplateReq
     */
    provider: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTemplateReq
     */
    template: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTemplateReq
     */
    iacType: string;
    /**
     * 
     * @type {Array<Variable>}
     * @memberof CustomTemplateReq
     */
    variables?: Array<Variable>;
    /**
     * 
     * @type {string}
     * @memberof CustomTemplateReq
     */
    iamPolicy?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTemplateReq
     */
    resourceId?: string;
}

/**
 * Check if a given object implements the CustomTemplateReq interface.
 */
export function instanceOfCustomTemplateReq(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "resourceType" in value;
    isInstance = isInstance && "label" in value;
    isInstance = isInstance && "provider" in value;
    isInstance = isInstance && "template" in value;
    isInstance = isInstance && "iacType" in value;

    return isInstance;
}

export function CustomTemplateReqFromJSON(json: any): CustomTemplateReq {
    return CustomTemplateReqFromJSONTyped(json, false);
}

export function CustomTemplateReqFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomTemplateReq {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resourceType': json['resourceType'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'label': json['label'],
        'provider': json['provider'],
        'template': json['template'],
        'iacType': json['iacType'],
        'variables': !exists(json, 'variables') ? undefined : ((json['variables'] as Array<any>).map(VariableFromJSON)),
        'iamPolicy': !exists(json, 'iamPolicy') ? undefined : json['iamPolicy'],
        'resourceId': !exists(json, 'resourceId') ? undefined : json['resourceId'],
    };
}

export function CustomTemplateReqToJSON(value?: CustomTemplateReq | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resourceType': value.resourceType,
        'description': value.description,
        'label': value.label,
        'provider': value.provider,
        'template': value.template,
        'iacType': value.iacType,
        'variables': value.variables === undefined ? undefined : ((value.variables as Array<any>).map(VariableToJSON)),
        'iamPolicy': value.iamPolicy,
        'resourceId': value.resourceId,
    };
}

