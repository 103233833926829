/* tslint:disable */
/* eslint-disable */
/**
 * Virtual Interaction and Recommendation Assistant - VIRA
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TopologyResourceConnectionData } from './TopologyResourceConnectionData';
import {
    TopologyResourceConnectionDataFromJSON,
    TopologyResourceConnectionDataFromJSONTyped,
    TopologyResourceConnectionDataToJSON,
} from './TopologyResourceConnectionData';

/**
 * 
 * @export
 * @interface TopologyResourceConnection
 */
export interface TopologyResourceConnection {
    /**
     * 
     * @type {string}
     * @memberof TopologyResourceConnection
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TopologyResourceConnection
     */
    sourceResourceId?: string;
    /**
     * 
     * @type {string}
     * @memberof TopologyResourceConnection
     */
    targetResourceId?: string;
    /**
     * 
     * @type {TopologyResourceConnectionData}
     * @memberof TopologyResourceConnection
     */
    data?: TopologyResourceConnectionData;
}

/**
 * Check if a given object implements the TopologyResourceConnection interface.
 */
export function instanceOfTopologyResourceConnection(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TopologyResourceConnectionFromJSON(json: any): TopologyResourceConnection {
    return TopologyResourceConnectionFromJSONTyped(json, false);
}

export function TopologyResourceConnectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopologyResourceConnection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'sourceResourceId': !exists(json, 'sourceResourceId') ? undefined : json['sourceResourceId'],
        'targetResourceId': !exists(json, 'targetResourceId') ? undefined : json['targetResourceId'],
        'data': !exists(json, 'data') ? undefined : TopologyResourceConnectionDataFromJSON(json['data']),
    };
}

export function TopologyResourceConnectionToJSON(value?: TopologyResourceConnection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sourceResourceId': value.sourceResourceId,
        'targetResourceId': value.targetResourceId,
        'data': TopologyResourceConnectionDataToJSON(value.data),
    };
}

