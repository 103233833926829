/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Policy,
  PolicyFilters,
} from '../models/index';
import {
    PolicyFromJSON,
    PolicyToJSON,
    PolicyFiltersFromJSON,
    PolicyFiltersToJSON,
} from '../models/index';

export interface GetPoliciesRequest {
    providers?: Array<string>;
    benchmarks?: Array<string>;
    categories?: Array<string>;
    policyIds?: Array<string>;
    severity?: Array<string>;
    resourceTypes?: Array<string>;
    types?: Array<string>;
}

/**
 * 
 */
export class PolicyApi extends runtime.BaseAPI {

    /**
     * Get  policies
     */
    async getPoliciesRaw(requestParameters: GetPoliciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Policy>>> {
        const queryParameters: any = {};

        if (requestParameters.providers) {
            queryParameters['providers'] = requestParameters.providers.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.benchmarks) {
            queryParameters['benchmarks'] = requestParameters.benchmarks.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.categories) {
            queryParameters['categories'] = requestParameters.categories.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.policyIds) {
            queryParameters['policyIds'] = requestParameters.policyIds.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.severity) {
            queryParameters['severity'] = requestParameters.severity.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.resourceTypes) {
            queryParameters['resourceTypes'] = requestParameters.resourceTypes.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.types) {
            queryParameters['types'] = requestParameters.types.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/policies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PolicyFromJSON));
    }

    /**
     * Get  policies
     */
    async getPolicies(requestParameters: GetPoliciesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Policy>> {
        const response = await this.getPoliciesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get  policy filters
     */
    async getPolicyFiltersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PolicyFilters>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/policy/filters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PolicyFiltersFromJSON));
    }

    /**
     * Get  policy filters
     */
    async getPolicyFilters(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PolicyFilters>> {
        const response = await this.getPolicyFiltersRaw(initOverrides);
        return await response.value();
    }

}
