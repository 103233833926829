import React, { FC } from "react";

import {
  AWSIcon,
  AWSIconsList,
  Icon,
  IconSize,
  IconsList,
  Tag,
} from "../../../components";
import type { configureTFVarsDialogSideNavItemProps } from "./configure-tf-vars-types";

const ConfigureTFVarsDialogSideNavItem: FC<
  configureTFVarsDialogSideNavItemProps
> = ({ resource, resourcesWithErrors, isSelected, hasError, onClick }) => {
  return (
    <div
      className={`tw-flex tw-items-center tw-px-4 tw-py-3.5 tw-w-full tw-justify-between tw-border-b tw-border-gray-700 tw-cursor-pointer ${
        isSelected
          ? "tw-bg-blue-500 tw-bg-opacity-10"
          : "hover:tw-bg-blue-500 hover:tw-bg-opacity-5"
      }`}
      onClick={onClick}
    >
      <div className="tw-flex tw-gap-3 tw-items-center">
        <AWSIcon
          name={
            AWSIconsList[resource?.resourceType as keyof typeof AWSIconsList]
          }
          alt={resource?.label || resource?.resourceTypeLabel}
          size={IconSize["2xl"]}
          fallbackPlaceholder={resource?.resourceType}
          className="tw-rounded"
        />
        <div className="tw-flex tw-flex-col tw-gap-0.5">
          <div className="tw-text-white tw-text-sm tw-font-semibold ">
            {resource.label || resource.resourceTypeLabel}
          </div>
          <div className="tw-text-gray-300 tw-text-xs tw-font-normal ">
            {resource.resourceTypeLabel}
          </div>
        </div>
      </div>
      <div className="tw-flex tw-gap-2 tw-ml-2">
        {hasError && (
          <Icon
            name={IconsList.TRIANGLE_EXCLAMATION}
            className="tw-text-red-400"
          />
        )}
        <Tag
          title={resourcesWithErrors?.errorFields?.length as number}
          dataTestId={`tf-vars-count-${resource.id}`}
        />
      </div>
    </div>
  );
};

export default ConfigureTFVarsDialogSideNavItem;
