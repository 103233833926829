import React, { useState } from "react";
import { Formik } from "formik";

import type { TopologyDiff } from "../../apis/topology";
import type { CompareTopologyProps } from "./topology-graph-types";

import {
  Card,
  SelectBox,
  Icon,
  IconsList,
  IconSize,
  Table,
  AWSIcon,
  AWSIconsList,
} from "../../components";

const CompareTopology = ({
  appStackVersions,
  graphData,
  getTopologyDiff,
  setShowTopologyDiff,
}: CompareTopologyProps) => {
  const [topologyDiffData, setTopologyDiffData] = useState<TopologyDiff>({});
  const [showTopologyDiffDetails, setShowTopologyDiffDetails] = useState(false);

  const getTopologyDiffTableRows = (
    rows_data: any[] | undefined,
    rows_type: "resource" | "connection",
    action_type: "created" | "deleted"
  ): any[] => {
    if (!rows_data || rows_data.length === 0) {
      return [];
    }

    return rows_data.map((row, index) => {
      if (rows_type === "connection") {
        row = {
          id: row.id,
          sourceResourceData: graphData.resources
            ? graphData.resources.find(
                (resource) => resource.id === row.sourceResourceId
              )
            : null,
          targetResourceData: graphData.resources
            ? graphData.resources.find(
                (resource) => resource.id === row.targetResourceId
              )
            : null,
        };
      }

      return {
        id: `${row.id}_${index}`,
        data: [
          {
            id: `${row.id}`,
            data: (
              <div className="tw-flex tw-gap-2 tw-items-center">
                <Icon
                  name={
                    action_type === "created" ? IconsList.ADD : IconsList.MINUS
                  }
                  size={IconSize.lg}
                  className={
                    action_type === "created"
                      ? "tw-text-green-400"
                      : "tw-text-red-400"
                  }
                />
                <div
                  className={`tw-text-xs ${
                    action_type === "created"
                      ? "tw-text-green-400"
                      : "tw-text-red-400"
                  }`}
                >
                  {action_type === "created" ? "Added" : "Removed"}{" "}
                  {rows_type === "resource" ? "Resource" : "Connection"}
                </div>
                <div className="tw-flex tw-gap-2 tw-items-center">
                  <AWSIcon
                    name={
                      AWSIconsList[
                        (rows_type === "resource"
                          ? row.resourceType
                          : row.sourceResourceData
                              ?.resourceType) as keyof typeof AWSIconsList
                      ]
                    }
                  />
                  <span className="tw-text-xs tw-text-gray-100">
                    {rows_type === "resource"
                      ? row.label
                      : row.sourceResourceData?.label}
                  </span>
                  {rows_type === "connection" && (
                    <>
                      <Icon
                        name={IconsList.ARROW_RIGHT}
                        size={IconSize.sm}
                        className="tw-text-gray-300"
                      />
                      <AWSIcon
                        name={
                          AWSIconsList[
                            row.targetResourceData
                              ?.resourceType as keyof typeof AWSIconsList
                          ]
                        }
                      />
                      <span className="tw-text-xs tw-text-gray-100">
                        {row.targetResourceData?.label}
                      </span>
                    </>
                  )}
                </div>
              </div>
            ),
          },
        ],
      };
    });
  };

  return (
    <Card padding>
      <div className="tw-flex tw-justify-between tw-gap-3">
        <div>
          <div className="tw-text-xs tw-font-semibold tw-text-gray-200">
            Compare with
          </div>
          <div className="tw-flex tw-gap-2 tw-items-center tw-mt-1">
            <div className="tw-flex tw-gap-2 tw-items-center">
              {appStackVersions && appStackVersions?.length > 1 ? (
                <Formik
                  initialValues={{
                    appstack_uuid: "",
                  }}
                  onSubmit={() => {
                    /* form submission is not needed, but onSubmit is required prop in Formik */
                  }}
                >
                  {({ setFieldValue }) => {
                    return (
                      <SelectBox
                        className="tw-w-96"
                        name="appstack_uuid"
                        options={appStackVersions?.reduce(
                          (
                            result: {
                              label: string;
                              value: string;
                            }[],
                            appStack
                          ) => {
                            if (graphData.appstackId !== appStack?.uuid) {
                              result.push({
                                label: `Version ${appStack?.version}`,
                                value: appStack?.uuid,
                              });
                            }
                            return result;
                          },
                          []
                        )}
                        placeholder="Select Version"
                        onChange={async (selected_option) => {
                          if (selected_option?.value) {
                            setFieldValue(
                              "appstack_uuid",
                              selected_option?.value
                            );
                            const topologyDiffResponse =
                              await getTopologyDiff?.(selected_option?.value);
                            if (topologyDiffResponse) {
                              setTopologyDiffData(topologyDiffResponse);
                            }
                          }
                        }}
                        dataTestId="compare-topology-select"
                      />
                    );
                  }}
                </Formik>
              ) : (
                <div className="tw-text-xssm">
                  There are no other versions to compare with.
                </div>
              )}
            </div>
            {Object.keys(topologyDiffData).length > 0 && (
              <div
                className="tw-flex tw-gap-1.5 tw-text-blue-500 tw-cursor-pointer"
                onClick={() =>
                  setShowTopologyDiffDetails(
                    (showTopologyDiffDetails) => !showTopologyDiffDetails
                  )
                }
                data-testid="compare-topology-diff-details"
              >
                <span className="tw-text-sm tw-font-semibold">
                  {showTopologyDiffDetails ? "Hide Details" : "View Details"}
                </span>
                <Icon
                  name={
                    showTopologyDiffDetails
                      ? IconsList.CARET_UP
                      : IconsList.CARET_DOWN
                  }
                />
              </div>
            )}
          </div>
          {showTopologyDiffDetails && (
            <div className="tw-mt-4 tw-max-h-52 tw-overflow-y-scroll">
              <Table
                columns={[
                  {
                    id: "difference",
                    data: "Difference",
                  },
                ]}
                rows={[
                  ...getTopologyDiffTableRows(
                    topologyDiffData.createdResources,
                    "resource",
                    "created"
                  ),
                  ...getTopologyDiffTableRows(
                    topologyDiffData.deletedResources,
                    "resource",
                    "deleted"
                  ),
                  ...getTopologyDiffTableRows(
                    topologyDiffData.createdResourceConnections,
                    "connection",
                    "created"
                  ),
                  ...getTopologyDiffTableRows(
                    topologyDiffData.deletedResourceConnections,
                    "connection",
                    "deleted"
                  ),
                ]}
                noRecordsMessage="There are no differences."
                disablePagination
                dataTestId="compare-topology-diff-table"
              />
            </div>
          )}
        </div>
        <div
          className="tw-cursor-pointer"
          onClick={() => {
            setShowTopologyDiff(false);
          }}
          data-testid="close-compare-topology"
        >
          <Icon
            name={IconsList.CLOSE}
            size={IconSize.lg}
            className="tw-text-gray-300"
          />
        </div>
      </div>
    </Card>
  );
};

export default CompareTopology;
