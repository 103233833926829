import React, { type FC, useEffect, useRef, useState } from "react";
import { useSetAtom } from "jotai";

import { Dialog, Spinner } from "..";
import { SpinnerType } from "../spinner/spinner-types";
import { fetchUserLoginStatusAtom } from "../../stores/auth.store";
import { debounce, goToLogin } from "../../utils";
import type { UserInactivityDialogProps } from "./user-inactivity-dialog-types";

const UserInactivityDialog: FC<UserInactivityDialogProps> = ({ idealTime }) => {
  const fetchUserLoginStatus = useSetAtom(fetchUserLoginStatusAtom);
  const [isIdle, setIsIdle] = useState(false);
  const timer = useRef<NodeJS.Timeout | null>(null); // Use useRef instead of useState

  const resetTimer = debounce(() => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => setIsIdle(true), idealTime * 1000 * 60);
  }, 300); // adding debounce in order to avoid multiple calls to resetTimer

  const addEventListeners = () => {
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    window.addEventListener("click", resetTimer);
  };

  const removeEventListeners = () => {
    window.removeEventListener("mousemove", resetTimer);
    window.removeEventListener("keydown", resetTimer);
    window.removeEventListener("click", resetTimer);
  };

  const fetchStatus = async () => {
    try {
      const user = await fetchUserLoginStatus();
      if (user) {
        // if session is valid then we'll get user object and we can hide the dialog
        setTimeout(() => {
          setIsIdle(false); // Hide the model when user data is found
        }, 5000); // keeping this time so that dialog content can be readable or else it will be like a flicker on screen
      } else {
        goToLogin(); // navigate to login screen if user object is not found
      }
    } catch (error: any) {
      if (error?.errCode === "INVALID_SESSION") {
        goToLogin(); // navigate to login screen if session is invalid
      }
    }
  };

  useEffect(() => {
    addEventListeners();
    return removeEventListeners;
  }, []);

  useEffect(() => {
    if (isIdle) {
      fetchStatus();
    } else {
      resetTimer(); // Start looking for an idle state again
    }
  }, [isIdle]);

  return isIdle ? (
    <Dialog
      titleContent={
        <span className="tw-flex tw-justify-center tw-pt-6">
          <Spinner type={SpinnerType.BARS}></Spinner>
        </span>
      }
      bodyContent={
        <div className="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-p-8 tw-pt-0">
          <span className="tw-text-base tw-font-semibold tw-text-white">
            Please wait while we reconnect you...
          </span>
          <span className="tw-text-sm tw-font-normal tw-text-white tw-text-center">
            You've lost connection to the backend API. Please wait a few seconds
            while we automatically reconnect you.
          </span>
        </div>
      }
      dataTestId="user-inactivity-dialog"
      backgroundBlur
    />
  ) : null;
};

export default UserInactivityDialog;
