/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddRepositoriesRequest,
  Branch,
  Commit,
  OffboardRepositoriesRequest,
  OnboardRepositoriesV2Request,
  Repository,
} from '../models/index';
import {
    AddRepositoriesRequestFromJSON,
    AddRepositoriesRequestToJSON,
    BranchFromJSON,
    BranchToJSON,
    CommitFromJSON,
    CommitToJSON,
    OffboardRepositoriesRequestFromJSON,
    OffboardRepositoriesRequestToJSON,
    OnboardRepositoriesV2RequestFromJSON,
    OnboardRepositoriesV2RequestToJSON,
    RepositoryFromJSON,
    RepositoryToJSON,
} from '../models/index';

export interface AddRepositoriesOperationRequest {
    addRepositoriesRequest: AddRepositoriesRequest;
    orgId?: string;
}

export interface GetRepoCommitsRequest {
    provider: string;
    repoId: string;
    branchName: string;
    page?: number;
    perPage?: number;
}

export interface GetRepositoriesRequest {
    provider?: string;
    all?: boolean;
    onboarded?: string;
    language?: string;
    _private?: boolean;
    page?: number;
    perPage?: number;
    orgId?: string;
}

export interface GetRepositoryBranchesRequest {
    provider: string;
    repoId: string;
    page?: number;
    perPage?: number;
}

export interface OffboardRepositoriesOperationRequest {
    offboardRepositoriesRequest: OffboardRepositoriesRequest;
    orgId?: string;
}

export interface OnboardRepositoriesRequest {
    provider: string;
    requestBody: Array<string>;
    orgId?: string;
}

export interface OnboardRepositoriesV2OperationRequest {
    onboardRepositoriesV2Request: OnboardRepositoriesV2Request;
    orgId?: string;
}

/**
 * 
 */
export class ReposApi extends runtime.BaseAPI {

    /**
     * Add repositories
     */
    async addRepositoriesRaw(requestParameters: AddRepositoriesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.addRepositoriesRequest === null || requestParameters.addRepositoriesRequest === undefined) {
            throw new runtime.RequiredError('addRepositoriesRequest','Required parameter requestParameters.addRepositoriesRequest was null or undefined when calling addRepositories.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/repositories`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddRepositoriesRequestToJSON(requestParameters.addRepositoriesRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add repositories
     */
    async addRepositories(requestParameters: AddRepositoriesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addRepositoriesRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieve commits for a specific branch in a repository
     */
    async getRepoCommitsRaw(requestParameters: GetRepoCommitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Commit>>> {
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider','Required parameter requestParameters.provider was null or undefined when calling getRepoCommits.');
        }

        if (requestParameters.repoId === null || requestParameters.repoId === undefined) {
            throw new runtime.RequiredError('repoId','Required parameter requestParameters.repoId was null or undefined when calling getRepoCommits.');
        }

        if (requestParameters.branchName === null || requestParameters.branchName === undefined) {
            throw new runtime.RequiredError('branchName','Required parameter requestParameters.branchName was null or undefined when calling getRepoCommits.');
        }

        const queryParameters: any = {};

        if (requestParameters.repoId !== undefined) {
            queryParameters['repoId'] = requestParameters.repoId;
        }

        if (requestParameters.branchName !== undefined) {
            queryParameters['branchName'] = requestParameters.branchName;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/repositories/{provider}/commits`.replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CommitFromJSON));
    }

    /**
     * Retrieve commits for a specific branch in a repository
     */
    async getRepoCommits(requestParameters: GetRepoCommitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Commit>> {
        const response = await this.getRepoCommitsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List repositories
     */
    async getRepositoriesRaw(requestParameters: GetRepositoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Repository>>> {
        const queryParameters: any = {};

        if (requestParameters.provider !== undefined) {
            queryParameters['provider'] = requestParameters.provider;
        }

        if (requestParameters.all !== undefined) {
            queryParameters['all'] = requestParameters.all;
        }

        if (requestParameters.onboarded !== undefined) {
            queryParameters['onboarded'] = requestParameters.onboarded;
        }

        if (requestParameters.language !== undefined) {
            queryParameters['language'] = requestParameters.language;
        }

        if (requestParameters._private !== undefined) {
            queryParameters['private'] = requestParameters._private;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/repositories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RepositoryFromJSON));
    }

    /**
     * List repositories
     */
    async getRepositories(requestParameters: GetRepositoriesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Repository>> {
        const response = await this.getRepositoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve branches for a specific repository
     */
    async getRepositoryBranchesRaw(requestParameters: GetRepositoryBranchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Branch>>> {
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider','Required parameter requestParameters.provider was null or undefined when calling getRepositoryBranches.');
        }

        if (requestParameters.repoId === null || requestParameters.repoId === undefined) {
            throw new runtime.RequiredError('repoId','Required parameter requestParameters.repoId was null or undefined when calling getRepositoryBranches.');
        }

        const queryParameters: any = {};

        if (requestParameters.repoId !== undefined) {
            queryParameters['repoId'] = requestParameters.repoId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/repositories/{provider}/branches`.replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BranchFromJSON));
    }

    /**
     * Retrieve branches for a specific repository
     */
    async getRepositoryBranches(requestParameters: GetRepositoryBranchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Branch>> {
        const response = await this.getRepositoryBranchesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Offboard repositories
     */
    async offboardRepositoriesRaw(requestParameters: OffboardRepositoriesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.offboardRepositoriesRequest === null || requestParameters.offboardRepositoriesRequest === undefined) {
            throw new runtime.RequiredError('offboardRepositoriesRequest','Required parameter requestParameters.offboardRepositoriesRequest was null or undefined when calling offboardRepositories.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/repositories/onboard`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: OffboardRepositoriesRequestToJSON(requestParameters.offboardRepositoriesRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Offboard repositories
     */
    async offboardRepositories(requestParameters: OffboardRepositoriesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.offboardRepositoriesRaw(requestParameters, initOverrides);
    }

    /**
     * Use /repositories/onboard instead
     * Onboard repositories
     */
    async onboardRepositoriesRaw(requestParameters: OnboardRepositoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider','Required parameter requestParameters.provider was null or undefined when calling onboardRepositories.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling onboardRepositories.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/repositories/{provider}/onboard`.replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Use /repositories/onboard instead
     * Onboard repositories
     */
    async onboardRepositories(requestParameters: OnboardRepositoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.onboardRepositoriesRaw(requestParameters, initOverrides);
    }

    /**
     * Onboard repositories
     */
    async onboardRepositoriesV2Raw(requestParameters: OnboardRepositoriesV2OperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.onboardRepositoriesV2Request === null || requestParameters.onboardRepositoriesV2Request === undefined) {
            throw new runtime.RequiredError('onboardRepositoriesV2Request','Required parameter requestParameters.onboardRepositoriesV2Request was null or undefined when calling onboardRepositoriesV2.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/repositories/onboard`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OnboardRepositoriesV2RequestToJSON(requestParameters.onboardRepositoriesV2Request),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Onboard repositories
     */
    async onboardRepositoriesV2(requestParameters: OnboardRepositoriesV2OperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.onboardRepositoriesV2Raw(requestParameters, initOverrides);
    }

}
