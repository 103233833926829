import React, { Fragment, type FC } from "react";
import {
  Icon,
  IconButton,
  IconSize,
  IconsList,
  SelectBox,
  TextBox,
} from "../..";
import { FieldArray } from "formik";
import type {
  DefaultValueRendererForNonPrimitiveTypeProps,
  RenderObjectListSchemaProps,
} from "./custom-resource-types";
import CommonFieldArrayComponent from "./common-field-array-component";
import {
  CustomResourceDataType,
  booleanOptions,
  primitiveDataTypeList,
} from "../../../utils/custom-resource-handler";

// Renders a default value input component based on the given data type.
const DefaultValueRendererForNonPrimitiveType: FC<
  DefaultValueRendererForNonPrimitiveTypeProps
> = ({ type, varIndex, values, setFieldValue }) => {
  switch (type) {
    case CustomResourceDataType.LIST_STRING:
    case CustomResourceDataType.LIST_NUMBER:
    case CustomResourceDataType.LIST_BOOLEAN:
    case CustomResourceDataType.SET_STRING:
    case CustomResourceDataType.SET_NUMBER:
    case CustomResourceDataType.SET_BOOLEAN:
      return (
        <CommonFieldArrayComponent
          type={type}
          varIndex={varIndex}
          values={values}
          isMap={false}
        />
      );
    case CustomResourceDataType.MAP_STRING:
    case CustomResourceDataType.MAP_NUMBER:
    case CustomResourceDataType.MAP_BOOLEAN:
      return (
        <CommonFieldArrayComponent
          type={type}
          varIndex={varIndex}
          values={values}
          isMap
        />
      );
    case CustomResourceDataType.OBJECT:
      return (
        <div className="tw-w-full tw-border tw-border-gray-600 tw-rounded-md tw-p-4">
          <div className="tw-text-xssm tw-font-semibold tw-text-hwite tw-mb-2">
            Define schema and set default value
          </div>
          <FieldArray
            name={`variables.[${varIndex}].schema`}
            render={(arrayHelper: any) => (
              <>
                {values?.variables?.[varIndex]?.schema?.length > 0 &&
                  values.variables[varIndex]?.schema?.map(
                    (_: any, i: number) => {
                      return (
                        <div
                          className="tw-flex tw-gap-2 tw-items-start tw-mb-2"
                          key={`default_${varIndex}_${i}`}
                        >
                          <TextBox
                            name={`variables.[${varIndex}].schema.[${i}].key`}
                            placeholder={`e.g. key `}
                            label="Key"
                            labelVariant="gray200-medium"
                            backgroundVariant="gray-800"
                            dataTestId={`default-schema-key-input-${varIndex}-${i}`}
                          />
                          <div className="tw-flex tw-gap-2 tw-w-full">
                            <div className="tw-min-w-36">
                              <SelectBox
                                name={`variables.[${varIndex}].schema.[${i}].dataType`}
                                options={primitiveDataTypeList}
                                labelVariant="gray200-medium"
                                backgroundVariant="gray-800"
                                label="Data Type"
                                dataTestId={`default-schema-data-type-input-${varIndex}-${i}`}
                              />
                            </div>
                            <div className="tw-flex-1">
                              {values?.variables?.[varIndex]?.schema?.[i]
                                ?.dataType ===
                              CustomResourceDataType.BOOLEAN ? (
                                <SelectBox
                                  name={`variables.[${varIndex}].defaultVal.[${i}]`}
                                  options={booleanOptions}
                                  labelVariant="gray200-medium"
                                  label="Default Value"
                                  backgroundVariant="gray-800"
                                  dataTestId={`default-schema-data-type-input-${varIndex}-${i}`}
                                />
                              ) : (
                                <TextBox
                                  name={`variables.[${varIndex}].defaultVal.[${i}]`}
                                  placeholder={`${
                                    values?.variables?.[varIndex]?.schema?.[i]
                                      ?.dataType ===
                                    CustomResourceDataType.NUMBER
                                      ? "e.g 123"
                                      : "e.g foo.com"
                                  }`}
                                  label="Default Value"
                                  type={
                                    values?.variables?.[varIndex]?.schema?.[i]
                                      ?.dataType ===
                                    CustomResourceDataType.NUMBER
                                      ? "number"
                                      : "text"
                                  }
                                  labelVariant="gray200-medium"
                                  backgroundVariant="gray-800"
                                  dataTestId={`default-schema-key-input-${varIndex}-${i}`}
                                />
                              )}
                            </div>
                          </div>
                          {values.variables[varIndex].schema?.length > 1 && (
                            <div className="tw-mt-5.5">
                              <IconButton
                                onClick={() => {
                                  arrayHelper.remove(i);
                                  setFieldValue(
                                    `variables.[${varIndex}].defaultVal`,
                                    values.variables[
                                      varIndex
                                    ].defaultVal.filter(
                                      (val: any, varIndex: number) =>
                                        varIndex !== i
                                    )
                                  );
                                }}
                                variant="secondary"
                                icon={IconsList.DELETE}
                                outlined
                                dataTestId={`delete-default-value-${varIndex}-${i}`}
                              />
                            </div>
                          )}
                        </div>
                      );
                    }
                  )}

                <p
                  className="tw-mt-2 tw-text-blue-500 tw-flex tw-items-center tw-gap-1.5 tw-cursor-pointer tw-w-fit"
                  onClick={() => arrayHelper.push({ key: "", dataType: "" })}
                  data-testid={`add-more-values-${varIndex}`}
                >
                  <Icon name={IconsList.ADD} />
                  <span className="tw-text-sm tw-font-semibold">
                    Add More Attributes
                  </span>
                </p>
              </>
            )}
          />
        </div>
      );
    case CustomResourceDataType.LIST_OBJECT:
      return (
        <>
          <div className="tw-w-full tw-border tw-border-gray-600 tw-rounded-md tw-p-4">
            <p className="tw-text-xssm tw-font-semibold tw-text-white tw-mb-2">
              Define a schema <span className="tw-font-normal">(Optional)</span>
            </p>
            <FieldArray
              name={`variables.[${varIndex}].schema`}
              render={(arrayHelper: any) => (
                <>
                  {values?.variables?.[varIndex]?.schema?.length > 0 &&
                    values.variables[varIndex]?.schema?.map(
                      (_: any, i: number) => {
                        return (
                          <div
                            className="tw-flex tw-gap-2 tw-items-start tw-mb-2"
                            key={`default_${varIndex}_${i}`}
                          >
                            <TextBox
                              name={`variables.[${varIndex}].schema.[${i}].key`}
                              placeholder={`e.g. key `}
                              label="Key"
                              labelVariant="gray200-medium"
                              backgroundVariant="gray-800"
                              dataTestId={`default-schema-key-input-${varIndex}-${i}`}
                            />
                            <div className="tw-flex-1">
                              <SelectBox
                                name={`variables.[${varIndex}].schema.[${i}].dataType`}
                                options={primitiveDataTypeList}
                                labelVariant="gray200-medium"
                                backgroundVariant="gray-800"
                                label="Data Type"
                                dataTestId={`default-schema-data-type-input-${varIndex}-${i}`}
                              />
                            </div>
                            {values.variables[varIndex].schema?.length > 1 && (
                              <div className="tw-mt-5.5">
                                <IconButton
                                  onClick={() => {
                                    arrayHelper.remove(i);
                                    const newDefaultValue = values.variables[
                                      varIndex
                                    ].defaultVal.map((val: any) =>
                                      val.filter(
                                        (_: any, varIndex: number) =>
                                          i !== varIndex
                                      )
                                    );
                                    setFieldValue(
                                      `variables.[${varIndex}].defaultVal`,
                                      newDefaultValue
                                    );
                                  }}
                                  variant="secondary"
                                  icon={IconsList.DELETE}
                                  outlined
                                  dataTestId={`delete-default-value-${varIndex}-${i}`}
                                />
                              </div>
                            )}
                          </div>
                        );
                      }
                    )}

                  <p
                    className="tw-mt-2 tw-text-blue-500 tw-flex tw-items-center tw-gap-1.5 tw-cursor-pointer tw-w-fit"
                    onClick={() => arrayHelper.push({ key: "", dataType: "" })}
                    data-testid={`add-more-values-${varIndex}`}
                  >
                    <Icon name={IconsList.ADD} />
                    <span className="tw-text-sm tw-font-semibold">
                      Add More Attributes
                    </span>
                  </p>
                </>
              )}
            />
          </div>
          {values.variables[varIndex]?.schema?.length > 0 &&
            values.variables[varIndex]?.schema[0].key && (
              <div className="tw-w-full tw-border tw-border-gray-600 tw-rounded-md tw-p-4">
                <p className="tw-text-xssm tw-font-semibold tw-text-white tw-mb-2">
                  Default Value{" "}
                  <span className="tw-font-normal">(Optional)</span>
                </p>
                <FieldArray
                  name={`variables.[${varIndex}].defaultVal`}
                  render={(arrayHelper: any) => (
                    <div
                      className="tw-flex tw-flex-col tw-gap-2"
                      key={`variable_${varIndex}`}
                    >
                      {values?.variables?.[varIndex]?.defaultVal?.length > 0 &&
                        values.variables[varIndex]?.defaultVal?.map(
                          (_: any, parentIndex: number) => (
                            <div
                              className="tw-w-full tw-border tw-border-gray-600 tw-rounded-md tw-p-4 tw-relative"
                              key={`default_${varIndex}_${parentIndex}`}
                            >
                              <FieldArray
                                name={`variables.[${parentIndex}].schema`}
                                render={() => (
                                  <>
                                    {values?.variables?.[varIndex]?.schema
                                      ?.length > 0 &&
                                      values.variables[varIndex]?.schema?.map(
                                        (_: any, i: number) => (
                                          <Fragment
                                            key={`default_${varIndex}_${parentIndex}_${i}`}
                                          >
                                            <RenderObjectListSchema
                                              varIndex={varIndex}
                                              parentIndex={parentIndex}
                                              childIndex={i}
                                              values={values}
                                            />
                                          </Fragment>
                                        )
                                      )}
                                  </>
                                )}
                              />
                              <div className="tw-absolute tw-right-3 tw-top-3">
                                <div
                                  className="tw-cursor-pointer tw-text-gray-400 hover:tw-text-gray-300"
                                  onClick={() =>
                                    arrayHelper.remove(parentIndex)
                                  }
                                  data-testid={`delete-default-value-${varIndex}-${parentIndex}`}
                                >
                                  <Icon
                                    name={IconsList.DELETE}
                                    size={IconSize.sm}
                                  />
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      <p
                        className="tw-mt-2 tw-text-blue-500 tw-flex tw-items-center tw-gap-1.5 tw-cursor-pointer tw-w-fit"
                        onClick={() => arrayHelper.push([])}
                        data-testid={`add-more-values-${varIndex}`}
                      >
                        <Icon name={IconsList.ADD} />
                        <span className="tw-text-sm tw-font-semibold">
                          {values?.variables?.[varIndex]?.defaultVal?.length ===
                          0
                            ? "Add List Item"
                            : "Add More List Items"}
                        </span>
                      </p>
                    </div>
                  )}
                />
              </div>
            )}
        </>
      );
    default:
      return null;
  }
};
export default DefaultValueRendererForNonPrimitiveType;

const RenderObjectListSchema: FC<RenderObjectListSchemaProps> = ({
  varIndex,
  parentIndex,
  childIndex,
  values,
}) => (
  <div className="tw-flex tw-gap-2 tw-items-start tw-mb-2">
    <TextBox
      name={`variables.[${varIndex}].schema.[${childIndex}].key`}
      placeholder={`e.g. key `}
      label="Key"
      labelVariant="gray200-medium"
      backgroundVariant="gray-800"
      disabled
      dataTestId={`default-schema-key-input-${varIndex}-${childIndex}`}
    />
    <div className="tw-flex-1">
      {values?.variables?.[varIndex]?.schema?.[childIndex]?.dataType ===
      CustomResourceDataType.BOOLEAN ? (
        <SelectBox
          name={`variables.[${varIndex}].defaultVal.[${parentIndex}].[${childIndex}]`}
          options={booleanOptions}
          labelVariant="gray200-medium"
          backgroundVariant="gray-800"
          label="Default Value"
          dataTestId={`default-schema-data-type-input-${varIndex}-${childIndex}`}
        />
      ) : (
        <TextBox
          name={`variables.[${varIndex}].defaultVal.[${parentIndex}].[${childIndex}]`}
          placeholder={`${
            values?.variables?.[varIndex]?.schema?.[childIndex]?.dataType ===
            CustomResourceDataType.NUMBER
              ? "e.g 123"
              : "e.g foo.com"
          }`}
          label="Default Value"
          labelVariant="gray200-medium"
          type={
            values?.variables?.[varIndex]?.schema?.[childIndex]?.dataType ===
            CustomResourceDataType.NUMBER
              ? "number"
              : "text"
          }
          backgroundVariant="gray-800"
          dataTestId={`default-schema-key-input-${varIndex}-${childIndex}`}
        />
      )}
    </div>
  </div>
);
