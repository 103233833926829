import React, { type FC, useEffect } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { useLocation } from "@reach/router";

import { goToLogin, trackAmplitudeEvent } from "../utils";

import { Navbar, Sidebar } from "../components";
import { HelpCentreDrawer } from "../components/help-centre";

import { fetchUserLoginStatusAtom } from "../stores/auth.store";
import { breadcrumbsAtom, sidebarCollapsedAtom } from "../stores/page.store";
import {
  showHelpDrawerAtom,
  toggleHelpDrawerAtom,
  reportBugAtom,
  submitFeedbackAtom,
  submitTroubleshootingPackageAtom,
} from "../stores/help-centre.store";
import { InAppLayoutProps } from "./layout-types";
import UserInactivityDialog from "../components/user-inactivity-dialog/user-inactivity-dialog";

// Layout with header, sidebar, body and to use when user is logged in
const InAppLayout: FC<InAppLayoutProps> = ({ children }) => {
  const location = useLocation();
  const activeUrlPath = location.pathname.slice(0, -1);
  const idealTime = 5; // allowed inactivity time before inactivity dialog is shown: IN MINUTES

  const fetchUserLoginStatus = useSetAtom(fetchUserLoginStatusAtom);
  const sidebarCollapsed = useAtomValue(sidebarCollapsedAtom);
  const breadcrumbs_data = useAtomValue(breadcrumbsAtom);
  const showHelpDrawer = useAtomValue(showHelpDrawerAtom);
  const toggleHelpDrawer = useSetAtom(toggleHelpDrawerAtom);
  const onSubmitBug = useSetAtom(reportBugAtom);
  const onSubmitFeedback = useSetAtom(submitFeedbackAtom);
  const onSubmitTroubleshootingPackage = useSetAtom(
    submitTroubleshootingPackageAtom
  );

  const isQuickAppStackCreationRouteActive = location.pathname.includes(
    "/quick-appstack-create"
  );
  const isAccountSettingsRouteActive =
    location.pathname.includes("/account-settings");

  useEffect(() => {
    const fetchLoginStatus = async () => {
      try {
        await fetchUserLoginStatus();
      } catch (error: any) {
        if (error?.errCode === "INVALID_SESSION") {
          goToLogin(); // navigate to login screen if session is invalid
        }
      }
    };
    fetchLoginStatus();
  }, []);

  return (
    <div>
      <div className={`tw-w-screen tw-h-screen tw-flex tw-text-gray-100`}>
        <Sidebar
          activeUrlPath={activeUrlPath}
          isQuickAppStackCreationRouteActive={
            isQuickAppStackCreationRouteActive
          }
          isAccountSettingsRouteActive={isAccountSettingsRouteActive}
        />
        <div
          className={`tw-h-full ${
            sidebarCollapsed
              ? "tw-absolute tw-left-14 tw-w-[calc(100vw-3.5rem)]"
              : "tw-w-4/5"
          } 
          `}
        >
          <Navbar
            breadcrumbs_data={breadcrumbs_data}
            onHelpClick={() => {
              if (!showHelpDrawer) {
                trackAmplitudeEvent("topnav_help");
              }
              toggleHelpDrawer(!showHelpDrawer);
            }}
            isQuickAppStackCreationRouteActive={
              isQuickAppStackCreationRouteActive
            }
          />
          <main className="tw-w-full tw-relative tw-h-[calc(100vh-3.5rem)] tw-overflow-y-auto tw-overflow-x-hidden tw-bg-gray-900 tw-text-white tw-border-t tw-border-gray-700">
            {children}
            <HelpCentreDrawer
              show={showHelpDrawer}
              onClose={() => toggleHelpDrawer(false)}
              onSubmitBug={(description) => onSubmitBug(description)}
              onSubmitFeedback={(description, rating) =>
                onSubmitFeedback(description, rating)
              }
              onSubmitTroubleshootingPackage={(comment) =>
                onSubmitTroubleshootingPackage(comment)
              }
            />
          </main>
        </div>
      </div>
      <UserInactivityDialog idealTime={idealTime} />
    </div>
  );
};

export default InAppLayout;
