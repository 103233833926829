import React, { useEffect, useState } from "react";
import { useAtomValue, useSetAtom } from "jotai";

import {
  goToAccountSettings,
  goToLogin,
  trackAmplitudeEvent,
} from "../../../utils";

import { Icon, IconsList, Avatar, Dropdown } from "../../../components";
import { sidebarHoveredAtom } from "../../../stores/page.store";

import {
  userLoginStatusAtom,
  userLogoutAtom,
} from "../../../stores/auth.store";

const SidebarFooter = () => {
  const loggedInUserInfo = useAtomValue(userLoginStatusAtom);
  const isSidebarHovered = useAtomValue(sidebarHoveredAtom);
  const logoutUser = useSetAtom(userLogoutAtom);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const placeholderUserInfo = { name: "John Doe", email: "hello@example.com" }; // TODO: remove this once we start using ful fledged auth

  useEffect(() => {
    // close menu if sidebar is not hovered and in collapsed state
    if (!isSidebarHovered) {
      setIsMenuOpen(false);
    }
  }, [isSidebarHovered]);

  const onFooterMenuItemClick = async (value: string) => {
    switch (value) {
      case "account_settings":
        trackAmplitudeEvent("leftnav_user_account_settings");
        goToAccountSettings();
        break;
      case "logout":
        await logoutUser();
        goToLogin();
        break;
      default:
        break;
    }
  };

  return (
    <Dropdown
      onMenuClose={() => setIsMenuOpen(false)}
      onMenuOpen={() => {
        setIsMenuOpen(true);
        trackAmplitudeEvent("leftnav_user_menu");
      }}
      isMenuOpen={isMenuOpen}
      containerClassName="tw-w-full"
      menuClassName="tw-mb-2 tw-left-2 !tw-w-[calc(100%-1rem)]"
      menuListClassName=""
      options={[
        {
          label: (
            <div
              className="tw-flex tw-gap-3 tw-items-center tw-px-1 tw-z-10"
              data-testid="account-settings-menu-item"
            >
              <Icon name={IconsList.GEAR} className="tw-text-gray-300" />
              <span className="tw-text-xssm tw-text-gray-100">
                Account Settings
              </span>
            </div>
          ),
          value: "account_settings",
        },
        {
          label: (
            <div
              className="tw-flex tw-gap-3 tw-items-center tw-px-1 tw-z-10"
              data-testid="logout-menu-item"
            >
              <Icon name={IconsList.DELETE} className="tw-text-red-400" />
              <span className="tw-text-xssm tw-text-red-400">Logout</span>
            </div>
          ),
          value: "logout",
        },
      ]}
      onSelectionChange={(e) => onFooterMenuItemClick(e.value)}
      dataTestId={"sidebar-footer"}
    >
      <div className="tw-p-3 tw-border-t tw-border-gray-700 tw-overflow-hidden tw-flex tw-items-center tw-w-full">
        <div className="tw-mr-3 tw-float-left">
          <Avatar
            imageUrl={loggedInUserInfo.avatar}
            title={loggedInUserInfo.name || placeholderUserInfo.name}
            size="2xl"
          />
        </div>
        <div className="tw-flex tw-items-center tw-justify-between tw-w-full">
          <div className="tw-flex tw-flex-col tw-flex-1">
            <span className="tw-text-gray-50 tw-text-sm tw-whitespace-nowrap">
              {loggedInUserInfo.name || placeholderUserInfo.name}
            </span>
            <span className="tw-text-gray-300 tw-text-xs tw-whitespace-nowrap">
              {loggedInUserInfo.email || placeholderUserInfo.email}
            </span>
          </div>
          <div className="tw-text-gray-300 tw-cursor-pointer tw-transition-colors hover:tw-text-gray-200">
            <Icon
              name={isMenuOpen ? IconsList.CARET_UP : IconsList.CARET_DOWN}
              dataTestId="sidebar-footer-menu-caret-icon"
            />
          </div>
        </div>
      </div>
    </Dropdown>
  );
};

export default SidebarFooter;
