import React, { memo } from "react";
import { NodeProps, NodeResizer } from "reactflow";
import {
  AWSIcon,
  AWSIconsList,
  Icon,
  IconSize,
  IconsList,
} from "../../components";

const NestedGroupNode = (node: NodeProps) => {
  return (
    <>
      {/* <NodeResizer isVisible={node.selected} /> */}
      <div
        className={`tw-flex tw-justify-between tw-gap-3 tw-p-3 tw-bg-purple-400 tw-bg-opacity-5 tw-rounded-t-md`}
        data-testid={`group-node-${node?.data?.id}`}
      >
        <div className="tw-flex tw-gap-2 tw-items-center tw-h-fit">
          <div className="tw-basis-7">
            <AWSIcon
              name={AWSIconsList[node?.data?.icon as keyof typeof AWSIconsList]}
              alt={node?.data?.label || node?.data?.resourceTypeLabel}
              size={IconSize.xl}
              fallbackPlaceholder={node?.data?.resourceType}
              className="tw-rounded"
            />
          </div>
          <div>
            <div className="tw-text-sm tw-text-white tw-font-semibold tw-break-all">
              {node?.data?.label || node?.data?.resourceTypeLabel}
            </div>
            {node?.data?.label && (
              <div className="tw-text-xxsxs tw-text-gray-200 tw-font-normal tw-break-all">
                {node?.data?.resourceTypeLabel}
              </div>
            )}
          </div>
          {node?.data?.external_resource && (
            <div className="tw-absolute tw-px-1.5 tw-py-1 tw-text-xxsxs tw-italic tw-text-gray-100 tw-right-px tw-top-px tw-bg-gray-600 tw-rounded-se-md tw-rounded-es-md">
              External
            </div>
          )}
        </div>
        <div className="tw-basis-4 tw-h-fit">
          {node.data?.errors?.validationErrors ? (
            <Icon
              name={IconsList.INFO_CIRCLE}
              className="tw-text-red-500"
              size={IconSize.sm}
            />
          ) : node.data?.errors?.policyViolationErrors ? (
            <Icon
              name={IconsList.SHIELD_EXCLAMATION}
              className="tw-text-yellow-500"
              size={IconSize.sm}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default memo(NestedGroupNode);
