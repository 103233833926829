import React, { useState, type FC } from "react";

import {
  Drawer,
  Icon,
  IconsList,
  Dialog,
  SelectableTile,
} from "../../components";
import { SelectionType } from "../../components/selectable-tile/selectable-tile-types";
import { ReportBug, SubmitFeedback, SubmitTroubleshootingPackage } from "./";
import { goToQuickAppStackCreate, trackAmplitudeEvent } from "../../utils";
import type { HelpCentreDrawerProps } from "./help-centre-types";

const HelpCentreDrawer: FC<HelpCentreDrawerProps> = ({
  show = false,
  onClose,
  onSubmitBug,
  onSubmitFeedback,
  onSubmitTroubleshootingPackage,
}) => {
  const [getHelpDialog, setGetHelpDialog] = useState<string | null>(null);

  const getHelpOptions: { [key: string]: any } = {
    "report-bug": {
      title: "Report bug",
      component: (
        <ReportBug
          onSubmit={onSubmitBug}
          onSubmitCallback={() => setGetHelpDialog(null)}
        />
      ),
      icon: IconsList.BUG,
      analyticsEventId: "help_report_bug",
    },
    "submit-feedback": {
      title: "Submit feedback",
      component: (
        <SubmitFeedback
          onSubmit={onSubmitFeedback}
          onSubmitCallback={() => setGetHelpDialog(null)}
        />
      ),
      icon: IconsList.MESSAGE_CIRCLE_LINES,
      analyticsEventId: "help_submit_feedback",
    },
    "submit-troubleshooting-package": {
      title: "Submit troubleshooting package",
      component: (
        <SubmitTroubleshootingPackage
          onSubmit={onSubmitTroubleshootingPackage}
          onSubmitCallback={() => setGetHelpDialog(null)}
        />
      ),
      icon: IconsList.INBOX_ARROW_UP,
      analyticsEventId: "help_submit_troubleshooting_package",
    },
  };

  return (
    <>
      <Drawer
        open={show}
        size="sm"
        isFullScreenDrawer={false}
        dataTestId={"help-drawer"}
      >
        <div className="tw-h-full tw-relative tw-bg-gray-900 tw-border-l tw-border-gray-700">
          <div className="tw-flex tw-gap-3 tw-p-4 tw-items-center tw-justify-between tw-border-b tw-border-gray-700">
            <div className="tw-flex tw-gap-2 tw-items-center">
              <Icon
                name={IconsList.CIRCLE_QUESTION}
                className="tw-text-gray-100"
              />
              <span className="tw-text-gray-50 tw-text-base tw-font-semibold">
                Help
              </span>
            </div>
            <div
              className="tw-cursor-pointer tw-text-gray-300 hover:tw-text-gray-200"
              onClick={onClose}
              data-testid="close-help-drawer"
            >
              <Icon name={IconsList.CLOSE} />
            </div>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-4 tw-border-b tw-border-gray-700 tw-p-4">
            <a
              href="https://docs.appcd.io/getting-started"
              target="_blank"
              className="tw-group"
              data-testid="help-getting-started"
            >
              <div
                className="tw-flex tw-gap-3 tw-items-center"
                onClick={() => trackAmplitudeEvent("help_getting_started")}
              >
                <Icon
                  name={IconsList.CIRCLE_LOCATION_ARROW}
                  className="tw-text-gray-300 group-hover:tw-text-blue-500"
                />
                <span className="tw-text-gray-50 group-hover:tw-text-blue-500 tw-text-sm tw-font-normal tw-flex tw-gap-1 tw-items-center">
                  Getting started
                  <Icon
                    name={IconsList.ARROW_UP_RIGHT}
                    className="tw-text-gray-400 group-hover:tw-text-blue-500"
                  />
                </span>
              </div>
            </a>
            <a
              href="https://docs.appcd.io"
              target="_blank"
              className="tw-group"
              data-testid="help-documentation"
            >
              <div
                className="tw-flex tw-gap-3 tw-items-center"
                onClick={() => trackAmplitudeEvent("help_documentation")}
              >
                <Icon
                  name={IconsList.BOOK}
                  className="tw-text-gray-300 group-hover:tw-text-blue-500"
                />
                <span className="tw-text-gray-50 group-hover:tw-text-blue-500 tw-text-sm tw-font-normal tw-flex tw-gap-1 tw-items-center">
                  Documentation
                  <Icon
                    name={IconsList.ARROW_UP_RIGHT}
                    className="tw-text-gray-400 group-hover:tw-text-blue-500"
                  />
                </span>
              </div>
            </a>
            <a
              className="tw-group"
              data-testid="help-quick-onboard"
              onClick={() => {
                trackAmplitudeEvent("quick_appstack_create");
                onClose();
                goToQuickAppStackCreate();
              }}
            >
              <div className="tw-flex tw-gap-3 tw-items-center tw-cursor-pointer">
                <Icon
                  name={IconsList.CIRCLE_PLAY}
                  className="tw-text-gray-300 group-hover:tw-text-blue-500"
                />
                <span className="tw-text-gray-50 group-hover:tw-text-blue-500 tw-text-sm tw-font-normal tw-flex tw-gap-1 tw-items-center">
                  Guide me once again to create an appStack
                </span>
              </div>
            </a>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-4 tw-border-b tw-border-gray-700 tw-p-4">
            {Object.keys(getHelpOptions).map((key) => (
              <div
                key={key}
                className="tw-flex tw-gap-3 tw-items-center tw-cursor-pointer tw-group"
                onClick={() => {
                  setGetHelpDialog(key);
                  trackAmplitudeEvent(getHelpOptions[key].analyticsEventId);
                }}
                data-testid={`get-help-${key}`}
              >
                <Icon
                  name={getHelpOptions[key].icon}
                  className="tw-text-gray-300 group-hover:tw-text-blue-500"
                />
                <span className="tw-text-gray-50 tw-text-sm tw-font-normal group-hover:tw-text-blue-500">
                  {getHelpOptions[key].title}
                </span>
              </div>
            ))}
          </div>
          {/* TODO: Uncomment this once release docs are ready and deployed */}
          {/* <div className="tw-border-t tw-border-gray-700 tw-p-4 tw-absolute tw-bottom-0 tw-w-full">
            <span className="tw-text-gray-300 tw-text-xssm tw-font-normal">
              <Link
                text="appCD"
                target="_blank"
                href="https://appcd.io"
                className="tw-text-gray-300"
                data-testid="appcd-link"
              />{" "}
              •{" "}
              <Link
                text="View Release Notes"
                target="_blank"
                href="https://docs.appcd.io/release-notes"
                className="tw-text-gray-300"
                data-testid="release-notes-link"
              />
            </span>
          </div> */}
        </div>
      </Drawer>
      {getHelpDialog && (
        <Dialog
          size="lg"
          titleContent={
            <div className="tw-flex tw-items-center tw-justify-between tw-gap-3">
              <span className="tw-text-white tw-text-base tw-font-semibold">
                Get Help
              </span>
              <button
                onClick={() => setGetHelpDialog(null)}
                type="button"
                data-testid="get-help-close-icon-dialog"
              >
                <Icon
                  name={IconsList.CLOSE}
                  className="tw-text-gray-300 hover:tw-text-gray-200 tw-cursor-pointer"
                />
              </button>
            </div>
          }
          bodyContent={
            <div className="tw-w-208 tw-flex tw-flex-col tw-gap-5">
              <div className="tw-flex tw-items-center tw-gap-4">
                {Object.keys(getHelpOptions).map((key) => (
                  <SelectableTile
                    key={key}
                    title={getHelpOptions[key].title}
                    type={SelectionType.DEFAULT}
                    name={key}
                    id={key}
                    value={key}
                    defaultTileContentRenderer={
                      <div>{getHelpOptions[key].title}</div>
                    }
                    isSelected={getHelpDialog === key}
                    onTileClick={(value) => setGetHelpDialog(value)}
                    dataTestId={`get-help-dialog-${key}-selectable-tile`}
                  />
                ))}
              </div>
              <div>{getHelpOptions[getHelpDialog].component}</div>
            </div>
          }
          dataTestId="get-help-dialog"
        />
      )}
    </>
  );
};

export default HelpCentreDrawer;
