import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";

import type { SupportedResourceListProps } from "./topology-graph-types";

import {
  AWSIcon,
  AWSIconsList,
  Dropdown,
  Icon,
  IconSize,
  IconsList,
  Tabs,
  TextBox,
  Tooltip,
} from "../../components";

import type {
  ProviderWiseSupportedResourceType,
  SupportedResourceType,
} from "../../apis/topology";
import { isFunction, isLongSingleWord } from "../../utils";

const SupportedResourceList = ({
  resourcesList,
  onAddCustomResource = () => {},
  activeResourceTab,
  cloudService,
  onPanelClose,
  onDragStart,
  onResourceTabChange,
  onActiveCustomResourceAction = () => {},
}: SupportedResourceListProps) => {
  const getResourceProviders = (
    cloudService: string
  ): { [key: string]: string } => {
    switch (cloudService) {
      case "aws-ecs":
        return { aws: "AWS", custom: "Custom" };
      case "k8s":
        return {
          aws: "AWS",
          helm: "HELM",
          general: "General",
          custom: "Custom",
        };
      case "aks-tf":
        return {
          azurerm: "AZURE",
          helm: "HELM",
          general: "General",
          custom: "Custom",
        };
      case "aws-lambda":
        return { aws: "AWS", general: "General", custom: "Custom" };
      default:
        return {
          aws: "AWS",
          azurerm: "AZURE",
          helm: "HELM",
          general: "General",
          custom: "Custom",
        };
    }
  };

  const [resourceProviders, setResourceProviders] = useState(
    getResourceProviders(cloudService)
  );
  const [resource_list, setResourceList] = useState(resourcesList);

  useEffect(() => {
    setResourceList(resourcesList);
  }, [activeResourceTab, resourcesList]);

  useEffect(() => {
    if (onResourceTabChange && isFunction(onResourceTabChange)) {
      // select first tab by default
      onResourceTabChange(
        Object.keys(
          resourceProviders
        )?.[0] as keyof ProviderWiseSupportedResourceType
      );
    }
  }, [resourceProviders]);

  const getResource = (
    resource: SupportedResourceType,
    provider: keyof ProviderWiseSupportedResourceType
  ) => {
    return (
      <div
        className={`tw-group tw-flex tw-items-center tw-justify-between tw-gap-2.5 tw-mb-3 tw-rounded-md tw-bg-gray-700 hover:tw-bg-gray-600 tw-border tw-border-gray-600 active:tw-cursor-grabbing tw-cursor-grab ${
          provider === "custom" ? "tw-px-3 tw-py-2" : "tw-p-3"
        }`}
        key={resource.resourceType}
        draggable
        onDragStart={(event) =>
          onDragStart(event, resource.resourceType as string)
        }
      >
        <div className="tw-flex tw-items-center tw-gap-2.5">
          <div className="tw-basis-7">
            <AWSIcon
              name={
                AWSIconsList[
                  resource?.resourceType as keyof typeof AWSIconsList
                ]
              }
              size={IconSize.xl}
              fallbackPlaceholder={resource.resourceType}
            />
          </div>
          {/* Show Ellipses text when label is long single word */}
          {isLongSingleWord(resource.label as string, 20) ? (
            <span className="tw-text-sm tw-text-white tw-font-medium tw-capitalize tw-w-40 tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap">
              <Tooltip title={resource.label}>{resource.label}</Tooltip>
            </span>
          ) : (
            <span
              className="tw-text-sm tw-text-white tw-font-medium tw-capitalize"
              data-testid={`label_${resource.resourceType}`}
            >
              {resource.label}
            </span>
          )}
        </div>
        <div className="tw-basis-6 tw-flex tw-gap-2 tw-items-center">
          {/* Show dropdown when we start supporting editing and deleting custom resource */}
          {/* {provider === "custom" && (
            <Dropdown
              options={[
                {
                  label: (
                    <div className="tw-flex tw-gap-3 tw-items-center tw-px-1">
                      <Icon
                        name={IconsList.PENCIL}
                        className="tw-text-gray-300"
                        size={IconSize.sm}
                      />
                      <span className="tw-text-xssm tw-text-gray-100">
                        Edit Resource
                      </span>
                    </div>
                  ),
                  value: "edit",
                },
                {
                  label: (
                    <div className="tw-flex tw-gap-3 tw-items-center tw-px-1">
                      <Icon
                        name={IconsList.DELETE}
                        className="tw-text-red-500"
                      />
                      <span className="tw-text-xssm tw-text-red-500">
                        Delete Resource
                      </span>
                    </div>
                  ),
                  value: "delete",
                },
              ]}
              onSelectionChange={(e) => {
                onActiveCustomResourceAction(resource, e.value);
              }}
              dataTestId={`custom-resource-action-${resource.resourceType}`}
            >
              <div className="tw-text-gray-400 hover:tw-text-gray-300">
                <Icon
                  name={IconsList.GEAR}
                  size={IconSize.md}
                  className="tw-opacity-0 tw-invisible group-hover:tw-opacity-100 group-hover:tw-visible tw-transition-opacity tw-delay-300 tw-duration-500"
                />
              </div>
            </Dropdown>
          )} */}
          <Icon
            name={IconsList.GRIP_DOTS_VERTICAL}
            className="tw-text-gray-400 tw-hidden group-hover:tw-block"
            dataTestId={`resource-drag-${resource.resourceType}`}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    setResourceProviders(getResourceProviders(cloudService));
  }, [cloudService]);

  return (
    <>
      <div className="tw-sticky tw-top-0 tw-z-10 tw-bg-gray-800">
        <div className="tw-flex tw-gap-3 tw-justify-between tw-items-center tw-p-4">
          <span className="tw-font-semibold tw-text-white tw-text-base">
            Add New Resource
          </span>
          <div
            onClick={() => {
              if (isFunction(onPanelClose)) {
                onPanelClose();
              }
            }}
            data-testid="close-resource-list"
            className="tw-cursor-pointer tw-text-gray-300 hover:tw-text-gray-200"
          >
            <Icon name={IconsList.CLOSE} />
          </div>
        </div>
        <div className="tw-py-2 tw-px-4">
          <Formik
            initialValues={{
              list_filter: "",
            }}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
              const filteredResourceList = { ...resourcesList };
              filteredResourceList[
                activeResourceTab as keyof ProviderWiseSupportedResourceType
              ] = resourcesList?.[
                activeResourceTab as keyof ProviderWiseSupportedResourceType
              ]?.filter((resource: SupportedResourceType) =>
                resource?.label
                  ?.toLocaleLowerCase()
                  .includes(values.list_filter.toLocaleLowerCase())
              );
              setResourceList(filteredResourceList);
              setSubmitting(false);
            }}
          >
            {({ submitForm, setFieldValue }) => (
              <Form>
                <TextBox
                  name="list_filter"
                  placeholder="Search Resources"
                  onChange={(e) => {
                    e.preventDefault();
                    setFieldValue("list_filter", e.target.value);
                    submitForm();
                  }}
                  prefix={
                    <Icon
                      name={IconsList.SEARCH}
                      size={IconSize.sm}
                      className="tw-text-gray-500"
                    />
                  }
                  dataTestId="search-resources"
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Tabs
        wrapperClassName="tw-overflow-x-auto"
        tabs={Object.keys(resourceProviders).map((provider) => {
          const resourceListForProvider =
            resource_list?.[
              provider as keyof ProviderWiseSupportedResourceType
            ];
          return {
            id: provider,
            title:
              resourceProviders[provider as keyof typeof resourceProviders],
            content: (
              <>
                {provider === "custom" ? (
                  <div
                    className={`tw-m-4 tw-mb-0 tw-pb-3 ${
                      (resourceListForProvider?.length as number) > 0
                        ? "tw-border-b tw-border-gray-700"
                        : ""
                    }`}
                  >
                    <div
                      className={
                        "tw-p-3 tw-flex tw-items-center tw-gap-1 tw-border tw-border-dashed tw-border-gray-400 tw-rounded-md tw-cursor-pointer hover:tw-bg-gray-700 tw-duration-150"
                      }
                      onClick={onAddCustomResource}
                      data-testid={`add-custom-resource-${provider}`}
                    >
                      <Icon name={IconsList.ADD} />
                      <span className="tw-text-xssm tw-text-gray-100 tw-font-medium">
                        Create Custom Resource
                      </span>
                    </div>
                  </div>
                ) : null}
                {(resourceListForProvider?.length as number) > 0 ? (
                  <div
                    className={`tw-p-4 ${
                      provider === "custom" ? "tw-pt-3" : ""
                    }`}
                    data-testid={`resource-list-${provider}`}
                  >
                    {resourceListForProvider?.map((resource) => {
                      return getResource(
                        resource,
                        provider as keyof ProviderWiseSupportedResourceType
                      );
                    })}
                  </div>
                ) : provider !== "custom" ? (
                  <div className="tw-flex tw-justify-center tw-mt-6">
                    <span
                      className="tw-text-gray-200 tw-text-xssm"
                      data-testid="no-result-label"
                    >
                      No results found.
                    </span>
                  </div>
                ) : null}
              </>
            ),
            onTabChange: (tab) => {
              onResourceTabChange &&
                isFunction(onResourceTabChange) &&
                onResourceTabChange(
                  tab as keyof ProviderWiseSupportedResourceType
                );
            },
          };
        })}
      />
    </>
  );
};

export default SupportedResourceList;
