import React, { useMemo, type FC } from "react";

import { SelectableTileProps, SelectionType } from "./selectable-tile-types";

import {
  AWSIcon,
  AWSIconsList,
  Icon,
  IconSize,
  IconsList,
  Checkbox,
  Link,
  Radio,
  ToggleSwitch,
} from "../../components";

const SelectableTile: FC<SelectableTileProps> = ({
  title,
  icon,
  type,
  id,
  name,
  value,
  dataTestId,

  isSelected = false,
  disabled = false,
  viewDetailsLink,
  customSubtitleRenderer,
  customIconRenderer,
  defaultTileContentRenderer,
  onTileClick,
}) => {
  // TODO: need to create separate component for variants to reduce complexity in same file

  // prepare container class
  const containerClass = useMemo(() => {
    const baseClassName =
      "tw-flex tw-items-center tw-gap-4 tw-border tw-w-full tw-rounded-md";
    const paddingClassName =
      type === SelectionType.SWITCH ? "tw-px-4 tw-py-2.5" : "tw-p-4";
    const disabledClassName = disabled
      ? "tw-cursor-default tw-opacity-50 tw-pointer-events-none"
      : "tw-cursor-pointer";
    const hoverClassName = "hover:tw-bg-blue-500 hover:tw-border-600";

    const selectedClassName = isSelected
      ? "tw-border-blue-500 tw-bg-blue-900 hover:tw-bg-opacity-30"
      : "tw-border-gray-500 tw-bg-gray-700 hover:tw-bg-opacity-10";
    return `${baseClassName} ${paddingClassName} ${hoverClassName} ${disabledClassName} ${selectedClassName}`;
  }, [disabled, isSelected]);

  return (
    <label
      htmlFor={id}
      className={containerClass}
      onClick={() => onTileClick && onTileClick(value)}
    >
      {type === SelectionType.DEFAULT && defaultTileContentRenderer ? (
        <div data-testid={dataTestId}>{defaultTileContentRenderer}</div>
      ) : (
        <>
          {type !== SelectionType.DEFAULT && (
            <>
              {type === SelectionType.RADIO && (
                <Radio
                  name={name}
                  id={id}
                  value={value}
                  disabled={disabled}
                  dataTestId={dataTestId}
                />
              )}
              {type === SelectionType.CHECKBOX && (
                <Checkbox
                  name={name}
                  id={id}
                  value={value}
                  dataTestId={dataTestId}
                />
              )}
              {(icon || customIconRenderer) &&
                (customIconRenderer ? (
                  customIconRenderer
                ) : Object.values(IconsList).includes(icon as IconsList) ? (
                  <Icon
                    name={icon as IconsList}
                    size={
                      type === SelectionType.SWITCH
                        ? IconSize["xl"]
                        : IconSize["4xl"]
                    }
                    className="tw-fill-gray-50"
                  />
                ) : (
                  Object.values(AWSIconsList).includes(
                    icon as AWSIconsList
                  ) && (
                    <AWSIcon
                      name={icon as AWSIconsList}
                      size={
                        type === SelectionType.SWITCH
                          ? IconSize["xl"]
                          : IconSize["4xl"]
                      }
                    />
                  )
                ))}
            </>
          )}
          <div
            className="tw-flex tw-justify-between tw-flex-1"
            onClick={() => onTileClick && onTileClick(value)}
          >
            <div className="tw-flex tw-flex-col tw-gap-1 tw-py-1 tw-items-start">
              <span
                className={`tw-text-white tw-text-left ${
                  type === SelectionType.SWITCH ? "tw-text-xssm" : "tw-text-sm"
                }`}
              >
                {title}
              </span>
              {type !== SelectionType.SWITCH &&
                (customSubtitleRenderer ? (
                  customSubtitleRenderer
                ) : (
                  <span className="tw-text-xs tw-flex tw-gap-1 tw-items-center">
                    {viewDetailsLink && (
                      <>
                        <Link
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          text="View Details"
                          href={viewDetailsLink ? viewDetailsLink : "#"}
                          className="tw-text-xs"
                          target="_blank"
                          dataTestId={`${dataTestId}-view-details-link`}
                        />
                        <Icon
                          name={IconsList.ARROW_UP_RIGHT}
                          size={IconSize.sm}
                          className="tw-text-blue-500"
                        />
                      </>
                    )}
                  </span>
                ))}
            </div>
            {type === SelectionType.SWITCH && (
              <ToggleSwitch
                name={name}
                value={value}
                id={id}
                dataTestId={dataTestId}
              />
            )}
          </div>
        </>
      )}
    </label>
  );
};

export default SelectableTile;
