import React, {
  useState,
  useCallback,
  useEffect,
  memo,
  type MouseEvent,
  type DragEvent,
  Fragment,
} from "react";

import { useSetAtom, useAtomValue } from "jotai";

import "reactflow/dist/style.css";
import ReactFlow, {
  BackgroundVariant,
  Background,
  addEdge,
  Panel,
  ReactFlowProvider,
  ConnectionMode,
  useReactFlow,
  useNodesState,
  useEdgesState,
  useStore,
} from "reactflow";
import type {
  Node,
  Connection,
  NodeTypes,
  EdgeTypes,
  Edge,
  ReactFlowInstance,
  OnConnectStartParams,
} from "reactflow";

import {
  type TopologyGraphProps,
  type Resource,
  type ResourceConnection,
  type ResourceErrors,
  TopologyPageReferrer,
} from "./topology-graph-types";
import type {
  CustomResourceTemplate,
  ProviderWiseSupportedResourceType,
  SupportedResourceType,
  TopologyResourceConnection,
  TopologyResourceWithMisconfigurations,
  TopologyResourceWithPosition,
  SupportedConnectionType,
} from "../../apis/topology";

import getELKGraphPositions from "./elk-graph-positions";

import {
  SupportedResourceList,
  CustomResourceNode,
  CustomGroupNode,
  CustomEdge,
  ResourcePropertiesForm,
  CompareTopology,
  CustomResourceDrawer,
  ConfigureTFVarsDialog,
  FixMissingAttributesDrawer,
  LLMPrompt,
  NestedGroupNode,
} from "./";

import {
  IconsList,
  Drawer,
  Icon,
  Tag,
  Popover,
  IconSize,
  Dialog,
  Button,
  Link,
  Spinner,
} from "../../components";
import {
  isFunction,
  trackAmplitudeEvent,
  findChildNodeAbsolutePosition,
  traverseAndUpdateEachResource,
  findResourceInTopology,
} from "../../utils";
import {
  targetComputeResourceTypes,
  unsupportedResourceTypes,
} from "../../config/constants";
import {
  deleteCustomResourceTemplateAtom,
  getCustomTemplateByResourceIdAtom,
  topologyPageMetaAtom,
} from "../../stores/topology.store";
import GlobalResourceTagsDrawer from "../global-resource-tags/global-resource-tags-drawer";

const nodeTypes: NodeTypes = {
  customResourceNode: CustomResourceNode,
  customGroupNode: CustomGroupNode,
  nestedGroupNode: NestedGroupNode,
};

const edgeTypes: EdgeTypes = {
  customEdge: CustomEdge,
};

enum SelectedResourceType {
  VALIDATION_ERROR_NODE = "validation_error_node",
  POLICY_VIOLATION_NODE = "policy_violation_node",
}

const TopologyGraph = ({
  showTopologyDiff,
  setShowTopologyDiff,
  getTopologyDiff,
  appStackVersions,
  graphData,
  resourcesList,
  resourcePropertiesTemplates,
  fetchResourcePropertiesTemplate,
  fetchResourcePropertiesValues,
  fetchResourceConnectionPropertiesValues,
  fetchResourceConnectionTypes,
  updateResourcePropertiesValues,
  updateResourceConnectionPropertiesValues,
  updateResourcePosition,
  updateBulkResourcePosition,
  createTopologyResource,
  createTopologyResourceConnection,
  deleteTopologyResource,
  deleteTopologyResourceConnection,
  topologyErrors,
  validateTopology,
  refetchTopologyGraphData,
  provider,
  refetchResourcesList = () => {},
  showGlobalResourceTagsDrawer,
  setShowGlobalResourceTagsDrawer,
  globalResourceTags,
  onSubmitGlobalResourceTags,
  markResourceAsExternal,
}: TopologyGraphProps) => {
  const { setViewport, zoomIn, zoomOut, fitView, screenToFlowPosition } =
    useReactFlow();
  const currentZoomLevel = useStore((store) => store.transform[2]);
  const isMaxZoomLevel = useStore(
    (store) => store.transform[2] === store.maxZoom
  );
  const isMinZoomLevel = useStore(
    (store) => store.transform[2] === store.minZoom
  );

  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance | null>(null);
  const [resources, setResources, onResourcesChange] = useNodesState<Node[]>(
    []
  );
  const [resourceConnections, setResourceConnections] = useEdgesState<Edge[]>(
    []
  );
  const getCustomTemplateByResourceId = useSetAtom(
    getCustomTemplateByResourceIdAtom
  );
  const deleteCustomResourceTemplate = useSetAtom(
    deleteCustomResourceTemplateAtom
  );
  const topologyPageMeta = useAtomValue(topologyPageMetaAtom);

  const defaultShowResourcePropertiesForm = {
    show: false,
    defaultTabId: "details",
    showValidationErrors: false,
  };

  const [showResourcesList, setShowResourcesList] = useState(false);
  const [showResourcePropertiesForm, setShowResourcePropertiesForm] = useState(
    defaultShowResourcePropertiesForm
  );
  const [selected_node, setSelectedNode] =
    useState<TopologyResourceWithMisconfigurations | null>(null);
  const [selected_edge, setSelectedEdge] = useState<Edge | null>(null);
  const [group_resources_expanded, updateGroupResourcesExpanded] = useState<{
    [resource_id: string]: boolean;
  }>({});
  const [connectionLineStyle, setConnectionLineStyle] = useState({});
  const [unsupportedResourcesExists, setUnsupportedResourcesExists] =
    useState(false);
  const [hideUnSupportedResources, setHideUnSupportedResources] =
    useState(false);
  const [activeResourceTab, setActiveResourceTab] = useState(
    "" as keyof ProviderWiseSupportedResourceType
  );
  const [showCustomResourceDrawer, setShowCustomResourceDrawer] =
    useState(false);
  const [showCustomResourceDeleteDialog, setShowCustomResourceDeleteDialog] =
    useState(false);
  const [showConfigureTFVarsDialog, setShowConfigureTFVarsDialog] =
    useState(false);
  const [showMissingAttributesDrawer, setShowMissingAttributesDrawer] =
    useState(false);
  const [activeCustomResourceForAction, setActiveCustomResourceForAction] =
    useState<SupportedResourceType>({});
  const [
    activeCustomResourceTemplateForAction,
    setActiveCustomResourceTemplateForAction,
  ] = useState<CustomResourceTemplate | null>(null);

  const handleTransform = useCallback(() => {
    setViewport({ x: 0, y: 0, zoom: 1 }, { duration: 800 });
  }, [setViewport]);

  const getPossibleConnectionTypes = useCallback(
    async (sourceNodeId: string, targetNodeId: string) => {
      const sourceNode: any = resources.find(
        (resource) => resource.id === sourceNodeId
      );
      const targetNode: any = resources.find(
        (resource) => resource.id === targetNodeId
      );
      const possibleConnectionTypes = await fetchResourceConnectionTypes?.(
        sourceNode?.data?.resourceType as string,
        targetNode?.data?.resourceType as string
      );

      return possibleConnectionTypes;
    },
    [resources, fetchResourceConnectionTypes]
  );

  const createConnection = useCallback(
    async (
      connection: Connection,
      connectionTypeData: SupportedConnectionType
    ) => {
      await createTopologyResourceConnection?.(
        graphData.id as string,
        {
          sourceResourceId: connection.source as string,
          targetResourceId: connection.target as string,
          connectionType: connectionTypeData.type,
          crossReferenceId: connectionTypeData?.crossReferenceId,
        },
        (resourceConnection) => {
          if (
            resourceConnection.sourceResourceId &&
            resourceConnection.targetResourceId
          ) {
            setResourceConnections((resource_connections) =>
              addEdge(
                {
                  ...connection,
                  id: resourceConnection.id as string,
                  source: resourceConnection.sourceResourceId as string,
                  target: resourceConnection.targetResourceId as string,
                  type: "customEdge",
                  style: { strokeWidth: 2, stroke: "#374252" },
                },
                resource_connections
              )
            );
            trackAmplitudeEvent("resource_connection", {
              connectionType: connectionTypeData?.type,
            });
            if (selected_node) {
              onNodeClick({
                id: selected_node.id as string,
                data: selected_node,
                position: {
                  x: selected_node?.position?.x as number,
                  y: selected_node?.position?.y as number,
                },
              });
            }
          } else {
            /** Do not show edge if it's an invalid connection
            setResourceConnections((resource_connections) =>
              addEdge(
                {
                  ...connection,
                  type: "customEdge",
                  style: { strokeWidth: 2, stroke: "#F24A4A" },
                },
                resource_connections
              )
            );
            */
          }
        }
      );
    },
    [setResourceConnections, graphData.id, selected_node]
  );

  const deleteConnection = useCallback(
    async (edges: Edge[]) => {
      edges.forEach(async (edge) => {
        edge?.data?.connectionTypes?.map(
          async (connection: TopologyResourceConnection) => {
            await deleteTopologyResourceConnection?.(
              graphData.id as string,
              connection
            );
          }
        );
        setSelectedEdge(null);
        setSelectedNode(null);
      });
    },
    [selected_node, graphData.id]
  );

  useEffect(() => {
    // Show missing attributes drawer if there are errors and user comes from quick appStack create flow
    if (
      topologyErrors &&
      topologyErrors?.totalErrors > 0 &&
      topologyPageMeta?.referrer === TopologyPageReferrer.QUICK_APPSTACK_CREATE
    ) {
      setShowMissingAttributesDrawer(true);
    }
  }, [topologyErrors?.totalErrors, topologyPageMeta]);

  const onNodesDelete = useCallback(
    async (nodes: Node[]) => {
      if (!showTopologyDiff) {
        // We are always deleting only one node at a time after selecting it
        const node = nodes[0];
        let findGroupResource = null;

        // This is to check if node is a child resource and it's parent is a group node(resourceType=group)
        if (node.data?.group) {
          findGroupResource = findResourceInTopology(
            graphData.resources,
            node.data.group
          );
        }

        if (
          !node.data?.group ||
          (node.data?.group &&
            findGroupResource &&
            findGroupResource?.isGroup &&
            findGroupResource?.resourceType !== "group")
        ) {
          await deleteTopologyResource?.(
            graphData?.appstackId as string,
            graphData.id as string,
            node.id
          );
          trackAmplitudeEvent(`delete_resource_${node?.data?.resourceType}`);

          if (
            validateTopology &&
            isFunction(validateTopology) &&
            graphData.id
          ) {
            validateTopology(graphData.id, [
              {
                id: node.id,
                resourceType: node?.data?.resourceType,
                isGroup: node?.data?.isGroup,
                label: "",
              },
            ]);
          }
          setShowResourcePropertiesForm({
            ...defaultShowResourcePropertiesForm,
            show: false,
          });
          setSelectedNode(null);
        }
      }
    },
    [setResources, graphData.id, graphData.resources, showTopologyDiff]
  );

  const onEdgesDelete = useCallback(
    async (edges: Edge[], manual_delete: boolean) => {
      if (!showTopologyDiff && manual_delete) {
        await deleteConnection(edges);
      }
    },
    [showTopologyDiff, deleteConnection]
  );

  const onEdgeClick = useCallback(
    async (edge: Edge) => {
      if (!showTopologyDiff) {
        // Fetch possible connection types everytime the edge is selected
        const possibleConnectionTypes: Array<SupportedConnectionType> =
          await getPossibleConnectionTypes(edge.source, edge.target);

        if (possibleConnectionTypes?.length > 1) {
          setResourceConnections((resource_connections: any) =>
            resource_connections.map((resource_connection: any) =>
              resource_connection.id === edge.id
                ? {
                    ...resource_connection,
                    selected: true,
                    data: {
                      ...resource_connection.data,
                      possibleConnectionTypes,
                      setResourceConnections,
                      newConnection: false,
                      createConnection,
                      deleteConnection,
                    },
                  }
                : resource_connection
            )
          );
        }
        setSelectedEdge(edge);
      }
    },
    [setSelectedEdge, showTopologyDiff, getPossibleConnectionTypes]
  );

  const onEdgeConnectionTypeClick = useCallback(
    async (selectedConnectionTypeData: any) => {
      if (!showTopologyDiff && graphData?.id && graphData?.resources) {
        // Fetch latest resource properties data
        const resource_connection_properties_data =
          await fetchResourceConnectionPropertiesValues?.(
            graphData.id,
            selectedConnectionTypeData.id
          );

        // Fetch resource template if not fetched earlier
        if (
          resourcePropertiesTemplates[
            selectedConnectionTypeData?.data?.resourceType
          ] === undefined
        ) {
          await fetchResourcePropertiesTemplate?.({
            resourceType: selectedConnectionTypeData?.data?.resourceType,
            templateId: selectedConnectionTypeData?.data?.templateId,
          });
        }

        setSelectedNode({
          ...resource_connection_properties_data?.data,
          id: resource_connection_properties_data?.id,
        } as unknown as TopologyResourceWithMisconfigurations);
        setShowResourcePropertiesForm({
          ...defaultShowResourcePropertiesForm,
          show: true,
          defaultTabId: defaultShowResourcePropertiesForm.defaultTabId,
          showValidationErrors: true,
        });
      }
    },
    [
      graphData.id,
      resourcePropertiesTemplates,
      fetchResourcePropertiesTemplate,
      setShowResourcePropertiesForm,
      showTopologyDiff,
    ]
  );

  const onNodeClick = useCallback(
    async (nodeObj: Node, node_type?: SelectedResourceType) => {
      if (!showTopologyDiff && graphData?.id && graphData?.resources) {
        // Fetch latest resource properties data
        const resource_properties_data = await fetchResourcePropertiesValues?.(
          graphData.id,
          nodeObj.id
        );

        if (nodeObj?.data?.resourceType !== "group") {
          // Fetch resource template if not fetched earlier
          if (
            resourcePropertiesTemplates[nodeObj?.data?.resourceType] ===
            undefined
          ) {
            await fetchResourcePropertiesTemplate?.({
              resourceType: nodeObj?.data?.resourceType,
              templateId: nodeObj?.data?.templateId,
            });
          }

          // Validate resource when clicked
          if (validateTopology && isFunction(validateTopology)) {
            validateTopology(graphData.id, [
              resource_properties_data as unknown as TopologyResourceWithMisconfigurations,
            ]);
          }
        }

        // Expand group node if selected node is a child of group node
        if (nodeObj?.data?.resourceType === "group") {
          updateGroupResourcesExpanded((prev) => ({
            ...prev,
            [nodeObj?.data?.group as string]: true,
          }));
        }
        setSelectedNode(
          resource_properties_data as unknown as TopologyResourceWithMisconfigurations
        );
        setShowResourcePropertiesForm({
          ...defaultShowResourcePropertiesForm,
          show: true,
          defaultTabId: node_type
            ? node_type === "policy_violation_node"
              ? "policy_violations"
              : "details"
            : defaultShowResourcePropertiesForm.defaultTabId,
          showValidationErrors:
            node_type === SelectedResourceType.VALIDATION_ERROR_NODE,
        });
      }
    },
    [
      graphData.id,
      resourcePropertiesTemplates,
      setSelectedNode,
      setShowResourcePropertiesForm,
      fetchResourcePropertiesTemplate,
      showTopologyDiff,
    ]
  );

  const onNodeDragStop = useCallback(
    async (_: MouseEvent, node: Node) => {
      if (!showTopologyDiff && node?.position) {
        await updateResourcePosition?.(
          graphData.id as string,
          node.id as string,
          {
            position: {
              x: Math.floor(node.position.x),
              y: Math.floor(node.position.y),
            },
          }
        );
      }
    },
    [graphData.id, showTopologyDiff]
  );

  const onDragStart = useCallback(
    (event: DragEvent, resourceType: string) => {
      if (!showTopologyDiff) {
        event.dataTransfer.setData("resourceType", resourceType);
        event.dataTransfer.effectAllowed = "move";
      }
    },
    [showTopologyDiff]
  );

  const onDragOver = useCallback(
    (event: DragEvent) => {
      if (!showTopologyDiff) {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
      }
    },
    [showTopologyDiff]
  );

  const onDrop = useCallback(
    async (event: DragEvent) => {
      if (!showTopologyDiff) {
        event.preventDefault();

        const resourceType = event.dataTransfer.getData("resourceType");

        // check if the dropped element is valid
        if (typeof resourceType === "undefined" || !resourceType) {
          return;
        }

        const position = screenToFlowPosition({
          x: event.clientX - 115,
          y: event.clientY - 25,
        });
        const dragged_resource = resourcesList?.[
          activeResourceTab as keyof ProviderWiseSupportedResourceType
        ]?.find((resource) => resource?.resourceType === resourceType);
        let absoluteResourcePosition: any = null;
        if (dragged_resource && position) {
          let groupNode: any = null;
          resources.forEach((resource) => {
            if (resource.type === "nestedGroupNode") {
              if (resource?.parentNode) {
                absoluteResourcePosition = findChildNodeAbsolutePosition(
                  resource,
                  resources
                );
              } else {
                absoluteResourcePosition = resource.position;
              }
              if (
                absoluteResourcePosition?.x <= position.x &&
                absoluteResourcePosition?.x +
                  parseInt(resource.style?.width?.toString() || "0") >=
                  position.x &&
                absoluteResourcePosition?.y <= position.y &&
                absoluteResourcePosition?.y +
                  parseInt(resource.style?.height?.toString() || "0") >=
                  position.y
              ) {
                groupNode = resource;
              }
            }
          });

          await createTopologyResource?.(
            graphData.id as string,
            {
              resourceType: dragged_resource.resourceType as string,
              iacType: dragged_resource.iacType,
              templateId: dragged_resource.templateId,
              groupType: dragged_resource?.groupType,
              isGroup: dragged_resource?.isGroup,
              ...(groupNode
                ? {
                    group: groupNode?.id,
                    position: {
                      x: position.x! - absoluteResourcePosition?.x,
                      y: position.y! - absoluteResourcePosition?.y,
                    },
                  }
                : { position }),
            },
            (resource) => {
              trackAmplitudeEvent(`drag_drop_${resource.resourceType}`);

              // This is to refetch topology graph data after adding a group type resource
              if (
                resource.group ||
                targetComputeResourceTypes.includes(resource.resourceType) ||
                resource.isGroup
              ) {
                refetchTopologyGraphData?.();
              }
              // Select node automatically after adding it to the graph
              onNodeClick({
                id: resource.id as string,
                data: resource,
                position: position,
              });
            }
          );
        }
      }
    },
    [
      reactFlowInstance,
      resourcesList,
      graphData.id,
      showTopologyDiff,
      activeResourceTab,
      resources,
    ]
  );

  const onConnect = useCallback(
    async (connection: Connection) => {
      if (!showTopologyDiff) {
        const possibleConnectionTypes: Array<SupportedConnectionType> =
          await getPossibleConnectionTypes(
            connection.source as string,
            connection.target as string
          );
        if (possibleConnectionTypes?.length > 0) {
          if (possibleConnectionTypes.length === 1) {
            // If there is only one type of connection possible
            await createConnection(connection, possibleConnectionTypes[0]);
          } else {
            // If there are multiple types of connections possible
            setResourceConnections((resource_connections) =>
              addEdge(
                {
                  ...connection,
                  id:
                    (connection.source as string) >
                    (connection.target as string)
                      ? `#${connection.source}-#${connection.target}`
                      : `#${connection.target}-#${connection.source}`,
                  source: connection.source as string,
                  target: connection.target as string,
                  type: "customEdge",
                  style: { strokeWidth: 2, stroke: "#374252" },
                  data: {
                    possibleConnectionTypes,
                    setResourceConnections,
                    newConnection: true,
                    createConnection,
                    deleteConnection,
                  },
                  selected: true,
                },
                resource_connections
              )
            );
          }
        }
      }
    },
    [
      showTopologyDiff,
      getPossibleConnectionTypes,
      createConnection,
      deleteConnection,
    ]
  );

  const onConnectStart = useCallback(
    (_: unknown, params: OnConnectStartParams) => {
      setConnectionLineStyle({
        stroke: "#3FA9F5", // tw-text-blue-500
        //TODO: use theme configs from tailwind config file https://tailwindcss.com/docs/configuration#referencing-in-java-script
        strokeWidth: 2,
      });
    },
    []
  );

  const onConnectEnd = useCallback(() => {
    setConnectionLineStyle({});
  }, []);

  const handleActiveCustomResourceAction = async (
    resource: SupportedResourceType,
    action: string
  ) => {
    switch (action) {
      case "delete":
        setActiveCustomResourceForAction(resource);
        setShowCustomResourceDeleteDialog(true);
        break;
      case "edit":
        const resource_template = await getCustomTemplateByResourceId({
          id: resource.templateId as string,
        });
        setActiveCustomResourceTemplateForAction(resource_template);
        setShowResourcePropertiesForm({
          ...showResourcePropertiesForm,
          show: false,
        });
        setShowCustomResourceDrawer(true);
        break;
    }
  };

  useEffect(() => {
    if (graphData.resources && graphData.resources?.length > 0) {
      const temp_graph_data: {
        resources: Resource[];
        resourceConnections: { [key: string]: ResourceConnection };
      } = { resources: [], resourceConnections: {} };

      let unsupportedResourcesCount = 0;

      graphData?.resources?.forEach((resource: any) => {
        if (
          resource?.isGroup &&
          resource?.groupType &&
          resource?.resourceType !== "group"
        ) {
          temp_graph_data.resources?.push({
            ...resource,
            selected: selected_node?.id === resource?.id,
            hidden:
              hideUnSupportedResources &&
              unsupportedResourceTypes.includes(resource?.resourceType),
            errors: showTopologyDiff
              ? undefined
              : topologyErrors?.resourcesWithErrors?.[resource?.id as string],
            external_resource: resource?.resourceType === "aws_external",
            children:
              resource?.children?.length > 0
                ? traverseAndUpdateEachResource(
                    resource?.children,
                    (child_resource: any) => {
                      return {
                        ...child_resource,
                        selected: selected_node?.id === child_resource?.id,
                        hidden:
                          hideUnSupportedResources &&
                          unsupportedResourceTypes.includes(
                            child_resource?.resourceType
                          ),
                        errors: showTopologyDiff
                          ? undefined
                          : topologyErrors?.resourcesWithErrors?.[
                              child_resource?.id as string
                            ],
                        external_resource:
                          child_resource?.resourceType === "aws_external",
                      };
                    }
                  )
                : undefined,
          });
        } else if (resource?.resourceType === "group") {
          temp_graph_data.resources?.push({
            ...resource,
            expanded: showTopologyDiff
              ? true
              : group_resources_expanded[resource.id as string] ?? true,
            updateExpanded: function (expanded: boolean) {
              if (!showTopologyDiff) {
                updateGroupResourcesExpanded((prev) => ({
                  ...prev,
                  [resource.id as string]: expanded,
                }));
              }
            },
            selected: selected_node?.id === resource?.id,
            children:
              resource?.children?.length > 0
                ? traverseAndUpdateEachResource(
                    resource?.children,
                    (child_resource: any) => {
                      return {
                        ...child_resource,
                        // Hide child node if group node is collapsed
                        hidden: showTopologyDiff
                          ? false
                          : group_resources_expanded[child_resource?.group] ===
                            undefined
                          ? false
                          : !group_resources_expanded[child_resource?.group],
                        selected: selected_node?.id === child_resource?.id,
                        errors: showTopologyDiff
                          ? undefined
                          : topologyErrors?.resourcesWithErrors?.[
                              child_resource?.id as string
                            ],
                        external_resource:
                          child_resource?.resourceType === "aws_external",
                      };
                    }
                  )
                : undefined,
          });
        } else {
          temp_graph_data.resources?.push({
            ...resource,
            selected: selected_node?.id === resource?.id,
            hidden:
              hideUnSupportedResources &&
              unsupportedResourceTypes.includes(resource?.resourceType),
            errors: showTopologyDiff
              ? undefined
              : topologyErrors?.resourcesWithErrors?.[resource?.id as string],
            external_resource: resource?.resourceType === "aws_external",
          });
        }

        // Count unsupported resources in topology
        if (unsupportedResourceTypes.includes(resource?.resourceType)) {
          unsupportedResourcesCount++;
        }
      });

      graphData?.resourceConnections?.forEach(
        (
          resource_connection: TopologyResourceConnection & {
            diffType?: "created" | "deleted";
            hidden?: boolean;
          }
        ) => {
          if (
            resource_connection.sourceResourceId &&
            resource_connection.targetResourceId
          ) {
            const edgeId =
              resource_connection.sourceResourceId >
              resource_connection.targetResourceId
                ? `${resource_connection.sourceResourceId}-${resource_connection.targetResourceId}`
                : `${resource_connection.targetResourceId}-${resource_connection.sourceResourceId}`;
            let edgeIsHidden = false;

            // Check if any edge is inside the group node and group is not expanded
            const findSourceResource = findResourceInTopology(
              temp_graph_data?.resources,
              resource_connection?.sourceResourceId
            );
            const findTargetResource = findResourceInTopology(
              temp_graph_data?.resources,
              resource_connection?.targetResourceId
            );
            if (
              findSourceResource &&
              findTargetResource &&
              (findSourceResource?.hidden || findTargetResource?.hidden)
            ) {
              edgeIsHidden = true;
            }

            // Group edges based on common source and target resources, and show only one edge with multiple connection types
            if (temp_graph_data.resourceConnections[edgeId]) {
              temp_graph_data.resourceConnections[edgeId].data = {
                ...temp_graph_data.resourceConnections[edgeId].data,
                hidden:
                  temp_graph_data.resourceConnections[edgeId].data.hidden ||
                  resource_connection.hidden ||
                  edgeIsHidden,
                connectionTypes: [
                  ...temp_graph_data.resourceConnections[edgeId].data
                    .connectionTypes,
                  {
                    ...resource_connection,
                    onConnectionTypeClick: (connection_data: any) => {
                      onEdgeConnectionTypeClick(connection_data);
                    },
                    selected_resource_id: selected_node?.id,
                  },
                ],
              };
            } else {
              temp_graph_data.resourceConnections[edgeId] = {
                id: edgeId,
                sourceResourceId: resource_connection.sourceResourceId,
                targetResourceId: resource_connection.targetResourceId,
                data: {
                  hidden: edgeIsHidden || resource_connection.hidden,
                  connectionTypes: [
                    {
                      ...resource_connection,
                      onConnectionTypeClick: (connection_data: any) => {
                        onEdgeConnectionTypeClick(connection_data);
                      },
                      selected_resource_id: selected_node?.id,
                    },
                  ],
                },
              };
            }
          }
        }
      );

      if (unsupportedResourcesCount > 0) {
        setUnsupportedResourcesExists(true);
      } else {
        setUnsupportedResourcesExists(false);
      }

      graphData = {
        ...graphData,
        resources: temp_graph_data.resources,
        resourceConnections: Object.values(temp_graph_data.resourceConnections),
      };

      getELKGraphPositions(graphData)
        .then(({ layoutedNodes, layoutedEdges }: any) => {
          setResources(layoutedNodes);
          setResourceConnections(layoutedEdges);

          if (!showTopologyDiff) {
            // Check which resource doesn't have position in data object
            const resources_without_position = layoutedNodes?.filter(
              (node: Node) =>
                node?.data?.position?.x === undefined ||
                node?.data?.position?.y === undefined
            );
            if (resources_without_position.length > 0) {
              const resourceIdWithPosition = resources_without_position.map(
                (node: Node) => {
                  return {
                    resourceId: node.id,
                    position: {
                      x: Math.floor(node.position.x),
                      y: Math.floor(node.position.y),
                    },
                  };
                }
              );
              updateBulkResourcePosition?.(
                graphData.id as string,
                resourceIdWithPosition
              );
            }
          }
        })
        .catch(console.error);
    }
  }, [
    group_resources_expanded,
    selected_node,
    graphData,
    updateGroupResourcesExpanded,
    setResources,
    setResourceConnections,
    topologyErrors,
    showTopologyDiff,
    hideUnSupportedResources,
  ]);

  const deleteCustomResource = async (resource: SupportedResourceType) => {
    const isSuccessResponse = await deleteCustomResourceTemplate({
      uuid: resource.templateId as string,
    });
    if (isSuccessResponse) {
      setActiveCustomResourceForAction({});
      refetchResourcesList();
    }
  };

  if (graphData?.id) {
    return (
      <div className="tw-relative">
        <div className="tw-w-full tw-h-[calc(100vh-11.5rem)]">
          <ReactFlow
            panOnScroll={true}
            fitView={true}
            nodes={resources}
            edges={resourceConnections}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            onInit={setReactFlowInstance}
            proOptions={{ hideAttribution: true }}
            nodeDragThreshold={1}
            connectionMode={ConnectionMode.Loose}
            onNodeDragStop={onNodeDragStop}
            onNodesChange={(changes) => {
              if (
                !(
                  selected_node &&
                  selected_node.group &&
                  changes[0].type === "remove"
                )
              ) {
                onResourcesChange(changes);
              }
            }}
            onNodesDelete={onNodesDelete}
            onEdgesDelete={(...props) =>
              onEdgesDelete(...props, selected_edge ? true : false)
            }
            onConnect={onConnect}
            onConnectStart={onConnectStart}
            onConnectEnd={onConnectEnd}
            connectionLineStyle={connectionLineStyle}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onNodeClick={(event: MouseEvent, node: Node) => onNodeClick(node)}
            onEdgeClick={(event: MouseEvent, edge: Edge) => onEdgeClick(edge)}
            deleteKeyCode={showTopologyDiff ? null : "Backspace"}
            snapGrid={[20, 20]}
            snapToGrid={true}
            minZoom={0.1}
            maxZoom={1}
          >
            <Background variant={BackgroundVariant.Dots} gap={12} size={0.5} />
            {graphData?.resources?.length === 0 && (
              <>
                <Panel
                  position="top-center"
                  className="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center tw-text-gray-400 !tw-top-1/3"
                >
                  <div>
                    <Icon
                      name={IconsList.ARROW_UP_FROM_SQUARE}
                      size={IconSize["3xl"]}
                    />
                  </div>
                  <div className="tw-text-base">
                    Drag & drop resources to create your own IaC
                  </div>
                </Panel>
                {!showTopologyDiff && (
                  <Panel position="top-left">
                    <LLMPrompt
                      appStackUUID={graphData?.appstackId as string}
                      onSuccess={(successData) => {
                        if (successData?.appstack_uuid) {
                          refetchTopologyGraphData?.();
                        }
                      }}
                    />
                  </Panel>
                )}
              </>
            )}
            {showTopologyDiff && (
              <Panel position="top-center" className="tw-w-full tw-m-0 tw-p-2">
                <CompareTopology
                  appStackVersions={appStackVersions}
                  graphData={graphData}
                  getTopologyDiff={getTopologyDiff}
                  setShowTopologyDiff={setShowTopologyDiff}
                />
              </Panel>
            )}
            {!showTopologyDiff && (
              <Panel position="bottom-left">
                <div className="tw-flex tw-gap-3">
                  {resourcesList && Object.keys(resourcesList)?.length > 0 && (
                    <div
                      className="tw-flex tw-gap-2 tw-items-center tw-bg-gray-800 tw-text-xssm tw-text-gray-200 tw-rounded-md tw-border tw-border-gray-600 tw-p-2 tw-cursor-pointer hover:tw-bg-gray-700"
                      onClick={() => setShowResourcesList(true)}
                      data-testid="supported-resources-list"
                    >
                      <Icon
                        name={IconsList.CIRCLE_PLUS}
                        className="tw-text-gray-200"
                      />
                      <span>Add New Resource</span>
                    </div>
                  )}
                  {topologyErrors?.totalErrors ? (
                    <Popover
                      trigger={
                        <div className="tw-flex tw-gap-2 tw-items-center tw-bg-gray-800 tw-text-xssm tw-text-gray-200 tw-rounded-md tw-border tw-border-gray-600 tw-p-2 tw-cursor-pointer hover:tw-bg-gray-700">
                          <Icon name={IconsList.BUG} />
                          <div className="tw-flex tw-gap-2">
                            <span>Actions</span>
                            <Tag
                              title={"" + topologyErrors?.totalErrors}
                              size="md"
                              className="tw-bg-red-400 tw-font-semibold tw-text-gray-900"
                            />
                          </div>
                        </div>
                      }
                      heading={
                        <div className="tw-flex tw-justify-between tw-items-center tw-w-full">
                          <div className="tw-flex tw-gap-2 tw-text-gray-200">
                            <Icon name={IconsList.BUG} />
                            <span className="tw-text-xssm tw-font-medium">
                              Actions
                            </span>
                          </div>
                          <Link
                            text="Fix Missing Attributes"
                            dataTestId="fix-missing-attributes-action"
                            className="tw-text-xssm tw-cursor-pointer hover:tw-no-underline"
                            onClick={() => {
                              setShowMissingAttributesDrawer(true);
                            }}
                          />
                        </div>
                      }
                      body={Object.keys(
                        topologyErrors.resourcesWithErrors
                      )?.map((resourceId: string, index) => {
                        const resource_obj = findResourceInTopology(
                          graphData?.resources,
                          resourceId
                        );
                        if (resource_obj) {
                          return (
                            <Fragment key={`${resourceId}_${index}`}>
                              {(topologyErrors?.resourcesWithErrors?.[
                                resourceId
                              ]?.policyViolationErrors as number) > 0 && (
                                <div
                                  className="tw-p-4 tw-flex tw-gap-2 tw-items-center tw-border-b tw-border-gray-600 tw-cursor-pointer"
                                  onClick={() =>
                                    onNodeClick(
                                      {
                                        id: resource_obj.id as string,
                                        data: resource_obj,
                                        position: {
                                          x: resource_obj?.position
                                            ?.x as number,
                                          y: resource_obj?.position
                                            ?.y as number,
                                        },
                                      },
                                      SelectedResourceType.POLICY_VIOLATION_NODE
                                    )
                                  }
                                  data-testid={`policy-violation-node-${resourceId}`}
                                >
                                  <div className="tw-basis-4">
                                    <Icon
                                      name={IconsList.SHIELD_EXCLAMATION}
                                      className="tw-text-yellow-400"
                                    />
                                  </div>
                                  <div>
                                    <span className="tw-text-xs tw-font-bold tw-text-gray-100">
                                      {resource_obj?.label ||
                                        resource_obj?.resourceTypeLabel}
                                    </span>
                                    <span className="tw-text-xssm tw-font-medium tw-text-gray-200">{` has ${topologyErrors.resourcesWithErrors[resourceId]?.policyViolationErrors} policy violation error(s).`}</span>
                                  </div>
                                </div>
                              )}
                              {(topologyErrors.resourcesWithErrors[resourceId]
                                ?.validationErrors as number) > 0 && (
                                <div
                                  className="tw-p-4 tw-flex tw-gap-2 tw-items-center tw-border-b tw-border-gray-600 tw-cursor-pointer"
                                  onClick={() =>
                                    onNodeClick(
                                      {
                                        id: resource_obj.id as string,
                                        data: resource_obj,
                                        position: {
                                          x: resource_obj?.position
                                            ?.x as number,
                                          y: resource_obj?.position
                                            ?.y as number,
                                        },
                                      },
                                      SelectedResourceType.VALIDATION_ERROR_NODE
                                    )
                                  }
                                  data-testid={`validation-error-node-${resourceId}`}
                                >
                                  <div className="tw-basis-4">
                                    <Icon
                                      name={IconsList.TRIANGLE_EXCLAMATION}
                                      size={IconSize.sm}
                                      className="tw-text-red-400"
                                    />
                                  </div>
                                  <div>
                                    <span className="tw-text-xs tw-font-bold tw-text-gray-100">
                                      {resource_obj?.label ||
                                        resource_obj?.resourceTypeLabel}
                                    </span>
                                    <span className="tw-text-xssm tw-font-medium tw-text-gray-200">
                                      {" "}
                                      is missing required information.
                                    </span>
                                  </div>
                                </div>
                              )}
                            </Fragment>
                          );
                        } else {
                          return null;
                        }
                      })}
                      dataTestId="errors-popover"
                    />
                  ) : (
                    <Popover
                      trigger={
                        <div className="tw-flex tw-gap-2 tw-items-center tw-bg-gray-800 tw-text-xssm tw-text-gray-200 tw-rounded-md tw-border tw-border-gray-600 tw-p-2 tw-cursor-pointer hover:tw-bg-gray-700">
                          {topologyErrors?.isValidated ? (
                            <>
                              <Icon name={IconsList.BUG} />
                              <div>No Actions</div>
                            </>
                          ) : (
                            <div className="tw-flex tw-gap-2 tw-items-center">
                              <Spinner
                                className="tw-h-5 tw-w-5"
                                dataTestId="validating-topology-loader"
                              />
                              Validating the topology...
                            </div>
                          )}
                        </div>
                      }
                      heading={
                        <div className="tw-flex tw-gap-2 tw-text-gray-200">
                          <Icon name={IconsList.BUG} />
                          <span className="tw-text-xssm tw-font-medium">
                            Actions
                          </span>
                        </div>
                      }
                      body={
                        <div className="tw-p-4 tw-flex tw-gap-2 tw-items-center tw-border-b tw-border-gray-600">
                          {topologyErrors?.isValidated ? (
                            <>
                              <div className="tw-basis-4">
                                <Icon
                                  name={IconsList.CHECK_CIRCLE}
                                  className="tw-text-green-500"
                                />
                              </div>
                              <span className="tw-text-xssm tw-font-medium tw-text-gray-200">
                                There are no actions, the topology is valid.
                              </span>
                            </>
                          ) : (
                            <>
                              <div className="tw-basis-4">
                                <Spinner
                                  className="tw-h-5 tw-w-5"
                                  dataTestId="validating-topology-loader"
                                />
                              </div>
                              <span className="tw-text-xssm tw-font-medium tw-text-gray-200">
                                Validating the topology...
                              </span>
                            </>
                          )}
                        </div>
                      }
                      dataTestId="no-errors-popover"
                    />
                  )}
                  {unsupportedResourcesExists && (
                    <>
                      <div className="tw-border-2 tw-border-gray-600"></div>
                      <div
                        className="tw-flex tw-gap-3 tw-items-center tw-text-sm tw-font-normal tw-text-white tw-py-2 tw-cursor-pointer"
                        onClick={() =>
                          setHideUnSupportedResources((state) => !state)
                        }
                        data-testid="unsupported-resources-toggle"
                      >
                        {hideUnSupportedResources
                          ? "View unsupported resources"
                          : "Hide unsupported resources"}
                      </div>
                    </>
                  )}
                </div>
              </Panel>
            )}
            <Panel position="bottom-right">
              <div className="tw-flex tw-items-center tw-border tw-border-gray-600 tw-rounded-md tw-bg-gray-800 tw-cursor-pointer">
                <div
                  className="tw-border-r tw-border-gray-600 tw-p-2 hover:tw-bg-gray-700 tw-text-gray-200"
                  onClick={() => fitView({ duration: 800 })}
                >
                  <Icon name={IconsList.EXPAND} />
                </div>
                <div
                  className={`tw-border-r tw-border-gray-600 tw-p-2 hover:tw-bg-gray-700 tw-text-gray-200 ${
                    isMinZoomLevel ? "tw-opacity-50 tw-cursor-not-allowed" : ""
                  }`}
                  onClick={() => zoomOut({ duration: 800 })}
                  data-testid="zoom-out"
                >
                  <Icon name={IconsList.MINUS} />
                </div>
                <div
                  className="tw-border-r tw-border-gray-600 tw-py-2 tw-px-3 hover:tw-bg-gray-700 tw-text-xssm tw-text-gray-300"
                  onClick={handleTransform}
                  data-testid="reset-zoom"
                >
                  {Math.round(currentZoomLevel * 100)}%
                </div>
                <div
                  className={`tw-border-r tw-border-gray-600 tw-p-2 hover:tw-bg-gray-700 tw-text-gray-200 ${
                    isMaxZoomLevel ? "tw-opacity-50 tw-cursor-not-allowed" : ""
                  }`}
                  onClick={() => zoomIn({ duration: 800 })}
                  data-testid="zoom-in"
                >
                  <Icon name={IconsList.PLUS_LARGE} />
                </div>
              </div>
            </Panel>
          </ReactFlow>
        </div>

        {!showTopologyDiff &&
          resourcesList &&
          Object.keys(resourcesList)?.length > 0 && (
            <Drawer
              open={showResourcesList ? true : false}
              size="xs"
              isFullScreenDrawer={false}
              position="Left"
              dataTestId="supported-resource-list"
            >
              <SupportedResourceList
                resourcesList={resourcesList}
                onResourceTabChange={(
                  resourceType: keyof ProviderWiseSupportedResourceType
                ) => setActiveResourceTab(resourceType)}
                activeResourceTab={activeResourceTab}
                onPanelClose={() => setShowResourcesList(false)}
                onDragStart={onDragStart}
                cloudService={graphData.deploymentType as string}
                onAddCustomResource={() => {
                  setShowCustomResourceDrawer(true);
                  setShowResourcePropertiesForm({
                    ...showResourcePropertiesForm,
                    show: false,
                  });
                }}
                onActiveCustomResourceAction={(resource, action) =>
                  handleActiveCustomResourceAction(resource, action)
                }
              />
            </Drawer>
          )}

        {!showTopologyDiff && (
          <Drawer
            open={
              showResourcePropertiesForm.show &&
              selected_node &&
              resourcePropertiesTemplates[selected_node?.resourceType]
                ? true
                : false
            }
            size="sm"
            isFullScreenDrawer={false}
            isResizable={true}
            minResizableWidth={300}
            maxResizableWidth={600}
            dataTestId="resource-properties-drawer"
          >
            {showResourcePropertiesForm.show &&
              selected_node &&
              resourcePropertiesTemplates[selected_node?.resourceType] && (
                <ResourcePropertiesForm
                  resourcePropertiesTemplate={
                    resourcePropertiesTemplates[selected_node?.resourceType]
                  }
                  resource={selected_node}
                  onUpdateResourcePropertiesValues={async (resource) => {
                    if (graphData.id && resource.id) {
                      let updated_properties: any = resource?.connectionType
                        ? await updateResourceConnectionPropertiesValues(
                            graphData.id,
                            resource.id,
                            {
                              _configuration: resource._configuration,
                              tfVars: resource.tfVars,
                            }
                          )
                        : await updateResourcePropertiesValues(
                            graphData.id,
                            resource.id,
                            resource
                          );

                      if (resource?.connectionType) {
                        updated_properties = {
                          ...updated_properties?.data,
                          id: updated_properties?.id,
                        };
                      }

                      if (
                        validateTopology &&
                        isFunction(validateTopology) &&
                        updated_properties
                      ) {
                        validateTopology(graphData.id, [updated_properties]);
                      }
                      setSelectedNode((prev) => {
                        if (
                          prev &&
                          updated_properties &&
                          prev.id === updated_properties.id
                        ) {
                          return updated_properties;
                        } else {
                          return prev;
                        }
                      });
                      return updated_properties;
                    }
                  }}
                  onPanelClose={() => {
                    setShowResourcePropertiesForm({
                      ...defaultShowResourcePropertiesForm,
                      show: false,
                    });
                    setSelectedNode(null);
                  }}
                  defaultSelectedTabId={showResourcePropertiesForm.defaultTabId}
                  resourceHasErrors={
                    showResourcePropertiesForm.showValidationErrors ||
                    topologyErrors?.resourcesWithErrors[
                      selected_node?.id as string
                    ]?.validationErrors
                      ? true
                      : false
                  }
                  showResourcePropertiesErrors={
                    showResourcePropertiesForm.showValidationErrors
                  }
                  onEditCustomResource={async (fetchTemplate?: boolean) => {
                    if (fetchTemplate) {
                      handleActiveCustomResourceAction(selected_node, "edit");
                    } else {
                      const resourceTemplate = {
                        provider: provider,
                        iacType: "Terraform", // TODO: Remove this hardcoded data once we start collecting it from UI
                        label: "",
                        resourceId: selected_node?.id,
                        resourceType: (selected_node?._configuration as any)
                          ?.type,
                        variables: (selected_node?._configuration as any)
                          ?.attributes
                          ? Object.keys(
                              (selected_node?._configuration as any)?.attributes
                            )?.map((attribute) => ({
                              name: attribute,
                            }))
                          : [],
                      };
                      setActiveCustomResourceTemplateForAction(
                        resourceTemplate
                      );
                      setShowCustomResourceDrawer(true);
                      setShowResourcePropertiesForm({
                        ...showResourcePropertiesForm,
                        show: false,
                      });
                    }
                  }}
                  markResourceAsExternal={async (resourceId: string) => {
                    let external_resource: any = await markResourceAsExternal(
                      resourceId
                    );

                    if (graphData.id && external_resource) {
                      if (
                        validateTopology &&
                        isFunction(validateTopology) &&
                        external_resource
                      ) {
                        validateTopology(graphData.id, [external_resource]);
                      }
                      setSelectedNode((prev) => {
                        if (
                          prev &&
                          external_resource &&
                          prev.id === external_resource.id
                        ) {
                          return external_resource;
                        } else {
                          return prev;
                        }
                      });

                      return external_resource;
                    } else {
                      return null;
                    }
                  }}
                />
              )}
          </Drawer>
        )}

        <CustomResourceDrawer
          open={showCustomResourceDrawer}
          customResourceToEdit={activeCustomResourceTemplateForAction}
          onClose={(shouldRefreshResourceList?: boolean) => {
            if (shouldRefreshResourceList) {
              refetchResourcesList(); // refresh resource list to show newly added resource in custom resource list
              setSelectedNode(null); // reset node selection
              // Refetch topology after custom resource edit/addition to reflect changes
              if (isFunction(refetchTopologyGraphData)) {
                refetchTopologyGraphData?.();
              }
              const resourceType =
                activeCustomResourceTemplateForAction?.resourceType as string;
              const resourceTemplate =
                resourcePropertiesTemplates[resourceType];

              if (activeCustomResourceTemplateForAction?.id) {
                // fetch properties template for newly added custom resource
                fetchResourcePropertiesTemplate?.({
                  resourceType,
                  templateId: activeCustomResourceTemplateForAction?.id,
                });
              }
            }
            setShowCustomResourceDrawer(false);
            setActiveCustomResourceTemplateForAction(null);
          }}
          provider={provider}
        />

        <GlobalResourceTagsDrawer
          open={showGlobalResourceTagsDrawer}
          initialTags={globalResourceTags}
          onClose={() => setShowGlobalResourceTagsDrawer(false)}
          onSubmit={onSubmitGlobalResourceTags}
        />

        {showCustomResourceDeleteDialog && (
          <Dialog
            title="Delete Token"
            bodyContent={
              <>
                <div className="tw-text-sm tw-text-gray-50">
                  Are you sure you want to delete this custom resource?
                </div>
              </>
            }
            size="sm"
            footerContent={
              <div className="tw-flex tw-justify-end tw-gap-3">
                <Button
                  variant="secondary"
                  label="Cancel"
                  onClick={() => {
                    setShowCustomResourceDeleteDialog(false);
                    setActiveCustomResourceForAction({});
                  }}
                  dataTestId="cancel-delete-custom-resource-button"
                />
                <Button
                  variant="danger"
                  label={"Remove"}
                  onClick={() => {
                    deleteCustomResource(activeCustomResourceForAction);
                    setShowCustomResourceDeleteDialog(false);
                  }}
                  dataTestId="delete-custom-resource-button"
                />
              </div>
            }
            dataTestId="delete-custom-resource-dialog"
          />
        )}

        {showConfigureTFVarsDialog && (
          <ConfigureTFVarsDialog
            resources={graphData.resources as TopologyResourceWithPosition[]}
            resourcesWithErrors={
              topologyErrors?.resourcesWithErrors as {
                [key: string]: ResourceErrors;
              }
            }
            topologyId={graphData.id}
            resourcePropertiesTemplates={resourcePropertiesTemplates}
            onClose={() => {
              setShowConfigureTFVarsDialog(false);
            }}
            onSubmit={() => {
              setShowConfigureTFVarsDialog(false);
              refetchTopologyGraphData?.();
            }}
          />
        )}

        <FixMissingAttributesDrawer
          open={showMissingAttributesDrawer}
          resources={
            graphData.resources as TopologyResourceWithMisconfigurations[]
          }
          resourcesWithErrors={
            topologyErrors?.resourcesWithErrors as {
              [key: string]: ResourceErrors;
            }
          }
          resourcePropertiesTemplates={resourcePropertiesTemplates}
          onClose={() => {
            setShowMissingAttributesDrawer(false);
          }}
          openTFVarsDialog={() => {
            setShowConfigureTFVarsDialog(true);
            setShowMissingAttributesDrawer(false);
          }}
          topologyId={graphData.id}
          onSubmit={() => {
            setShowMissingAttributesDrawer(false);
            refetchTopologyGraphData?.();
          }}
        />
      </div>
    );
  } else {
    return (
      <div className="tw-text-sm" data-testid="no-topology-found-text">
        No topology data found.
      </div>
    );
  }
};

const TopologyGraphWrapper = ({ ...props }: TopologyGraphProps) => {
  return (
    <ReactFlowProvider>
      <TopologyGraph {...props} />
    </ReactFlowProvider>
  );
};

export default memo(TopologyGraphWrapper);
