import React, { useState, useEffect } from "react";
import type { FormikErrors, FormikTouched } from "formik";

import { Icon, IconsList, Button } from "../../components";

import type { PropertiesType } from "./topology-graph-types";
import GetFormElement from "./get-form-element";

const ResourcePropertiesFormElements = ({
  resource_attribute_obj,
  values,
  errors,
  touched,
  setFieldValue,
  tfVarValue,
  showTFVarsDialog,
  hideTFVar,
  selectMenuPosition,
  template_attributes,
}: {
  resource_attribute_obj: PropertiesType;
  values: any;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  tfVarValue?: string;
  showTFVarsDialog?: (field_props: any, updateTFVar?: boolean) => void;
  hideTFVar?: boolean;
  selectMenuPosition?: "absolute" | "fixed";
  template_attributes?: PropertiesType[];
}) => {
  // This is required in case of dependent attributes
  const [updatedValue, setUpdatedValue] = useState<null | PropertiesType>(null);

  const field_props: any = {
    type: resource_attribute_obj.type,
    name: resource_attribute_obj.key,
    label: resource_attribute_obj.label,
    placeholder: resource_attribute_obj.label,
    description: resource_attribute_obj.description,
    required: resource_attribute_obj.validation?.required,
    disabled: resource_attribute_obj.disabled,
    menuPosition: selectMenuPosition,
    dataTestId: `${resource_attribute_obj.key}-attribute-${resource_attribute_obj.type}`,
    backgroundVariant: "gray-800",
    fieldBackgroundVariant: "gray-800",
    options: resource_attribute_obj?.options?.items
      ? [
          {
            label: "-- select --",
            value: "",
          },
          ...(resource_attribute_obj.options?.items ?? []),
        ]
      : undefined,
    schema: resource_attribute_obj?.schema,
    linkAttributes: resource_attribute_obj?.linkAttributes
      ? {
          ...resource_attribute_obj.linkAttributes,
          disabled: resource_attribute_obj?.linkAttributes?.action?.disabled,
          template_attributes,
          values,
          errors,
          touched,
          setFieldValue,
        }
      : undefined,
    fallbackValue: resource_attribute_obj?.fallbackValue,
  };

  // Special handling for select type fields with dependent attributes
  useEffect(() => {
    // Check on higher level if dependentAttributes property is true
    if (
      resource_attribute_obj?.dependentAttributes &&
      values?.[resource_attribute_obj.key]
    ) {
      let updated_attribute_obj = field_props.options?.find(
        (option: any) => option.value === values[resource_attribute_obj.key]
      );
      if (updated_attribute_obj?.dependentAttributes) {
        setUpdatedValue(updated_attribute_obj);
      }
    }
  }, [values[resource_attribute_obj.key]]);

  if (!tfVarValue) {
    return (
      <>
        {!hideTFVar ? (
          <GetFormElement
            field_props={{
              ...field_props,
              labelSuffix: <Icon name={IconsList.TF_VARS} />, // prop for tf vars icon on form elements
              labelSuffixAction: () => showTFVarsDialog?.(field_props), // prop for tf vars icon click action
            }}
            values={values}
            errors={errors}
            touched={touched}
          />
        ) : (
          <GetFormElement
            field_props={field_props}
            values={values}
            errors={errors}
            touched={touched}
          />
        )}
        {updatedValue?.dependentAttributes && (
          <div className="tw-flex tw-flex-col tw-gap-4 tw-mt-4">
            {updatedValue.description && (
              <span className="tw-text-xssm tw-text-gray-100">
                {updatedValue.description}
              </span>
            )}
            {updatedValue?.dependentAttributes?.map(
              (dependent_attribute: any) => {
                let dependent_resource_attribute_obj =
                  template_attributes?.find(
                    (attribute) => attribute.key === dependent_attribute.key
                  );

                dependent_resource_attribute_obj = {
                  ...dependent_resource_attribute_obj,
                  ...dependent_attribute,
                };

                // Check if dependent_resource_attribute_obj is not empty after overrideAttributes
                if (!dependent_resource_attribute_obj) {
                  return null;
                }

                return (
                  <ResourcePropertiesFormElements
                    key={dependent_resource_attribute_obj.key}
                    template_attributes={template_attributes}
                    resource_attribute_obj={dependent_resource_attribute_obj}
                    values={values}
                    errors={errors}
                    touched={touched}
                    hideTFVar={true}
                    setFieldValue={setFieldValue}
                  />
                );
              }
            )}
          </div>
        )}
      </>
    );
  } else {
    return (
      <div>
        <label
          className={`tw-text-xssm tw-text-white tw-font-semibold`}
          data-testid={`text-box-label`}
        >
          {field_props.label}
          {resource_attribute_obj.validation?.required && (
            <span className="tw-text-red-400"> *</span>
          )}
        </label>
        <Button
          outlined
          variant="secondary"
          size="sm"
          dataTestId={`var_${values[field_props.name]}`}
          label={tfVarValue}
          className="tw-mt-2 tw-cursor-pointer"
          leftIcon={IconsList.TF_VARS}
          onClick={() => {
            showTFVarsDialog?.(field_props, true);
          }}
        />
      </div>
    );
  }
};

export default ResourcePropertiesFormElements;
