import * as amplitude from "@amplitude/analytics-browser";

// To initialize Amplitude
export const initAmplitude = (enabled: boolean) => {
  if (enabled) {
    amplitude.init("c0c878cfe328c731038a7e6e35cb2c20", {
      serverUrl:
        typeof window !== "undefined"
          ? `${window.location.origin}/amplitude`
          : undefined,
      defaultTracking: {
        pageViews: {
          trackOn: () =>
            typeof window !== "undefined"
              ? !window.location.pathname.includes("login")
              : true,
        },
        sessions: true,
        formInteractions: false,
        fileDownloads: false,
      },
    });
  }
};

// To set UserId when user logs in
export const setAmplitudeUserId = (userId: string) => {
  amplitude.setUserId(userId);
};

// To unset UserId when logging out user or login session is invalid
export const resetAmplitudeUser = () => {
  amplitude.reset();
};

// To track amplitude events along with event properties
export const trackAmplitudeEvent = (
  eventName: string,
  eventProperties?: { [key: string]: any }
) => {
  amplitude.logEvent(eventName, {
    custom_page_url: typeof window !== "undefined" ? window.location.href : "",
    ...(eventProperties ?? {}),
  });
};
