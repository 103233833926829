import { VarSchema, Variable } from "../apis/topology";
export enum CustomResourceDataType {
  NUMBER = "number",
  STRING = "string",
  BOOLEAN = "bool",
  LIST_STRING = "list(string)",
  LIST_NUMBER = "list(number)",
  LIST_BOOLEAN = "list(bool)",
  SET_STRING = "set(string)",
  SET_NUMBER = "set(number)",
  SET_BOOLEAN = "set(bool)",
  MAP_STRING = "map(string)",
  MAP_NUMBER = "map(number)",
  MAP_BOOLEAN = "map(bool)",
  OBJECT = "object",
  LIST_OBJECT = "list(object)",
}

export const booleanOptions = [
  { label: "true", value: true },
  { label: "false", value: false },
];
export const primitiveDataTypeList = [
  { label: "string", value: "string" },
  { label: "number", value: "number" },
  { label: "boolean", value: "bool" },
];

const handleNumber = (variable: Variable) => ({
  ...variable,
  schema: undefined,
  defaultVal: +variable.defaultVal, // converting to number
});

const handleListOrSetNumber = (variable: Variable) => ({
  ...variable,
  schema: undefined,
  defaultVal: variable.defaultVal.map((item: any) => +item), // converting to list of number
});

const handleMap = (variable: Variable) => {
  // filtering out defaults with empty key & values
  const filteredDefaultVal = variable.defaultVal.filter(
    (item: { key: string; value: any }) => item.key && item.value
  );
  return {
    ...variable,
    schema: undefined,
    defaultVal: filteredDefaultVal.reduce(
      (acc: Record<string, string | number | boolean>, curr: any) => {
        acc[curr.key] =
          variable.type === CustomResourceDataType.MAP_NUMBER
            ? +curr.value
            : curr.value;
        return acc;
      },
      {}
    ), // converting to list of object
  };
};

const handleObject = (variable: Variable) => ({
  ...variable,
  defaultVal: variable.defaultVal.reduce(
    (
      acc: Record<string, string | number | boolean>,
      value: string | number | boolean,
      index: number
    ) => {
      if (variable?.schema?.[index]?.key) {
        acc[variable.schema[index].key as string] =
          variable.schema[index].dataType === CustomResourceDataType.NUMBER
            ? +value
            : value;
      }
      return acc;
    },
    {}
  ), // converting to object
});

const handleListObject = (variable: Variable) => ({
  ...variable,
  schema: variable.schema?.filter((schema: VarSchema) => schema.key),
  defaultVal: variable.defaultVal.map((val: any) =>
    val.reduce(
      (
        acc: Record<string, string | number | boolean>,
        value: string | number | boolean,
        index: number
      ) => {
        if (variable.schema?.[index]?.key) {
          acc[variable.schema[index].key as string] =
            variable.schema[index].dataType === CustomResourceDataType.NUMBER
              ? +value
              : value;
        }
        return acc;
      },
      {}
    )
  ), // converting to list of object
});

const handleDefault = (variable: Variable) => {
  // filtering out defaults with empty string
  if (
    Array.isArray(variable.defaultVal) &&
    variable.defaultVal.length === 1 &&
    variable.defaultVal[0] === ""
  ) {
    return { ...variable, defaultVal: [] };
  } else {
    return { ...variable, schema: undefined };
  }
};

export const formikValuesToAttributeProperties = (variables: Variable[]) => {
  return variables.map((variable: Variable) => {
    switch (variable.type) {
      case CustomResourceDataType.NUMBER:
        return handleNumber(variable);
      case CustomResourceDataType.LIST_NUMBER:
      case CustomResourceDataType.SET_NUMBER:
        return handleListOrSetNumber(variable);
      case CustomResourceDataType.MAP_STRING:
      case CustomResourceDataType.MAP_NUMBER:
      case CustomResourceDataType.MAP_BOOLEAN:
        return handleMap(variable);
      case CustomResourceDataType.OBJECT:
        return handleObject(variable);
      case CustomResourceDataType.LIST_OBJECT:
        return handleListObject(variable);
      default:
        return handleDefault(variable);
    }
  });
};

export const attributeToFormikProperties = (variable: any) => {
  // setting up default values as empty string
  variable.name = variable.name ?? "";
  variable.label = !variable.label
    ? getCapitalizedVariableName(variable.name, 0)
    : variable.label;
  variable.type = variable.type ?? "";
  if (
    [
      CustomResourceDataType.MAP_STRING,
      CustomResourceDataType.MAP_NUMBER,
      CustomResourceDataType.MAP_BOOLEAN,
    ].includes(variable.type)
  ) {
    variable.defaultVal = Object.entries(variable.defaultVal).map(
      ([key, value]) => ({
        key,
        value,
      })
    );
  } else if (variable.type === CustomResourceDataType.OBJECT) {
    variable.defaultVal = Object.values(variable.defaultVal);
  } else if (variable.type === CustomResourceDataType.LIST_OBJECT) {
    variable.defaultVal = variable.defaultVal.map((obj: any) =>
      variable.schema?.map(({ key }: { key: string }) => obj[key])
    );
  } else {
    variable.defaultVal ?? "";
  }
  return variable;
};

export const getCapitalizedVariableName = (
  name: string | undefined,
  index: number
) => {
  if (name) {
    return name
      .split(/[\s_]+/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }
  return `Variable ${index + 1}`;
};
