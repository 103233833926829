import {
  V1ResourceApi,
  V1TopologyApi,
  V1ResourceConnectionApi,
  Configuration as IACGenConfiguration,
  PolicyApi,
  V1ImportApi,
  V1CustomResourceTemplateApi,
} from "../apis/topology";

import {
  AppStacksApi,
  ReposApi,
  AuthenticationApi,
  Configuration as AppStacksConfiguration,
  LabelsApi,
  SCMConnectorApi,
  TroubleshootingApi,
  HealthApi,
  APIKeyApi,
  FtuxApi,
} from "../apis/appcd";

import {
  Configuration as ViraConfiguration,
  RecommendationsApi,
} from "../apis/vira";

// APIs from appcd repository
export const appStacksApiObj = new AppStacksApi(
  new AppStacksConfiguration({ basePath: "/appcd/api/v1" })
);

export const reposApiObj = new ReposApi(
  new AppStacksConfiguration({ basePath: "/appcd/api/v1" })
);

export const authenticationApiObject = new AuthenticationApi(
  new AppStacksConfiguration({ basePath: "/appcd/api/v1" })
);

export const labelsApiObject = new LabelsApi(
  new AppStacksConfiguration({ basePath: "/appcd/api/v1" })
);

export const scmConnectorApiObject = new SCMConnectorApi(
  new AppStacksConfiguration({ basePath: "/appcd/api/v1" })
);

export const troubleshootingApiObject = new TroubleshootingApi(
  new AppStacksConfiguration({ basePath: "/appcd/api/v1" })
);

export const healthApiObject = new HealthApi(
  new AppStacksConfiguration({ basePath: "/appcd/api/v1" })
);

export const tokenApiObject = new APIKeyApi(
  new AppStacksConfiguration({ basePath: "/appcd/api/v1" })
);

export const ftuxApiObject = new FtuxApi(
  new AppStacksConfiguration({ basePath: "/appcd/api/v1" })
);

// APIs from iac-gen repository
export const v1TopologyApiObj = new V1TopologyApi(
  new IACGenConfiguration({ basePath: "/iac-gen" })
);

export const v1ResourceApiObj = new V1ResourceApi(
  new IACGenConfiguration({ basePath: "/iac-gen" })
);

export const v1ResourceConnectionApiObj = new V1ResourceConnectionApi(
  new IACGenConfiguration({ basePath: "/iac-gen" })
);

export const appStacksPolicyApiObject = new PolicyApi(
  new IACGenConfiguration({ basePath: "/iac-gen" })
);

export const v1ImportApiObject = new V1ImportApi(
  new IACGenConfiguration({ basePath: "/iac-gen" })
);

export const v1CustomResourceTemplateApiObj = new V1CustomResourceTemplateApi(
  new IACGenConfiguration({ basePath: "/iac-gen" })
);

// APIs from appcd-vira repository
export const recommendationsApiObj = new RecommendationsApi(
  new ViraConfiguration({ basePath: "/vira" })
);
