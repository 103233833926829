import React, { memo, useCallback } from "react";
import {
  getBezierPath,
  getSmoothStepPath,
  type EdgeProps,
  useStore,
  Position,
  MarkerType,
  EdgeLabelRenderer,
  applyEdgeChanges,
} from "reactflow";
import { Formik, Form } from "formik";

import { getEdgeParams } from "./flow-utils";

import { topologyResourceConnectionTypes } from "../../config/constants";

import { Icon, IconSize, IconsList, Checkbox } from "../../components";

import { SupportedConnectionType } from "../../apis/topology";

const CustomEdge = ({
  id,
  source,
  target,
  markerEnd,
  style,
  selected,
  data,
}: EdgeProps) => {
  const sourceNode = useStore(
    useCallback((store) => store.nodeInternals.get(source), [source])
  );
  const targetNode = useStore(
    useCallback((store) => store.nodeInternals.get(target), [target])
  );

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(
    sourceNode,
    targetNode
  );

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX: sx as number,
    sourceY: sy as number,
    sourcePosition: sourcePos as Position,
    targetPosition: targetPos as Position,
    targetX: tx as number,
    targetY: ty as number,
  });

  const updatedStyle = { ...style, strokeWidth: 2 };
  const isEdgeCreated = data?.connectionTypes?.some((connectionType: any) => connectionType?.diffType === "created");
  const isEdgeDeleted = data?.connectionTypes?.some((connectionType: any) => connectionType?.diffType === "deleted");

  // Update edge color and width if edge is selected or created/deleted
  if (isEdgeCreated) {
    updatedStyle.stroke = "#7AC943"; // tw-text-green-500
  } else if (isEdgeDeleted) {
    updatedStyle.stroke = "#F24A4A"; // tw-text-red-500
  } else if (selected) {
    updatedStyle.stroke = "#3FA9F5"; // tw-text-blue-500
  } else {
    updatedStyle.stroke = "#374252"; // tw-text-gray-500
  }

  return (
    <>
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
        style={updatedStyle}
        data-testid={`custom-edge-${id}`}
      />
      {data?.possibleConnectionTypes && selected && (
        <EdgeLabelRenderer>
          <div
            style={{
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              pointerEvents: "all",
              zIndex: 1000,
            }}
            className={`nodrag nopan tw-absolute`}
          >
            <div className="tw-border tw-border-gray-500 tw-bg-gray-600 tw-rounded-md tw-w-52">
              <div className="tw-flex tw-justify-between tw-px-3 tw-py-2 tw-border-b tw-border-gray-500">
                <span className="tw-text-gray-200 tw-font-medium tw-text-xs">
                  Configure Connection
                </span>
                <span
                  className="tw-text-gray-200 tw-cursor-pointer hover:tw-text-gray-100"
                  onClick={(e) => {
                    e.stopPropagation();
                    data?.setResourceConnections((resource_connections: any) =>
                      applyEdgeChanges(
                        [
                          data?.newConnection
                            ? {
                              type: "remove",
                              id,
                            }
                            : {
                              type: "select",
                              id,
                              selected: false,
                            },
                        ],
                        resource_connections
                      )
                    );
                  }}
                >
                  <Icon name={IconsList.CLOSE} size={IconSize.sm} />
                </span>
              </div>
              <Formik
                enableReinitialize
                initialValues={{
                  connectionType: data?.connectionTypes?.map(
                    (connectionTypeData: any) => connectionTypeData?.data?.connectionType) ?? [],
                  clickedConnectionTypeData: null,
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  if (data?.newConnection) {
                    // Taking only first value because for new connection only one connection type would be there
                    if (values.clickedConnectionTypeData) {
                      await data?.createConnection(
                        { source, target },
                        values.clickedConnectionTypeData
                      );
                    }
                  } else {
                    const isConnectionTypeDeSelected = data.connectionTypes?.find((typeData: any) => typeData?.data?.connectionType === (values as any)?.clickedConnectionTypeData?.type);
                    if (isConnectionTypeDeSelected) {
                      await data?.deleteConnection([{ data: { connectionTypes: [isConnectionTypeDeSelected] } }]);
                    } else {
                      await data?.createConnection(
                        { source, target },
                        values.clickedConnectionTypeData
                      );
                    }
                  }
                  setSubmitting(false);
                }}
              >
                {({ setFieldValue, submitForm }) => (
                  <Form>
                    {data?.possibleConnectionTypes?.map(
                      (connectionTypeData: SupportedConnectionType, index: number) => {
                        if (connectionTypeData.type) {
                          return (
                            <div
                              key={connectionTypeData.type}
                              className={`tw-flex tw-gap-3 tw-items-center tw-py-2 tw-px-3 tw-cursor-pointer hover:tw-bg-gray-500 ${index === data?.possibleConnectionTypes.length - 1 ? "tw-rounded-b-md" : ""}`}
                              onClick={async () => {
                                await setFieldValue(
                                  "clickedConnectionTypeData",
                                  connectionTypeData
                                );
                                submitForm();
                              }}
                            >
                              <Icon
                                name={
                                  topologyResourceConnectionTypes[
                                    connectionTypeData.type
                                  ].icon
                                }
                              />
                              <div className="tw-flex tw-justify-between tw-items-center tw-gap-4 tw-w-full">
                                <span className="tw-text-white tw-text-xssm tw-font-normal">
                                  {
                                    topologyResourceConnectionTypes[
                                      connectionTypeData.type
                                    ].label
                                  }
                                </span>
                                <span>
                                  <Checkbox
                                    name="connectionType"
                                    id={`checkbox_${connectionTypeData.type}`}
                                    dataTestId={`checkbox_${connectionTypeData.type}`}
                                    value={connectionTypeData.type}
                                    className="!tw-border-gray-400"
                                  />
                                </span>
                              </div>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      }
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </EdgeLabelRenderer>
      )}

      {data?.connectionTypes?.length > 0 && (
        <EdgeLabelRenderer>
          <div
            style={{
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              pointerEvents: "all",
            }}
            className={`nodrag nopan tw-absolute tw-flex tw-gap-7 tw-items-center tw-justify-center ${(sourcePos === "top" && targetPos === "bottom") ||
              (sourcePos === "bottom" && targetPos === "top")
              ? "tw-flex-col"
              : "tw-flex-row"
              }`}
          >
            {data.connectionTypes.map(
              (connectionTypeData: any, index: number) => {
                if (
                  topologyResourceConnectionTypes[
                  connectionTypeData?.data?.connectionType
                  ]
                ) {
                  return (
                    <span
                      key={index}
                      className={`tw-rounded-full tw-bg-gray-500 tw-p-1.5 tw-cursor-pointer ${connectionTypeData.selected_resource_id &&
                        connectionTypeData.selected_resource_id ===
                        connectionTypeData.id
                        ? "tw-border-1.5 tw-border-blue-500"
                        : ""
                        }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (connectionTypeData?.data?.resourceType) {
                          connectionTypeData?.onConnectionTypeClick?.(
                            connectionTypeData
                          );
                        }
                      }}
                    >
                      <Icon
                        name={
                          topologyResourceConnectionTypes[
                            connectionTypeData?.data?.connectionType
                          ].icon
                        }
                        size={IconSize.sm}
                      />
                    </span>
                  );
                } else {
                  return null;
                }
              }
            )}
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
};

export default memo(CustomEdge);
