/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ProvidersResponse,
  SaveSCMTokenRequest,
  ScmCallback200Response,
} from '../models/index';
import {
    ProvidersResponseFromJSON,
    ProvidersResponseToJSON,
    SaveSCMTokenRequestFromJSON,
    SaveSCMTokenRequestToJSON,
    ScmCallback200ResponseFromJSON,
    ScmCallback200ResponseToJSON,
} from '../models/index';

export interface ConnectToScmRequest {
    provider: string;
}

export interface DisconnectFromScmRequest {
    provider: string;
}

export interface SaveSCMTokenOperationRequest {
    provider: string;
    saveSCMTokenRequest: SaveSCMTokenRequest;
}

export interface ScmCallbackRequest {
    provider: string;
}

/**
 * 
 */
export class SCMConnectorApi extends runtime.BaseAPI {

    /**
     * Connect to a SCM provider
     */
    async connectToScmRaw(requestParameters: ConnectToScmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider','Required parameter requestParameters.provider was null or undefined when calling connectToScm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/scm/{provider}/login`.replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Connect to a SCM provider
     */
    async connectToScm(requestParameters: ConnectToScmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.connectToScmRaw(requestParameters, initOverrides);
    }

    /**
     * Disconnects from a SCM provider
     */
    async disconnectFromScmRaw(requestParameters: DisconnectFromScmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider','Required parameter requestParameters.provider was null or undefined when calling disconnectFromScm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/scm/{provider}/disconnect`.replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Disconnects from a SCM provider
     */
    async disconnectFromScm(requestParameters: DisconnectFromScmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.disconnectFromScmRaw(requestParameters, initOverrides);
    }

    /**
     * Lists the currently configured providers
     */
    async getScmProvidersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProvidersResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/scm/providers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProvidersResponseFromJSON));
    }

    /**
     * Lists the currently configured providers
     */
    async getScmProviders(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProvidersResponse>> {
        const response = await this.getScmProvidersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update token for a SCM provider
     */
    async saveSCMTokenRaw(requestParameters: SaveSCMTokenOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider','Required parameter requestParameters.provider was null or undefined when calling saveSCMToken.');
        }

        if (requestParameters.saveSCMTokenRequest === null || requestParameters.saveSCMTokenRequest === undefined) {
            throw new runtime.RequiredError('saveSCMTokenRequest','Required parameter requestParameters.saveSCMTokenRequest was null or undefined when calling saveSCMToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/scm/{provider}/token`.replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SaveSCMTokenRequestToJSON(requestParameters.saveSCMTokenRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update token for a SCM provider
     */
    async saveSCMToken(requestParameters: SaveSCMTokenOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveSCMTokenRaw(requestParameters, initOverrides);
    }

    /**
     * Connect to a SCM provider
     */
    async scmCallbackRaw(requestParameters: ScmCallbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScmCallback200Response>> {
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider','Required parameter requestParameters.provider was null or undefined when calling scmCallback.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/scm/{provider}/callback`.replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScmCallback200ResponseFromJSON(jsonValue));
    }

    /**
     * Connect to a SCM provider
     */
    async scmCallback(requestParameters: ScmCallbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScmCallback200Response> {
        const response = await this.scmCallbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
