import { AppStack, Branch, Component } from "../apis/appcd";
import {
  AppStackWithVersions,
  AppstackReposGroupMeta,
} from "../components/appstacks/appstack-list-types";

// list of language that is supported by appCD analyzer today
const supportedAnalysisLanguages = ["Java", "Python"];

// this will return appStacks list with "versions" list, where versions will be mapped for parent-child relation
export const appStackVersionMapping = (
  appStackList: AppStack[],
  isVersionsDescending?: boolean
) => {
  return appStackList.reduce(
    (result: AppStackWithVersions[], appStack: AppStack) => {
      const existingStack = result.find(
        (stack) => stack.name === appStack.name
      );
      if (existingStack) {
        existingStack?.versions?.push(appStack);
        existingStack?.versions?.sort((a, b) => {
          const dateA = new Date(a.createdDate as Date);
          const dateB = new Date(b.createdDate as Date);
          return isVersionsDescending
            ? dateB.getTime() - dateA.getTime()
            : dateA.getTime() - dateB.getTime();
        });
      } else {
        result.push({ ...appStack, versions: [appStack] });
      }
      return result;
    },
    []
  );
};

/**
 * Returns a record of the count of repositories grouped by provider for a given appstack
 *
 * @param {Component[]} appStackComponents - an array of components
 * @return {Record<string, number>} a record of the count of repositories grouped by provider
 */
export const getAppStackGroupedByProviderRepoCount = (
  appStackComponents: Component[]
) => {
  const uniqueRepoSet = new Set<string>();
  return appStackComponents?.reduce((acc, component) => {
    const repoId = component?.source?.repository?.id;
    // uniqueRepoSet check is added to ignore repeated repositories used within multiple components
    if (repoId && !uniqueRepoSet.has(repoId as string)) {
      uniqueRepoSet.add(repoId as string);
      const provider = component?.source?.repository?.provider;
      if (provider) {
        acc[provider] = {
          count: (acc[provider] ? acc[provider].count : 0) + 1,
          hasAllReposOnboarded: appStackComponents.every((component) => {
            return component?.source?.repository?.provider === provider
              ? !component?.source?.offboarded
              : true;
          }),
        };
      }
    }
    return acc;
  }, {} as Record<string, AppstackReposGroupMeta>);
};

/**
 * Returns the default language from the given languageMap, based on the supported languages.
 *
 * @param {Record<string, number>} languageMap - A map of languages to their corresponding values.
 * @return {string | undefined} The default language, or undefined if no supported language is found.
 */
export const getLanguageDefaultValue = (
  languageMap: Record<string, number>
) => {
  let language: string | undefined;

  // Check if the languageMap contains any of the supported languages
  supportedAnalysisLanguages.forEach((lang) => {
    if (languageMap?.[lang]) {
      // If language is not set or the current language has a higher value, set it as the language
      if (!language || languageMap[lang] > languageMap[language]) {
        language = lang;
      }
    }
  });

  return language;
};

/**
 * Returns an list of branch options for a given repository and component index - for populating a dropdown of branches.
 *
 * @param {string} activeRepositoryId - The ID of the active repository.
 * @param {number} index - The index of the component.
 * @param {Record<string, Branch[]>} branchesList - A record of repositories and their corresponding branches.
 * @param {any} formValues - The form values.
 * @return {Array<{value: string, label: string}>} An array of branch options.
 */
export const getRepoBranchOptionsForAppStack = (
  activeRepositoryId: string,
  index: number,
  branchesList: Record<string, Branch[]>,
  formValues: any
) => {
  if (branchesList?.[activeRepositoryId]?.length > 0) {
    return branchesList[activeRepositoryId]?.map((branch: any) => ({
      value: branch.name,
      label: branch.name,
    }));
  }

  // make default form values as selected branch name(this will be used for version creation flow as we are not making api call to fetch branches)
  const gitReference =
    formValues?.repositories?.[activeRepositoryId]?.components?.[index]?.source
      ?.gitReference;

  return [
    {
      value: gitReference ?? "DEFAULT_HEAD",
      label: gitReference ?? "HEAD", // setting default branch as head in case if branch info not available
    },
  ];
};
