import React, { type FC, Fragment, useState, useEffect } from "react";
import { useSetAtom } from "jotai";

import {
  AWSIcon,
  AWSIconsList,
  Button,
  Drawer,
  Icon,
  IconSize,
  IconsList,
  Tag,
} from "../../../components";
import { TopologyResourceWithPosition } from "../../../apis/topology";
import { Form, Formik } from "formik";
import ResourcePropertiesFormElements from "../resource-properties-form-elements";
import { updateTopologyResourcesPropertiesAtom } from "../../../stores/topology.store";
import {
  attributePropertiesToFormikValues,
  formikValuesToAttributeProperties,
  traverseEachResource,
} from "../../../utils";
import { updateNotificationsAtom } from "../../../stores/page.store";
import { TagVariant } from "../../tag/tag.types";
import type { FixMissingAttributesDrawerProps } from "./fix-missing-attributes-types";

const FixMissingAttributesDrawer: FC<FixMissingAttributesDrawerProps> = ({
  open,
  topologyId,
  resources = [],
  resourcesWithErrors = {},
  resourcePropertiesTemplates,
  openTFVarsDialog,
  onClose,
  onSubmit,
}) => {
  const [collapsedResourceList, setCollapsedResourceList] = useState<string[]>(
    []
  );
  const [resourcesToFix, setResourcesToFix] = useState<
    TopologyResourceWithPosition[]
  >([]);
  const [formInitialValue, setFormInitialValue] = useState(
    {} as Record<string, any>
  );

  const updateTopologyResourcesProperties = useSetAtom(
    updateTopologyResourcesPropertiesAtom
  );
  const updateNotifications = useSetAtom(updateNotificationsAtom);

  useEffect(() => {
    const runEffect = async () => {
      const resourceMap = new Map();
      const resourcesToFixList: TopologyResourceWithPosition[] = [];

      await traverseEachResource(resources, async (resource) => {
        resourceMap.set(resource.id, resource);
        if ((resourcesWithErrors?.[resource.id]?.validationErrors ?? 0) > 0) {
          resourcesToFixList.push(resource);
        }
      });

      setResourcesToFix(resourcesToFixList);
    };
    if (open) {
      runEffect();
    } else {
      setResourcesToFix([]);
    }
  }, [open, resourcesWithErrors, resources]);

  const handleFixMissingAttributesSubmit = async (
    submittedValues: Record<string, any>,
    setSubmitting: (newValue: boolean) => void
  ) => {
    setSubmitting(true);
    const resourceMapToUpdate: Record<string, any> = {};

    // Create a map of resourcesToFix by their id
    const resourcesToFixMap = new Map(
      resourcesToFix.map((resource) => [resource.id, resource])
    );

    for (const [key, resourceProperties] of Object.entries(submittedValues)) {
      const targetResourceToFix = resourcesToFixMap.get(key);
      if (!targetResourceToFix) continue;
      targetResourceToFix["_configuration"] =
        { ...targetResourceToFix._configuration, ...resourceProperties } || {};
      const attributeTemplate =
        resourcePropertiesTemplates[targetResourceToFix.resourceType];

      const currentResource = resourceMapToUpdate[key] || {
        resourceId: targetResourceToFix.id,
        _configuration: formikValuesToAttributeProperties(
          targetResourceToFix._configuration,
          attributeTemplate?.attributes
        ),
      };

      resourceMapToUpdate[key] = currentResource;
    }
    const resourceListToUpdate = Object.values(resourceMapToUpdate);
    const isSuccess = await updateTopologyResourcesProperties({
      topologyId,
      updateResourceRequest: resourceListToUpdate,
    });
    if (isSuccess) {
      updateNotifications({
        type: "success",
        title: "Missing attributes added",
        description: "Missing attributes have been added successfully.",
        dismissable: true,
      });
    }

    setSubmitting(false);
    onSubmit();
  };

  useEffect(() => {
    const initialValue = resourcesToFix.reduce((acc, resource) => {
      const resourceId = resource?.id as string;
      const resourceType = resource?.resourceType as string;
      const attributeTemplate = resourcePropertiesTemplates[resourceType];
      acc[resourceId] = attributePropertiesToFormikValues(
        attributeTemplate?.attributes,
        resource?._configuration
      );
      return acc;
    }, {} as Record<string, any>);

    setFormInitialValue(initialValue);
  }, [resourcesToFix]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      size="sm"
      isFullScreenDrawer={false}
      isResizable={true}
      minResizableWidth={300}
      maxResizableWidth={600}
      dataTestId="fix-missing-attributes-drawer"
    >
      <Formik
        initialValues={formInitialValue}
        onSubmit={(submittedValues: Record<string, any>, { setSubmitting }) => {
          handleFixMissingAttributesSubmit(submittedValues, setSubmitting);
        }}
        enableReinitialize
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <div className="tw-h-full tw-relative">
              <div className="tw-py-3 tw-px-4 tw-flex tw-justify-between tw-items-center">
                <div className="tw-flex tw-flex-col">
                  <p className="tw-text-base tw-font-semibold tw-text-white">
                    Fix Missing Attributes
                  </p>
                  <p className="tw-text-xssm tw-text-gray-200">
                    Below attributes are required to generate IaC.
                  </p>
                </div>
                <div
                  className="tw-cursor-pointer tw-text-gray-300 hover:tw-text-gray-200"
                  onClick={() => onClose()}
                  data-testid="custom-resource-drawer-close"
                >
                  <Icon name={IconsList.CLOSE} />
                </div>
              </div>
              <div className="tw-overflow-auto tw-pb-20">
                {resourcesToFix.map(
                  (resource: TopologyResourceWithPosition | undefined) => {
                    if (!resource) return null;
                    const attributeTemplate =
                      resourcePropertiesTemplates[resource.resourceType];
                    const resourceErrors =
                      resourcesWithErrors[resource.id as string];

                    if (!attributeTemplate || !resourceErrors) return null;

                    const keysWithErrors = resourceErrors.errorFields?.map(
                      (errorField) =>
                        errorField?.key?.match(/^[^\[]*/)?.[0] ??
                        errorField?.key
                    );
                    const attributesWithErrors =
                      attributeTemplate.attributes.filter((attribute) =>
                        keysWithErrors?.includes(attribute.key)
                      );

                    return (
                      <Fragment key={resource?.id}>
                        <div
                          className={`tw-flex tw-items-center tw-px-4 tw-py-3 tw-w-full tw-justify-between tw-bg-gray-500 tw-bg-opacity-20 tw-border-b tw-border-gray-700 tw-cursor-pointer`}
                          onClick={() => {
                            collapsedResourceList.includes(
                              resource?.id as string
                            )
                              ? setCollapsedResourceList(
                                  collapsedResourceList.filter(
                                    (id) => id !== resource?.id
                                  )
                                )
                              : setCollapsedResourceList([
                                  ...collapsedResourceList,
                                  resource?.id as string,
                                ]);
                          }}
                          data-testid={`resource-${resource?.id}`}
                        >
                          <div className="tw-flex tw-gap-2 tw-items-center">
                            <AWSIcon
                              name={
                                AWSIconsList[
                                  resource?.resourceType as keyof typeof AWSIconsList
                                ]
                              }
                              alt={
                                resource?.label || resource?.resourceTypeLabel
                              }
                              size={IconSize["xl"]}
                              fallbackPlaceholder={resource?.resourceType}
                              className="tw-rounded"
                            />
                            <div className="tw-flex tw-flex-col tw-gap-0.5">
                              <div className="tw-text-white tw-text-xssm tw-font-semibold">
                                {resource?.label || resource?.resourceTypeLabel}
                              </div>
                              <div className="tw-text-gray-300 tw-text-xs tw-font-normal">
                                {resource?.resourceTypeLabel}
                              </div>
                            </div>
                          </div>
                          <div className="tw-flex tw-gap-2">
                            <Tag
                              title={`${
                                attributesWithErrors?.length
                              } attribute${
                                attributesWithErrors?.length > 1 ? "s" : ""
                              }`}
                              className="tw-ml-2"
                              dataTestId={`tf-vars-count-${resource.id}`}
                              variant={TagVariant.SOLID}
                              size="sm"
                            />
                            <Icon
                              className="tw-text-gray-50"
                              name={
                                collapsedResourceList.includes(
                                  resource?.id as string
                                )
                                  ? IconsList.CARET_DOWN
                                  : IconsList.CARET_UP
                              }
                            />
                          </div>
                        </div>
                        {!collapsedResourceList.includes(
                          resource?.id as string
                        ) && (
                          <div className="tw-p-4 tw-flex tw-flex-col tw-gap-4">
                            {attributesWithErrors?.map((attribute) => {
                              return (
                                <Fragment
                                  key={`${resource?.id}_${attribute.key}`}
                                >
                                  <ResourcePropertiesFormElements
                                    resource_attribute_obj={{
                                      ...attribute,
                                      key: `${resource?.id}[${attribute.key}]`,
                                    }}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    showTFVarsDialog={() => {}}
                                    hideTFVar={true}
                                    setFieldValue={setFieldValue}
                                  />
                                </Fragment>
                              );
                            })}
                          </div>
                        )}
                      </Fragment>
                    );
                  }
                )}
              </div>
              <div className="tw-p-4 tw-flex tw-items-center tw-gap-2 tw-fixed tw-bottom-0 tw-w-full tw-border-t tw-border-gray-600 tw-bg-gray-800">
                <Button
                  label="Save Changes"
                  dataTestId="fix-attributes-save-changes-button"
                  variant="secondary"
                  className="tw-w-flex tw-flex-1"
                  type="submit"
                />
                <p className="tw-text-gray-300 tw-text-xssm tw-font-semibold">
                  OR
                </p>
                <Button
                  label="Convert to TF Vars"
                  variant="primary"
                  dataTestId="convert-to-tf-vars-button"
                  className="tw-w-flex tw-flex-1"
                  onClick={openTFVarsDialog}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default FixMissingAttributesDrawer;
