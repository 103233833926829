/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ManagedBy } from './ManagedBy';
import {
    ManagedByFromJSON,
    ManagedByFromJSONTyped,
    ManagedByToJSON,
} from './ManagedBy';
import type { TemplateType } from './TemplateType';
import {
    TemplateTypeFromJSON,
    TemplateTypeFromJSONTyped,
    TemplateTypeToJSON,
} from './TemplateType';
import type { Variable } from './Variable';
import {
    VariableFromJSON,
    VariableFromJSONTyped,
    VariableToJSON,
} from './Variable';

/**
 * 
 * @export
 * @interface CustomResourceTemplate
 */
export interface CustomResourceTemplate {
    /**
     * 
     * @type {string}
     * @memberof CustomResourceTemplate
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomResourceTemplate
     */
    resourceType?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomResourceTemplate
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomResourceTemplate
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomResourceTemplate
     */
    provider?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomResourceTemplate
     */
    template?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomResourceTemplate
     */
    deleted?: boolean;
    /**
     * 
     * @type {TemplateType}
     * @memberof CustomResourceTemplate
     */
    templateType?: TemplateType;
    /**
     * 
     * @type {boolean}
     * @memberof CustomResourceTemplate
     */
    allowDnd?: boolean;
    /**
     * 
     * @type {ManagedBy}
     * @memberof CustomResourceTemplate
     */
    managedBy?: ManagedBy;
    /**
     * 
     * @type {string}
     * @memberof CustomResourceTemplate
     */
    iacType?: string;
    /**
     * 
     * @type {Array<Variable>}
     * @memberof CustomResourceTemplate
     */
    variables?: Array<Variable>;
    /**
     * 
     * @type {string}
     * @memberof CustomResourceTemplate
     */
    iamPolicy?: string;
}

/**
 * Check if a given object implements the CustomResourceTemplate interface.
 */
export function instanceOfCustomResourceTemplate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomResourceTemplateFromJSON(json: any): CustomResourceTemplate {
    return CustomResourceTemplateFromJSONTyped(json, false);
}

export function CustomResourceTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomResourceTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'resourceType': !exists(json, 'resourceType') ? undefined : json['resourceType'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'provider': !exists(json, 'provider') ? undefined : json['provider'],
        'template': !exists(json, 'template') ? undefined : json['template'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'templateType': !exists(json, 'templateType') ? undefined : TemplateTypeFromJSON(json['templateType']),
        'allowDnd': !exists(json, 'allowDnd') ? undefined : json['allowDnd'],
        'managedBy': !exists(json, 'managedBy') ? undefined : ManagedByFromJSON(json['managedBy']),
        'iacType': !exists(json, 'iacType') ? undefined : json['iacType'],
        'variables': !exists(json, 'variables') ? undefined : ((json['variables'] as Array<any>).map(VariableFromJSON)),
        'iamPolicy': !exists(json, 'iamPolicy') ? undefined : json['iamPolicy'],
    };
}

export function CustomResourceTemplateToJSON(value?: CustomResourceTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'resourceType': value.resourceType,
        'description': value.description,
        'label': value.label,
        'provider': value.provider,
        'template': value.template,
        'deleted': value.deleted,
        'templateType': TemplateTypeToJSON(value.templateType),
        'allowDnd': value.allowDnd,
        'managedBy': ManagedByToJSON(value.managedBy),
        'iacType': value.iacType,
        'variables': value.variables === undefined ? undefined : ((value.variables as Array<any>).map(VariableToJSON)),
        'iamPolicy': value.iamPolicy,
    };
}

