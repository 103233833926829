import React, { useState, useEffect } from "react";
import { Link as InternalLink } from "gatsby";

import { Link } from "../../components";

import type { PropertiesType } from "./topology-graph-types";
import { ResourcePropertiesFormElements } from "./";

// TODO: We need to make this component more generic and reusable
const LinkElement = ({
  label,
  action,
  template_attributes,
  values,
  errors,
  touched,
  setFieldValue,
  disabled,
}: any) => {
  const [showClickActionAttribute, setShowClickActionAttribute] =
    useState<boolean>(false);

  useEffect(() => {
    if (action?.value) {
      setFieldValue(action.key, action.value);
      setShowClickActionAttribute(false);
    }
  }, [action?.value]);

  useEffect(() => {
    setShowClickActionAttribute(false);
  }, [setShowClickActionAttribute]);

  if (
    action?.type === "json" ||
    action?.type === "text" ||
    action?.type === "select"
  ) {
    if (showClickActionAttribute) {
      let dependent_resource_attribute_obj = template_attributes?.find(
        (attribute: PropertiesType) => attribute.key === action.key
      );

      dependent_resource_attribute_obj = {
        ...dependent_resource_attribute_obj,
        label: action.label
          ? action.label
          : dependent_resource_attribute_obj.label,
      };

      // Check if dependent_resource_attribute_obj is not empty after overrideAttributes
      if (!dependent_resource_attribute_obj) {
        return null;
      }

      return (
        <ResourcePropertiesFormElements
          template_attributes={template_attributes}
          resource_attribute_obj={{
            ...dependent_resource_attribute_obj,
            disabled: disabled,
          }}
          values={values}
          errors={errors}
          touched={touched}
          hideTFVar={true}
          setFieldValue={setFieldValue}
        />
      );
    } else {
      return (
        <div
          className="tw-text-xssm tw-font-medium tw-text-blue-500 hover:tw-underline tw-cursor-pointer"
          onClick={() => setShowClickActionAttribute(true)}
        >
          {label}
        </div>
      );
    }
  } else if (action?.type === "external_link") {
    return (
      <Link
        dataTestId={`external_link_${action?.key}`}
        href={action.url}
        text={label}
        target="_blank"
        className="tw-text-xssm tw-font-medium tw-cursor-pointer"
      />
    );
  } else if (action?.type === "internal_link") {
    return (
      <InternalLink
        to={action?.url}
        data-testid={`internal_link-${action?.key}`}
        className="tw-text-xssm tw-font-medium tw-text-blue-500 hover:tw-underline tw-cursor-pointer"
      >
        {label}
      </InternalLink>
    );
  }
};

export default LinkElement;
