import { PrimitiveAtom, atom } from "jotai";

import { updateNotificationsAtom } from "./page.store";
import { setRequestIdFromResponseAtom } from "./logger.store";

import type {
  Topology,
  TopologyResourceValidations,
  TopologyResourceWithPosition,
  TopologyResourceWithMisconfigurations,
  ProviderWiseSupportedResourceType,
  UpdateResourceRequest,
  UpdateResourceConnectionRequest,
  NewTopologyResourceConnectionRequest,
  TopologyResourceConnection,
  ResourcePosition,
  SupportedTfDataTypes,
  SaveCustomResourceTemplateForResourceTypeRequest,
  ResourceIdWithPosition,
  GetResourceTemplateForResourceTypeRequest,
  DeleteCustomResourceTemplateRequest,
  GetCustomTemplateByResourceIdRequest,
  UpdateCustomTemplateRequest,
  CustomResourceTemplate,
  UpdateResourcesRequest,
  UpdateGlobalResourceTagsRequest,
} from "../apis/topology";

import {
  ErrorHandler,
  v1TopologyApiObj,
  v1ResourceApiObj,
  v1ResourceConnectionApiObj,
  isFunction,
  formikValuesValidationSchema,
  attributePropertiesToFormikValues,
  trackAmplitudeEvent,
  v1ImportApiObject,
  v1CustomResourceTemplateApiObj,
  updateResourceDataInTopology,
  findResourceInTopology,
  traverseEachResource,
  traverseAndUpdateEachResource,
  addResourceInTopology,
} from "../utils";

import { unsupportedResourceTypes } from "../config/constants";

// This type should come from the API later on when validation is implemented on the backend
import type {
  TopologyErrorsType,
  TopologyPageMeta,
} from "../components/topology-graph/topology-graph-types";
import { ValidationError } from "yup";
import { IacType } from "../apis/appcd";

export const appStackTopologyGraphAtom = atom<Topology>({});

export const topologyPageMetaAtom = atom<TopologyPageMeta | null>(null);

export const fetchAppStackTopologyGraphAtom = atom(
  null,
  async (
    get,
    set,
    requestParameters: {
      appstackId: string;
    }
  ) => {
    try {
      const topology_graph: any =
        await v1TopologyApiObj.getTopologyRepresentationalData({
          appstackId: requestParameters.appstackId,
        });

      handleTopologyGraph(get, set, topology_graph);
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      await ErrorHandler(error);
      set(appStackTopologyGraphAtom, {});
      return null;
    }
  }
);

export const fetchAppStackTopologyByAppStackIdAtom = atom(
  null,
  async (
    get,
    set,
    requestParameters: {
      appstackId: string;
    }
  ) => {
    try {
      const topology_graph: any =
        await v1TopologyApiObj.v1GetTopologyFromAppstackId({
          appstackId: requestParameters.appstackId,
        });

      return topology_graph;
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      await ErrorHandler(error);
      set(appStackTopologyGraphAtom, {});
      return null;
    }
  }
);

export const topologyResourcesListAtom =
  atom<ProviderWiseSupportedResourceType>({});

export const fetchTopologyResourcesListAtom = atom(null, async (get, set) => {
  try {
    const resources_list = await v1ResourceApiObj.getSupportedResourceTypes();
    set(topologyResourcesListAtom, resources_list);
  } catch (error) {
    await set(setRequestIdFromResponseAtom, (error as any).response);
    set(topologyResourcesListAtom, {});
    await ErrorHandler(error);
  }
});

export const topologyResourcePropertiesTemplatesAtom = atom<{
  [resourceType: string]: TopologyResourceValidations;
}>({});

export const fetchTopologyResourcePropertiesTemplateAtom = atom(
  null,
  async (get, set, reqBody: GetResourceTemplateForResourceTypeRequest) => {
    try {
      const resource_properties_template =
        await v1ResourceApiObj.getResourceTemplateForResourceType(reqBody);
      set(topologyResourcePropertiesTemplatesAtom, (prev) => {
        return {
          ...prev,
          [reqBody.resourceType]: resource_properties_template,
        };
      });
      return resource_properties_template;
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      await ErrorHandler(error);
    }
  }
);

export const fetchResourcePropertiesValuesAtom = atom(
  null,
  async (get, set, topologyId: string, resourceId: string) => {
    try {
      const resource_properties_data = await v1ResourceApiObj.getResource({
        topologyId,
        resourceId,
      });

      return resource_properties_data;
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      await ErrorHandler(error);
    }
  }
);

export const fetchResourceConnectionPropertiesValuesAtom = atom(
  null,
  async (get, set, topologyId: string, resourceConnectionId: string) => {
    try {
      const resource_connection_properties_data =
        await v1ResourceConnectionApiObj.getResourceConnectionById({
          topologyId,
          resourceConnectionId,
        });

      return resource_connection_properties_data;
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      await ErrorHandler(error);
    }
  }
);

export const updateTopologyResourcePropertiesValuesAtom = atom(
  null,
  async (
    get,
    set,
    topologyId: string,
    resourceId: string,
    updateResourceRequest: UpdateResourceRequest
  ) => {
    try {
      const updated_resource_properties = await v1ResourceApiObj.updateResource(
        {
          topologyId,
          resourceId,
          updateResourceRequest,
        }
      );
      set(updateNotificationsAtom, {
        type: "success",
        title: "Resource configuration updated successfully.",
        dismissable: true,
      });

      set(appStackTopologyGraphAtom, (prev) => {
        const topology_graph = { ...prev };

        if (topology_graph.resources) {
          topology_graph.resources = updateResourceDataInTopology(
            topology_graph.resources,
            resourceId,
            { label: updated_resource_properties.label }
          );
        }

        return topology_graph;
      });

      return updated_resource_properties;
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      await ErrorHandler(error);
      set(updateNotificationsAtom, {
        type: "error",
        title: "An error occurred while updating the resource configuration.",
        dismissable: true,
      });
    }
  }
);

export const updateTopologyResourcesPropertiesAtom = atom(
  null,
  async (get, set, updateResourceRequest: UpdateResourcesRequest) => {
    try {
      await v1ResourceApiObj.updateResources(updateResourceRequest);
      return true;
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      await ErrorHandler(error);
      set(updateNotificationsAtom, {
        type: "error",
        title: "Some error occurred while updating resources.",
        dismissable: true,
      });
      return false;
    }
  }
);

export const updateResourceConnectionPropertiesValuesAtom = atom(
  null,
  async (
    get,
    set,
    topologyId: string,
    resourceConnectionId: string,
    updateResourceConnectionRequest: UpdateResourceConnectionRequest
  ) => {
    try {
      const updated_resource_connection_properties =
        await v1ResourceConnectionApiObj.updateResourceConnection({
          topologyId,
          resourceConnectionId,
          updateResourceConnectionRequest,
        });
      set(updateNotificationsAtom, {
        type: "success",
        title: "Resource connection configuration updated successfully.",
        dismissable: true,
      });

      return updated_resource_connection_properties;
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      await ErrorHandler(error);
      set(updateNotificationsAtom, {
        type: "error",
        title:
          "An error occurred while updating the resource connection configuration.",
        dismissable: true,
      });
    }
  }
);

export const createTopologyResourceAtom = atom(
  null,
  async (
    get,
    set,
    topologyId: string,
    resource: TopologyResourceWithPosition,
    resourceCreatedCallback?: (
      resource: TopologyResourceWithMisconfigurations
    ) => void
  ) => {
    try {
      const created_resource = await v1ResourceApiObj.createResource({
        topologyId,
        topologyResourceWithPosition: resource,
      });
      set(appStackTopologyGraphAtom, (prev) => {
        const topology_graph = { ...prev };
        if (created_resource.group) {
          topology_graph.resources = addResourceInTopology(
            topology_graph.resources,
            created_resource
          );
        } else {
          topology_graph.resources?.push(created_resource);
        }

        return topology_graph;
      });

      if (resourceCreatedCallback && isFunction(resourceCreatedCallback)) {
        resourceCreatedCallback(created_resource);
      }
      set(updateNotificationsAtom, {
        type: "success",
        title: "Topology updated successfully.",
        dismissable: true,
      });
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      const errorResponse = await ErrorHandler(error);
      set(updateNotificationsAtom, {
        type: "error",
        title: `${errorResponse.msg}`,
        description: `${errorResponse?.extras?.join("\n")}`,
        dismissable: true,
      });
    }
  }
);

export const createTopologyResourceConnectionAtom = atom(
  null,
  async (
    get,
    set,
    topologyId: string,
    resourceConnection: NewTopologyResourceConnectionRequest,
    resourceConnectionCreatedCallback?: (
      resourceConnection: TopologyResourceConnection
    ) => void
  ) => {
    try {
      const created_resource_connection =
        await v1ResourceConnectionApiObj.createResourceConnection({
          topologyId,
          newTopologyResourceConnectionRequest: resourceConnection,
        });
      set(appStackTopologyGraphAtom, (prev) => {
        const topology_graph = { ...prev };
        topology_graph.resourceConnections?.push(created_resource_connection);
        return topology_graph;
      });

      set(validateTopologyAtom, topologyId, [
        {
          id: resourceConnection.sourceResourceId,
          resourceType: "", // Resource type is not required for this validation
        },
        {
          id: resourceConnection.targetResourceId,
          resourceType: "", // Resource type is not required for this validation
        },
      ]);

      if (
        resourceConnectionCreatedCallback &&
        isFunction(resourceConnectionCreatedCallback)
      ) {
        resourceConnectionCreatedCallback(created_resource_connection);
      }
      set(updateNotificationsAtom, {
        type: "success",
        title: "Connection Successful !",
        description: "Resource connection created successfully.",
        dismissable: true,
      });
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      const errorResponse = await ErrorHandler(error);
      set(updateNotificationsAtom, {
        type: "error",
        title: `${errorResponse.msg}`,
        description: `${errorResponse?.extras?.join("\n")}`,
        dismissable: true,
      });
      if (
        resourceConnectionCreatedCallback &&
        isFunction(resourceConnectionCreatedCallback)
      ) {
        resourceConnectionCreatedCallback({});
      }
    }
  }
);

export const getResourceConnectionTypesAtom = atom(
  null,
  async (get, set, sourceResourceType: string, targetResourceType: string) => {
    try {
      const resource_connection_types =
        await v1ResourceConnectionApiObj.getSupportedConnectionTypes({
          sourceResourceType,
          targetResourceType,
        });
      if (resource_connection_types?.length === 0) {
        set(updateNotificationsAtom, {
          type: "error",
          title: "Invalid Connection",
          dismissable: true,
        });
      }
      return resource_connection_types;
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      const errorResponse = await ErrorHandler(error);
      set(updateNotificationsAtom, {
        type: "error",
        title: `${errorResponse?.msg || "Invalid Connection"}`,
        description: `${errorResponse?.extras?.join("\n")}`,
        dismissable: true,
      });
    }
  }
);

export const deleteTopologyResourceAtom = atom(
  null,
  async (
    get,
    set,
    appstackId: string,
    topologyId: string,
    resourceId: string
  ) => {
    try {
      await v1ResourceApiObj.deleteResource({
        topologyId,
        resourceId,
      });

      await set(fetchAppStackTopologyGraphAtom, { appstackId });

      set(updateNotificationsAtom, {
        type: "success",
        title: "Resource deleted successfully.",
        dismissable: true,
      });
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      await ErrorHandler(error);
      set(updateNotificationsAtom, {
        type: "error",
        title: "Error: An error occurred while deleting the resource.",
        dismissable: true,
      });
    }
  }
);

export const deleteTopologyResourceConnectionAtom = atom(
  null,
  async (
    get,
    set,
    topologyId: string,
    resourceConnection: TopologyResourceConnection
  ) => {
    try {
      await v1ResourceConnectionApiObj.deleteResourceConnection({
        topologyId,
        resourceConnectionId: resourceConnection.id as string,
      });
      set(appStackTopologyGraphAtom, (prev) => {
        const topology_graph = { ...prev };

        if (topology_graph.resourceConnections) {
          topology_graph.resourceConnections =
            topology_graph.resourceConnections.filter(
              (topologyResourceConnection) =>
                topologyResourceConnection.id !== resourceConnection.id
            );
        }

        return topology_graph;
      });
      set(validateTopologyAtom, topologyId, [
        {
          id: resourceConnection.sourceResourceId,
          resourceType: "", // Resource type is not required for this validation
        },
        {
          id: resourceConnection.targetResourceId,
          resourceType: "", // Resource type is not required for this validation
        },
      ]);
      set(updateNotificationsAtom, {
        type: "success",
        title: "Resource connection deleted successfully.",
        dismissable: true,
      });
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      await ErrorHandler(error);
      set(updateNotificationsAtom, {
        type: "error",
        title:
          "Error: An error occurred while deleting the resource connection.",
        dismissable: true,
      });
    }
  }
);

export const updateResourcePositionAtom = atom(
  null,
  async (
    get,
    set,
    topologyId: string,
    resourceId: string,
    resourcePosition: ResourcePosition
  ) => {
    try {
      await v1ResourceApiObj.updateResourcePosition({
        topologyId,
        resourceId,
        resourcePosition,
      });
      set(appStackTopologyGraphAtom, (prev) => {
        const topology_graph = { ...prev };

        if (topology_graph.resources) {
          topology_graph.resources = updateResourceDataInTopology(
            topology_graph.resources,
            resourceId,
            resourcePosition
          );
        }

        return topology_graph;
      });
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      await ErrorHandler(error);
    }
  }
);

export const updateBulkResourcePositionAtom = atom(
  null,
  async (
    get,
    set,
    topologyId: string,
    resourceIdWithPosition: ResourceIdWithPosition[]
  ) => {
    try {
      await v1ResourceApiObj.updateResourcePositions({
        topologyId,
        resourceIdWithPosition,
      });
      set(appStackTopologyGraphAtom, (prev) => {
        const topology_graph = { ...prev };

        if (topology_graph.resources) {
          resourceIdWithPosition.map((resourceWithPosition) => {
            if (resourceWithPosition.resourceId) {
              topology_graph.resources = updateResourceDataInTopology(
                topology_graph.resources,
                resourceWithPosition.resourceId,
                { position: resourceWithPosition.position }
              );
            }
          });
        }
        return topology_graph;
      });
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      await ErrorHandler(error);
    }
  }
);

export const topologyErrorsAtom = atom<TopologyErrorsType>({
  topologyId: "",
  totalErrors: 0,
  totalValidationErrors: 0,
  resourcesWithErrors: {},
  isValidated: false,
});

const validateTopologyResourceAtom = atom(
  null,
  async (get, set, resource_properties: any) => {
    let resourceErrors: {
      policyViolationErrors: number;
      validationErrors: number;
      errorFields: { key: string | undefined }[];
    } = {
      policyViolationErrors: 0,
      validationErrors: 0,
      errorFields: [],
    };

    // Exclude unsupported resources from validation
    if (unsupportedResourceTypes.includes(resource_properties?.resourceType)) {
      return resourceErrors;
    }

    // Check if resource properties have misconfigurations or policy violations
    if (
      resource_properties?.misconfigurations &&
      resource_properties.misconfigurations?.length > 0
    ) {
      resourceErrors.policyViolationErrors =
        resource_properties.misconfigurations.length;
    } else {
      resourceErrors.policyViolationErrors = 0;
    }

    if (resource_properties?._configuration) {
      // We are fetching resource properties template everytime and not using from cache, because now templates are also dynamic
      let resource_properties_template = await set(
        fetchTopologyResourcePropertiesTemplateAtom,
        {
          resourceType: resource_properties?.resourceType,
          templateId: resource_properties?.templateId,
        }
      );

      if (resource_properties_template) {
        // check if resource properties have validation errors
        try {
          // Exclude cross-reference values from validation as they are disabled fields
          if (
            resource_properties?.references &&
            resource_properties.references?.length > 0
          ) {
            resource_properties_template["attributes"] =
              resource_properties_template?.attributes?.map(
                (resource_property) => {
                  const updated_resource_property =
                    resource_properties.references?.find(
                      (reference_resource_property: any) =>
                        reference_resource_property.key ===
                        resource_property.key
                    );
                  if (updated_resource_property) {
                    return updated_resource_property;
                  } else {
                    return resource_property;
                  }
                }
              );
          }
          await formikValuesValidationSchema(
            // Filter out attributes converted to tfvars from validation
            resource_properties_template.attributes.filter(
              (attr) =>
                attr?.key &&
                (resource_properties as any)?.tfVars?.[attr.key] === undefined
            )
          )?.validate(
            attributePropertiesToFormikValues(
              resource_properties_template.attributes,
              resource_properties?._configuration
            ),
            { abortEarly: false }
          );

          resourceErrors.validationErrors = 0;
        } catch (err) {
          if (resource_properties?.iacType !== IacType.Helm) {
            if ((err as ValidationError)?.inner?.length > 0) {
              resourceErrors.errorFields = (err as ValidationError).inner.map(
                (errorField) => ({
                  key: errorField.path,
                })
              );
            }
            if (err) {
              resourceErrors.validationErrors = 1;
            }
          }
        }
      }
    }

    return resourceErrors;
  }
);

export const validateTopologyAtom = atom(
  null,
  async (get, set, topologyId: string, resourcesToVerify: any[]) => {
    if (topologyId && resourcesToVerify) {
      const topologyErrors = get(topologyErrorsAtom);

      const updatedTopologyErrors: TopologyErrorsType = {
        topologyId: "",
        totalErrors: 0,
        totalValidationErrors: 0,
        resourcesWithErrors: {},
        isValidated: false,
      };

      if (topologyErrors.topologyId === topologyId) {
        updatedTopologyErrors.topologyId = topologyErrors.topologyId;
        updatedTopologyErrors.totalErrors = topologyErrors.totalErrors; // We will need to re-calculate total errors at the end
        updatedTopologyErrors.totalValidationErrors =
          topologyErrors.totalValidationErrors;
        updatedTopologyErrors.resourcesWithErrors =
          topologyErrors.resourcesWithErrors;
      } else {
        updatedTopologyErrors.topologyId = topologyId;
      }

      // Special handling for deleted resource, or if resource properties already fetched for a single resource
      if (resourcesToVerify.length === 1 && resourcesToVerify[0].id) {
        const topology_graph = get(appStackTopologyGraphAtom);
        const resourceToVerify = findResourceInTopology(
          topology_graph?.resources,
          resourcesToVerify[0].id
        );

        if (resourceToVerify) {
          if (resourceToVerify?.resourceType !== "group") {
            const resource_properties = await set(
              fetchResourcePropertiesValuesAtom,
              topologyId,
              resourceToVerify.id
            );

            const resourceErrors = await set(
              validateTopologyResourceAtom,
              resource_properties
            );

            if (resourceErrors) {
              updatedTopologyErrors.resourcesWithErrors[resourceToVerify.id] =
                resourceErrors;
            }
          }

          if (
            (resourceToVerify?.isGroup ||
              resourceToVerify?.resourceType === "group") &&
            resourceToVerify?.children?.length > 0
          ) {
            await traverseEachResource(
              resourceToVerify?.children,
              async (nestedResource) => {
                if (nestedResource) {
                  const resource_properties = await set(
                    fetchResourcePropertiesValuesAtom,
                    topologyId,
                    nestedResource.id
                  );

                  const resourceErrors = await set(
                    validateTopologyResourceAtom,
                    resource_properties
                  );

                  if (resourceErrors) {
                    updatedTopologyErrors.resourcesWithErrors[
                      nestedResource.id
                    ] = resourceErrors;
                  }
                }
              }
            );
          }
        } else {
          if (
            updatedTopologyErrors.resourcesWithErrors[resourcesToVerify[0].id]
          ) {
            updatedTopologyErrors.resourcesWithErrors[resourcesToVerify[0].id] =
              {
                policyViolationErrors: 0,
                validationErrors: 0,
              };
          }

          if (
            resourcesToVerify[0].resourceType === "group" ||
            resourcesToVerify[0].isGroup
          ) {
            // If group resource is deleted, remove all child resources from errors list
            Object.keys(updatedTopologyErrors.resourcesWithErrors).forEach(
              (resourceId) => {
                if (
                  !findResourceInTopology(topology_graph.resources, resourceId)
                ) {
                  updatedTopologyErrors.resourcesWithErrors[resourceId] = {
                    policyViolationErrors: 0,
                    validationErrors: 0,
                  };
                }
              }
            );
          }
        }
      } else {
        for (let i = 0; i < resourcesToVerify.length; i++) {
          const resource_data = resourcesToVerify[i];

          if (resource_data.id) {
            if (resource_data.resourceType !== "group") {
              const resource_properties = await set(
                fetchResourcePropertiesValuesAtom,
                topologyId,
                resource_data.id
              );

              if (resource_properties) {
                const resourceErrors = await set(
                  validateTopologyResourceAtom,
                  resource_properties
                );

                if (resourceErrors) {
                  updatedTopologyErrors.resourcesWithErrors[resource_data.id] =
                    resourceErrors;
                }
              }
            }

            if (resource_data?.isGroup && resource_data?.children?.length > 0) {
              await traverseEachResource(
                resource_data?.children,
                async (nestedResource) => {
                  const resource_properties = await set(
                    fetchResourcePropertiesValuesAtom,
                    topologyId,
                    nestedResource.id
                  );

                  if (resource_properties) {
                    const resourceErrors = await set(
                      validateTopologyResourceAtom,
                      resource_properties
                    );

                    if (resourceErrors) {
                      updatedTopologyErrors.resourcesWithErrors[
                        nestedResource.id
                      ] = resourceErrors;
                    }
                  }
                }
              );
            }
          }
        }
      }

      // Calculate total errors after resources validation
      let totalErrors = 0;
      let totalValidationErrors = 0;
      Object.keys(updatedTopologyErrors.resourcesWithErrors).forEach(
        (resourceId) => {
          if (
            updatedTopologyErrors.resourcesWithErrors[resourceId]
              .policyViolationErrors
          ) {
            totalErrors += 1;
          }
          if (
            updatedTopologyErrors.resourcesWithErrors[resourceId]
              .validationErrors
          ) {
            totalErrors += 1;
            totalValidationErrors += 1;
          }
        }
      );
      updatedTopologyErrors.totalErrors = totalErrors;
      updatedTopologyErrors.totalValidationErrors = totalValidationErrors;

      // isValidated is used to prevent showing Export IaC button on first load
      updatedTopologyErrors.isValidated = true;

      if (updatedTopologyErrors.totalErrors === 0) {
        trackAmplitudeEvent("topology_validate");
      }

      set(topologyErrorsAtom, { ...updatedTopologyErrors });
    }
  }
);

export const exportIaCAtom = atom(
  null,
  async (get, set, topologyId: string) => {
    try {
      const blob_response = await v1TopologyApiObj.v1ExportTopology({
        topologyId,
      });
      return blob_response;
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      await ErrorHandler(error);
    }
  }
);

export const topologyDiffAtom = atom(
  null,
  async (get, set, oldAppstackId: string, newAppstackId: string) => {
    try {
      const diff_response = await v1TopologyApiObj.getTopologyDiff({
        getTopologyDiffRequest: {
          oldAppstackId,
          newAppstackId,
        },
      });

      return diff_response;
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      const errorResponse = await ErrorHandler(error);
      set(updateNotificationsAtom, {
        type: "error",
        title: `${errorResponse.msg}`,
        description: `${errorResponse?.extras?.join("\n")}`,
        dismissable: true,
      });
    }
  }
);

export const uploadIaCFileAtom = atom(
  null,
  async (
    get,
    set,
    appstackId: string,
    iacFile: File,
    isAppStackCreationStep = false
  ) => {
    try {
      await v1ImportApiObject.importTfstate({
        appstackId,
        tfstate: iacFile,
      });
      if (!isAppStackCreationStep) {
        set(updateNotificationsAtom, {
          type: "success",
          title: "IaC file imported successfully.",
          dismissable: true,
        });
      }
      return true;
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      await ErrorHandler(error);
      set(updateNotificationsAtom, {
        type: "error",
        title: "Error: An error occurred.",
        description:
          "Error occurred while importing the IaC file, please try again later.",
        dismissable: true,
      });
      return false;
    }
  }
);

export const uploadTopologyFileAtom = atom(
  null,
  async (get, set, appstackId: string, iacFile: File) => {
    try {
      const topology_graph: any = await v1ImportApiObject.importTopology({
        appstackId,
        topology: iacFile,
      });

      handleTopologyGraph(get, set, topology_graph);

      return true;
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      await ErrorHandler(error);
      return false;
    }
  }
);

const handleTopologyGraph = (
  get: any,
  set: (
    arg0: PrimitiveAtom<Topology> & { init: Topology },
    arg1: {
      appstackId: any;
      cloudProvider: any;
      deploymentType: any;
      id: any;
      resources: any;
      resourceConnections: any;
    }
  ) => void,
  topology_graph: any
) => {
  const resourcesList = traverseAndUpdateEachResource(
    topology_graph?.resourceTree ?? topology_graph?.resources,
    (resource) => {
      return {
        ...resource,
        configuration: undefined,
        _configuration: resource.configuration,
      };
    }
  );

  const appStackTopologyGraph = {
    appstackId: topology_graph?.appstackId,
    cloudProvider: topology_graph?.cloudProvider,
    deploymentType: topology_graph?.deploymentType,
    id: topology_graph?.id,
    resources: resourcesList ?? [],
    resourceConnections: topology_graph?.connections ?? [],
  };

  set(appStackTopologyGraphAtom, appStackTopologyGraph);

  return appStackTopologyGraph;
};

export const supportedTfDataTypesAtom = atom<SupportedTfDataTypes>({});

export const fetchSupportedTfDataTypesAtom = atom(null, async (get, set) => {
  try {
    const supported_tf_data_types: SupportedTfDataTypes =
      await v1CustomResourceTemplateApiObj.getSupportedTfDataTypes();
    set(supportedTfDataTypesAtom, supported_tf_data_types);
    return supported_tf_data_types;
  } catch (error) {
    await set(setRequestIdFromResponseAtom, (error as any).response);
    await ErrorHandler(error);
    set(supportedTfDataTypesAtom, {});
  }
});

export const saveCustomResourceTemplateForResourceTypeAtom = atom(
  null,
  async (
    get,
    set,
    reqBody: SaveCustomResourceTemplateForResourceTypeRequest
  ) => {
    try {
      const responseObj: CustomResourceTemplate =
        await v1CustomResourceTemplateApiObj.saveCustomResourceTemplateForResourceType(
          reqBody
        );
      set(updateNotificationsAtom, {
        type: "success",
        title: `Custom resource created successfully`,
        dismissable: true,
      });
      return responseObj;
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      const errorResponse = await ErrorHandler(error);
      set(updateNotificationsAtom, {
        type: "error",
        title: errorResponse.msg,
        description:
          errorResponse.extras?.length > 0 &&
          errorResponse.extras
            ?.map((extra: { message: string }) => extra.message)
            ?.join("\n"),
        dismissable: true,
      });
      return null;
    }
  }
);

export const deleteCustomResourceTemplateAtom = atom(
  null,
  async (get, set, reqBody: DeleteCustomResourceTemplateRequest) => {
    try {
      await v1CustomResourceTemplateApiObj.deleteCustomResourceTemplate(
        reqBody
      );
      set(updateNotificationsAtom, {
        type: "success",
        title: `Custom resource removed successfully`,
        dismissable: true,
      });
      return true;
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      const errorResponse = await ErrorHandler(error);
      set(updateNotificationsAtom, {
        type: "error",
        title: errorResponse.msg,
        description:
          errorResponse.extras?.length > 0 &&
          errorResponse.extras
            ?.map((extra: { message: string }) => extra.message)
            ?.join("\n"),
        dismissable: true,
      });
      return false;
    }
  }
);

export const getCustomTemplateByResourceIdAtom = atom(
  null,
  async (get, set, reqBody: GetCustomTemplateByResourceIdRequest) => {
    try {
      const resource_template =
        await v1CustomResourceTemplateApiObj.getCustomTemplateByResourceId(
          reqBody
        );
      return resource_template;
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      const errorResponse = await ErrorHandler(error);
      set(updateNotificationsAtom, {
        type: "error",
        title: errorResponse.msg,
        description:
          errorResponse.extras?.length > 0 &&
          errorResponse.extras
            ?.map((extra: { message: string }) => extra.message)
            ?.join("\n"),
        dismissable: true,
      });
      return null;
    }
  }
);

export const updateCustomTemplateAtom = atom(
  null,
  async (get, set, reqBody: UpdateCustomTemplateRequest) => {
    try {
      const resource_template =
        await v1CustomResourceTemplateApiObj.updateCustomTemplate(reqBody);
      set(updateNotificationsAtom, {
        type: "success",
        title: `Custom resource updated successfully`,
        dismissable: true,
      });
      return resource_template;
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      const errorResponse = await ErrorHandler(error);
      set(updateNotificationsAtom, {
        type: "error",
        title: errorResponse.msg,
        description:
          errorResponse.extras?.length > 0 &&
          errorResponse.extras
            ?.map((extra: { message: string }) => extra.message)
            ?.join("\n"),
        dismissable: true,
      });
      return null;
    }
  }
);

export const fetchGlobalResourceTagsAtom = atom(
  null,
  async (get, set, topologyId: string) => {
    try {
      const global_resource_tags = await v1TopologyApiObj.getGlobalResourceTags(
        {
          topologyId,
        }
      );
      return global_resource_tags;
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      const errorResponse = await ErrorHandler(error);
      set(updateNotificationsAtom, {
        type: "error",
        title: `${errorResponse.msg}`,
        description: `${errorResponse?.extras?.join("\n")}`,
        dismissable: true,
      });
    }
  }
);

export const updateGlobalResourceTagsAtom = atom(
  null,
  async (get, set, reqBody: UpdateGlobalResourceTagsRequest) => {
    try {
      await v1TopologyApiObj.updateGlobalResourceTags({
        topologyId: reqBody.topologyId,
        body: reqBody.body,
      });
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
    }
  }
);

export const markResourceAsExternalAtom = atom(
  null,
  async (get, set, resourceId: string) => {
    try {
      const external_resource =
        await v1ResourceApiObj.convertToExternalResource({
          convertToExternalResourceRequest: {
            resourceId,
          },
        });
      if (
        external_resource.id === resourceId &&
        external_resource.resourceType === "aws_external"
      ) {
        set(updateNotificationsAtom, {
          type: "success",
          title: "Resource marked as external successfully.",
          dismissable: true,
        });
        set(appStackTopologyGraphAtom, (prev) => {
          const topology_graph = { ...prev };

          if (topology_graph.resources) {
            const resource_index = topology_graph.resources.findIndex(
              (resource) => resource.id === resourceId
            );
            if (resource_index > -1) {
              topology_graph.resources[resource_index] = external_resource;
            }
          }

          return topology_graph;
        });
      }
      return external_resource;
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      await ErrorHandler(error);
      set(updateNotificationsAtom, {
        type: "error",
        title: "An error occurred while marking the resource as external.",
        dismissable: true,
      });
    }
  }
);
