import React from "react";
import type { ColumnType } from "./table-types";

const TableHeader = ({
  data,
  dataTestId,
}: {
  data: ColumnType[];
  dataTestId: string;
}) => {
  return (
    <thead className="tw-text-xssm tw-text-gray-400 tw-bg-gray-800 tw-border-b tw-border-b-gray-700">
      <tr>
        {data?.map((item) => (
          <th
            scope="col"
            className={`tw-px-4 tw-py-3 tw-text-xssm tw-text-gray-300 tw-font-normal ${
              item.className ? item.className : ""
            }`}
            key={`table_header_${item.id}`}
            data-testid={`${dataTestId}-table-header-${item.id}`}
          >
            {item.data}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
