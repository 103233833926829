import * as yup from "yup";

import { getFileExtensionFromFilename } from "./";

type DataType =
  | "string"
  | "int"
  | "bool"
  | "string_array"
  | "object_array"
  | "date";

export type FieldSchema = {
  field_name: string;
  field_type: "text" | "select" | "multiselect" | "textarea" | "field_array";
  data_type?: DataType;
  required?: boolean;
  meta_data?: FieldSchema[];
};

export const getformValidationSchema = (form_schema: FieldSchema[]) => {
  let schema: { [key: string]: any } = {};

  const dataTypeCheck = (data_type: DataType, required?: boolean) => {
    let schema: any;
    if (data_type === "string") {
      if (required) {
        schema = yup.string().nullable().required("This field is required.");
      } else {
        schema = yup.string().nullable();
      }
    } else if (data_type === "int") {
      if (required) {
        schema = yup
          .number()
          .integer()
          .nullable()
          .required("This field is required.");
      } else {
        schema = yup.number().integer().nullable();
      }
    } else if (data_type === "bool") {
      if (required) {
        schema = yup.boolean().nullable().required("This field is required.");
      } else {
        schema = yup.boolean().nullable();
      }
    } else if (data_type === "string_array") {
      if (required) {
        schema = yup
          .array()
          .of(yup.string())
          .min(1, "Please select atleast one option.")
          .nullable();
      } else {
        schema = yup.array().of(yup.string()).nullable();
      }
    } else if (data_type === "date") {
      if (required) {
        schema = yup.date().nullable().required("This field is required.");
      } else {
        schema = yup.date().nullable();
      }
    }

    return schema;
  };

  if (form_schema?.length > 0) {
    form_schema.forEach((field_schema) => {
      if (
        field_schema.field_type === "text" ||
        field_schema.field_type === "select" ||
        field_schema.field_type === "multiselect" ||
        field_schema.field_type === "textarea"
      ) {
        if (field_schema?.data_type) {
          schema[field_schema.field_name] = dataTypeCheck(
            field_schema.data_type,
            field_schema.required
          );
        }
      } else if (field_schema.field_type === "field_array") {
        if (field_schema.meta_data && field_schema.meta_data?.length > 0) {
          schema[field_schema.field_name] = yup.array().of(
            yup.object().shape(
              field_schema.meta_data.reduce((result, meta_field_schema) => {
                if (meta_field_schema?.data_type) {
                  result[meta_field_schema.field_name] = dataTypeCheck(
                    meta_field_schema.data_type,
                    meta_field_schema.required
                  );
                }

                return result;
              }, {} as Record<string, any>)
            )
          );
          if (field_schema.required) {
            schema[field_schema.field_name] = schema[
              field_schema.field_name
            ].min(1, "Please provide atleast one entry.");
          }
        }
      }
    });

    return yup.object().shape(schema);
  } else {
    return null;
  }
};

export const isValidFileType = (file: any, validFileExtensions: string[]) => {
  if (file && file.name) {
    const type = getFileExtensionFromFilename(file.name);
    return validFileExtensions.includes(type);
  } else {
    return false;
  }
};

// TODO: do not use this function as of now, it needs some changes
export const inputFileValidation = (
  fieldName: string,
  required: boolean,
  validFileExtensions: string[],
  MAX_FILE_SIZE: number
) => {
  const validationSchema: { [fieldName: string]: any } = {};
  validationSchema[fieldName] = yup.mixed();

  if (required) {
    validationSchema[fieldName].required("Please select a file");
  }

  if (validFileExtensions?.length > 0) {
    validationSchema[fieldName].test(
      "is-valid-type",
      "Not a valid file type",
      (files: any) => {
        if (files[0]) {
          return isValidFileType(files[0], validFileExtensions);
        } else {
          return true;
        }
      }
    );
  }

  if (MAX_FILE_SIZE) {
    validationSchema[fieldName].test(
      "is-valid-size",
      "Max allowed size is 10MB",
      (files: any) => {
        if (files[0].size > MAX_FILE_SIZE) {
          return false;
        } else {
          return true;
        }
      }
    );
  }

  return yup.object(validationSchema);
};
