import React, { type FC, useEffect, useState } from "react";
import { useAtom, useSetAtom } from "jotai";
import { initAmplitude } from "../utils";

import FullScreenLayout from "./full-screen-layout";
import InAppLayout from "./in-app-layout";
import { Toast, Dialog } from "../components";
import {
  notificationsAtom,
  dialogAtom,
  environmentTypeAtom,
} from "../stores/page.store";
import {
  fetchUserProductModeAtom,
  fetchProductVersionAtom,
} from "../stores/auth.store";
import { LayoutProps } from "./layout-types";
import Vira from "../components/vira/vira";
import { EnvironmentType } from "../config/constants";

const Page: FC<LayoutProps> = ({ children, pageContext }) => {
  const [notifications, updateNotifications] = useAtom(notificationsAtom);
  const [dialogData] = useAtom(dialogAtom);
  const fetchUserProductMode = useSetAtom(fetchUserProductModeAtom);
  const fetchProductVersion = useSetAtom(fetchProductVersionAtom);
  const [environmentTypeValue, setEnvironmentTypeValue] =
    useAtom(environmentTypeAtom);
  const [hostname, setHostname] = useState("");

  useEffect(() => {
    fetchUserProductMode();
    fetchProductVersion();
    if (typeof window !== "undefined") {
      setHostname(window.location.hostname);
    }
  }, []);

  useEffect(() => {
    const isProd =
      hostname &&
      !hostname.endsWith("dev.appcd.io") &&
      !hostname.endsWith("localhost");
    setEnvironmentTypeValue(
      isProd ? EnvironmentType.PROD : EnvironmentType.DEV
    );
  }, [hostname]);

  useEffect(() => {
    // Enable amplitude only for production environment
    if (environmentTypeValue === EnvironmentType.PROD) {
      initAmplitude(true);
    }
  }, []);

  return (
    <>
      {pageContext.layout === "inAppRoutes" ? (
        <InAppLayout>{children}</InAppLayout>
      ) : (
        <FullScreenLayout>{children}</FullScreenLayout>
      )}
      <Toast
        notifications={notifications}
        onDismissAll={() => updateNotifications([])}
      />
      {/* Vira-Virtual Interaction and Recommendation Assistant component for all in-app QnA */}
      <Vira />
      {dialogData?.open && <Dialog {...dialogData} />}
    </>
  );
};

export default Page;
