/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Topology,
} from '../models/index';
import {
    TopologyFromJSON,
    TopologyToJSON,
} from '../models/index';

export interface ImportTfstateRequest {
    appstackId: string;
    tfstate?: Blob;
}

export interface ImportTopologyRequest {
    appstackId: string;
    topology?: Blob;
}

/**
 * 
 */
export class V1ImportApi extends runtime.BaseAPI {

    /**
     * Import Terraform State file
     */
    async importTfstateRaw(requestParameters: ImportTfstateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.appstackId === null || requestParameters.appstackId === undefined) {
            throw new runtime.RequiredError('appstackId','Required parameter requestParameters.appstackId was null or undefined when calling importTfstate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.tfstate !== undefined) {
            formParams.append('tfstate', requestParameters.tfstate as any);
        }

        const response = await this.request({
            path: `/v1/appstacks/{appstackId}/import-tfstate`.replace(`{${"appstackId"}}`, encodeURIComponent(String(requestParameters.appstackId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Import Terraform State file
     */
    async importTfstate(requestParameters: ImportTfstateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.importTfstateRaw(requestParameters, initOverrides);
    }

    /**
     * Import Topology json file
     */
    async importTopologyRaw(requestParameters: ImportTopologyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Topology>> {
        if (requestParameters.appstackId === null || requestParameters.appstackId === undefined) {
            throw new runtime.RequiredError('appstackId','Required parameter requestParameters.appstackId was null or undefined when calling importTopology.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.topology !== undefined) {
            formParams.append('topology', requestParameters.topology as any);
        }

        const response = await this.request({
            path: `/v1/appstacks/{appstackId}/import-topology`.replace(`{${"appstackId"}}`, encodeURIComponent(String(requestParameters.appstackId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TopologyFromJSON(jsonValue));
    }

    /**
     * Import Topology json file
     */
    async importTopology(requestParameters: ImportTopologyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Topology> {
        const response = await this.importTopologyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
