export enum IconsList {
  HOME = "home",
  ADD = "add",
  APPSTACKS = "appstacks",
  POLICIES = "policies",
  DEPLOYMENTS = "deployments",
  DARKMODE = "darkmode",
  LIGHTMODE = "lightmode",
  BELL = "bell",
  SEARCH = "search",
  CLOCK_FILLED = "clock-filled",
  DELETE = "delete",
  GITHUB = "github",
  CODE_BRANCHES = "code-branches",
  CARET_DOWN = "caret-down",
  CARET_UP = "caret-up",
  CARET_RIGHT = "caret-right",
  CARET_LEFT = "caret-left",
  CIRCLE_CARET_DOWN = "circle-caret-down",
  CIRCLE_CARET_UP = "circle-caret-up",
  CLOSE = "close",
  OPEN_EYE = "open-eye",
  CLOSED_EYE = "closed-eye",
  SPINNER = "spinner",
  ELLIPSIS_VERTICAL = "ellipsis-vertical",
  DUPLICATE = "duplicate",
  EDIT = "edit",
  POWER = "power",
  GRIP_DOTS_VERTICAL = "grip-dots-vertical",
  SIDEBAR_TOGGLE = "sidebar-toggle",
  HEXAGON = "hexagon",
  LAYER_GROUP = "layer-group",
  DIAGRAM_SUBTASK = "diagram-subtask",
  CLOUDS = "clouds",
  EYE_ALT = "eye-alt",
  BOOK = "book",
  CHART = "chart",
  HELP_CIRCLE = "help-circle",
  INFO_CIRCLE = "info-circle",
  CHECK_CIRCLE = "check-circle",
  WARNING = "warning",
  REPOSITORY = "repository",
  SHIELD_BOLT = "shield-bolt",
  HELM = "helm",
  TERRAFORM = "terraform",
  ARROW_UP_FROM_SQUARE = "arrow-up-from-square",
  ARROW_DOWN_TO_SQUARE = "arrow-down-to-square",
  ARROW_UP_RIGHT = "arrow-up-right",
  ARROW_RIGHT = "arrow-right",
  ARROW_LEFT = "arrow-left",
  ALT_CIRCLE_CHECK = "alt-circle-check",
  TRASH_BLANK = "trash-blank",
  AZURE_KUBERNETES_SERVICE = "azure-kubernetes-service",
  AZURE_CONTAINER_INSTANCE = "azure-container-instance",
  AZURE_CLOUD_FUNCTION = "azure-cloud-function",
  GOOGLE_CLOUD_FUNCTION = "google-cloud-function",
  YAML_LIGHT = "yaml-light",
  GCP_CLOUD_DEPLOY = "gcp-cloud-deploy",
  AZURE_RESOURCE_MANAGER = "azure-resource-manager",
  CHECK = "check",
  TRIANGLE_EXCLAMATION = "triangle-exclamation",
  CIRCLE_QUESTION = "circle-question",
  CIRCLE_EXCLAMATION = "circle-exclamation",
  EXPAND = "expand",
  MAXIMIZE = "maximize",
  PLUS_LARGE = "plus-large",
  MINUS = "minus",
  CORNER_UP_LEFT = "corner-up-left",
  CORNER_UP_RIGHT = "corner-up-right",
  BUG = "bug",
  CIRCLE_PLUS = "circle-plus",
  AWS = "provider-aws",
  KUBERNETES = "provider-k8s",
  REFRESH = "refresh",
  DOTS_VERTICAL = "dots-vertical",
  FILE_COPY = "file-copy",
  REPLY = "reply",
  CODE_COMPARE = "code-compare",
  GEAR = "gear",
  TRAFFIC_CONE = "traffic-cone",
  SHIELD_EXCLAMATION = "shield-exclamation",
  GRID_PLUS = "grid-plus",
  FILE_ARROW_RIGHT = "file-arrow-right",
  KEY = "key",
  WINDOW_FLIP = "window-flip",
  PENCIL = "pencil",
  CIRCLE_EMPTY = "circle-empty",
  CIRCLE_FILLED = "circle-filled",
  USER_ALT = "user-alt",
  MONEY_CHECK_DOLLAR = "money-check-dollar",
  SHIELD_PLUS = "shield-plus",
  CIRCLE_LOCATION_ARROW = "circle-location-arrow",
  MESSAGE_CIRCLE_LINES = "message-circle-lines",
  INBOX_ARROW_UP = "inbox-arrow-up",
  ARROW_SQUARE_UP_RIGHT = "arrow-square-up-right",
  GLOBE = "globe",
  EYE_SLASH_ALT = "eye-slash-alt",
  FOLDER_CLOSED = "folder-closed",
  FOLDER_OPENED = "folder-opened",
  FILE = "file",
  JSON_FILE = "json-file",
  YAML_FILE = "yaml-file",
  SHELL_FILE = "shell-file",
  CIRCLE_PLAY = "circle-play",
  TRIGGER_CONNECTION = "trigger-connection",
  IAM_CONNECTION = "iam-connection",
  LINK = "link",
  DOWNLOAD = "download",
  CODE_PULL_REQUEST = "code-pull-request",
  TF_VARS = "tf-vars",
  TAG = "tag",
  SPARKLES = "sparkles",
  INDEX_ARROW_DOWN = "index-arrow-down",
  FILE_CODE = "file-code",
}

export enum AWSIconsList {
  aws_unsupported = "aws-unsupported",
  azurerm_unsupported = "azurerm-unsupported",
  microservice = "micro-service",
  aws_application_load_balancer = "application-load-balancer",
  aws_certificate_manager = "certificate-manager",
  aws_secretsmanager = "secrets-manager",
  aws_secretsmanager_secret = "secrets-manager",
  aws_route53 = "route-53",
  aws_route_table = "route-table",
  aws_s3 = "aws-s3",
  aws_s3_bucket_public_access_block = "aws-s3",
  aws_s3_bucket_versioning = "aws-s3",
  aws_eks = "eks",
  "aws-eks" = "eks",
  aws_rds = "rds",
  aws_rds_cluster = "rds",
  aws_ecs = "ecs",
  "aws-ecs" = "ecs",
  aws_kinesis = "kinesis",
  aws_kinesis_stream = "kinesis-stream",
  aws_opensearch = "opensearch",
  aws_opensearch_domain = "opensearch",
  aws_sns = "sns",
  aws_sns_topic = "sns-topic",
  aws_sqs = "sqs",
  aws_sqs_queue = "sqs-queue",
  aws_ssm = "ssm",
  aws_dynamodb = "dynamodb",
  aws_elasticache = "elasticache",
  aws_elasticache_cluster = "elasticache",
  aws_network_acl = "network-acl",
  aws_kinesis_firehose = "kinesis-firehose",
  aws_glacier = "simple-storage-service-glacier",
  aws_redshift = "redshift",
  aws_redshift_cluster = "redshift",
  aws_neptune = "neptune",
  aws_mq = "mq",
  aws_mq_broker = "mq",
  aws_glue = "glue",
  aws_lambda = "lambda",
  cloud_formation = "cloud-formation",
  aws = "aws",
  aws_athena = "athena",
  aws_athena_workgroup = "athena",
  aws_msk = "msk",
  aws_msk_cluster = "msk",
  aws_vpc = "vpc",
  aws_iam = "iam",
  aws_security_group = "security-group",
  aws_sg = "security-group",
  aws_dynamodb_table = "dynamodb-table",
  aws_ecs_task_definition = "ecs-task-definition",
  aws_kms_key = "kms-key",
  aws_api_gateway_http_api = "api-gateway-http-api",
  aws_cloudwatch_event_rule_schedule = "aws-cloudwatch-event-rule-schedule",
  aws_cloudwatch_log_group = "aws-cloudwatch-log-group",
  aws_region = "aws-region",
  data_aws_s3_object = "data-aws-s3-object",
  aws_ses_email_template = "aws-ses-email-template",
  azurerm_automation_dsc_configuration = "azurerm-automation-dsc-configuration",
  azurerm_cosmosdb_mongo_database = "azurerm-cosmosdb-mongo-database",
  azurerm_key_vault = "azurerm-key-vault",
  azurerm_kubernetes_cluster = "azurerm-kubernetes-cluster",
  azurerm_mssql_server = "azurerm-mssql-server",
  azurerm_notification_hub = "azurerm-notification-hub",
  azurerm_redis_cache = "azurerm-redis-cache",
  azurerm_storage_container = "azurerm-storage-container",
  azurerm_storage_queue = "azurerm-storage-queue",
  azurerm_data_factory = "azurerm-data-factory",
  azurerm_data_lake_file_system = "azurerm-data-lake-file-system",
  azurerm_eventgrid_topic = "azurerm-eventgrid-topic",
  azurerm_eventhub = "azurerm-eventhub",
  azurerm_servicebus_queue = "azurerm-servicebus-queue",
  azurerm_stream_analytics_cluster = "azurerm-stream-analytics-cluster",
  azurerm_synapse_workspace = "azurerm-synapse-workspace",
  eks_deployment = "eks-deployment",
  k8s = "eks",
  helm_hpa = "helm-hpa",
  helm_ingress = "helm-ingress",
  helm_service = "helm-service",
  helm_service_account = "helm-service-account",
  helm_workload = "helm-workload",
  helm_mongodb = "helm-dependency-mongodb",
  helm_mysql = "helm-dependency-mysql",
  helm_postgresql = "helm-dependency-postgresql",
  helm_redis = "helm-dependency-redis",
  helm_kafka = "helm-dependency-kafka",
  helm_cluster_role = "helm-cluster-role",
  helm_cluster_role_binding = "helm-cluster-role-binding",
  helm_configmap = "helm-configmap",
  helm_role = "helm-role",
  helm_role_binding = "helm-role-binding",
  helm_nginx_ingress_controller = "helm-nginx-ingress-controller",
  azure = "azure",
  aws_subnet = "aws-subnet",
  aws_kms = "aws-kms",
  azurerm_aks = "azurerm-kubernetes-cluster",
  azurerm_cosmosdb_mongo_collection = "azurerm-cosmosdb-mongo-database",
  azurerm_elastic_cloud_elasticsearch = "azurerm-elastic-cloud-elasticsearch",
  azurerm_eventgrid_system_topic = "azurerm-eventgrid-system-topic",
  azurerm_mysql_flexible_db_server = "azurerm-mssql-server",
  azurerm_postgresql_db_server = "azurerm-postgresql-db-server",
  azurerm_dns_zone = "azurerm-dns-zone",
  azurerm_dns_a_record = "azurerm-dns-a-record",
  azurerm_dns_cname_record = "azurerm-dns-cname-record",
  azurerm_dns_mx_record = "azurerm-dns-mx-record",
  azurerm_dns_ns_record = "azurerm-dns-ns-record",
  azurerm_dns_srv_record = "azurerm-dns-srv-record",
  azurerm_dns_txt_record = "azurerm-dns-txt-record",
  azurerm_lb = "azurerm-load-balancer",
  azurerm_cosmosdb_postgresql_cluster = "azurerm-cosmosdb-mongo-database",
  azurerm_cognitive = "azurerm-cognitive",
  azurerm_cdn = "azurerm-cdn",
  appcd_vira_bubble = "appcd-vira-bubble",
}

export enum IconSize {
  "xxs" = "tw-w-2 tw-h-2",
  "xs" = "tw-w-3 tw-h-3",
  "sm" = "tw-w-4 tw-h-4",
  "md" = "tw-w-5 tw-h-5",
  "lg" = "tw-w-6 tw-h-6",
  "xl" = "tw-w-7 tw-h-7",
  "2xl" = "tw-w-8 tw-h-8",
  "3xl" = "tw-w-9 tw-h-9",
  "4xl" = "tw-w-10 tw-h-10",
  "5xl" = "tw-w-12 tw-h-12",
  "6xl" = "tw-w-16 tw-h-16",
}

export interface IconProps {
  name: IconsList;
  size?: IconSize;
  className?: string;
  alt?: string;
  dataTestId?: string;
}

export interface AWSIconProps {
  name: AWSIconsList;
  size?: IconSize;
  className?: string;
  alt?: string;
  fallbackPlaceholder?: string;
  dataTestId?: string;
}
