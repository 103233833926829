import React, { memo } from "react";
import { NodeProps, Handle, Position, NodeToolbar } from "reactflow";
import {
  AWSIcon,
  AWSIconsList,
  IconSize,
  Icon,
  IconsList,
} from "../../components";

const CustomResourceNode = (node: NodeProps) => {
  return (
    <>
      {/* Below commented code will be used to show menu over node for these actions */}
      {/* <NodeToolbar
        isVisible={node.selected}
        position={Position.Top}
        align="center"
      >
        <button>configuration</button>
        <button>duplicate</button>
        <button>delete</button>
      </NodeToolbar> */}
      <Handle
        type="source"
        position={Position.Top}
        id={`${node?.data?.id}-top`}
        isConnectable={true}
        className={`tw-w-2.5 tw-h-2.5 -tw-top-1.5 tw-bg-gray-700 hover:tw-bg-gray-500 hover:tw-border-gray-300 ${
          node.selected ? "tw-border-blue-500" : "tw-border-gray-400"
        }`}
        data-testid={`handle-${node?.data?.id}-top`}
      />
      <Handle
        type="source"
        position={Position.Left}
        id={`${node?.data?.id}-left`}
        isConnectable={true}
        className={`tw-w-2.5 tw-h-2.5 -tw-left-1.5 tw-bg-gray-700 hover:tw-bg-gray-500 hover:tw-border-gray-300 ${
          node.selected ? "tw-border-blue-500" : "tw-border-gray-400"
        }`}
        data-testid={`handle-${node?.data?.id}-left`}
      />
      <div
        className={`${
          node?.data?.external_resource
            ? "tw-w-70 tw-min-h-18 tw-gap-7 tw-bg-gray-800 hover:tw-bg-gray-700"
            : "tw-w-62 tw-gap-2 tw-bg-gray-700 hover:tw-bg-gray-600"
        } tw-flex tw-items-center tw-justify-between tw-p-3 tw-rounded-md tw-border ${
          node?.data?.diffType === "created"
            ? "tw-border tw-border-green-500"
            : node?.data?.diffType === "deleted"
            ? "tw-border tw-border-red-500"
            : node.selected
            ? "tw-border-blue-500"
            : "tw-border-gray-600"
        }`}
      >
        <div className="tw-flex tw-gap-2 tw-items-center">
          <div className="tw-basis-7">
            <AWSIcon
              name={AWSIconsList[node?.data?.icon as keyof typeof AWSIconsList]}
              alt={node?.data?.label || node?.data?.resourceTypeLabel}
              size={IconSize.xl}
              fallbackPlaceholder={node?.data?.resourceType}
              className="tw-rounded"
            />
          </div>
          <div className="tw-relative">
            <div className="tw-text-sm tw-text-white tw-font-semibold tw-break-all">
              {node?.data?.label || node?.data?.resourceTypeLabel}
            </div>
            {node?.data?.label && (
              <div className="tw-text-xxsxs tw-text-gray-200 tw-font-normal tw-break-all">
                {node?.data?.resourceTypeLabel}
              </div>
            )}
          </div>
          {node?.data?.external_resource && (
            <div className="tw-absolute tw-px-1.5 tw-py-1 tw-text-xxsxs tw-italic tw-text-gray-100 tw-right-px tw-top-px tw-bg-gray-600 tw-rounded-se-md tw-rounded-es-md">
              External
            </div>
          )}
        </div>
        <div className="tw-basis-4">
          {node.data?.errors?.validationErrors ? (
            <Icon
              name={IconsList.INFO_CIRCLE}
              className="tw-text-red-500"
              size={IconSize.sm}
            />
          ) : node.data?.errors?.policyViolationErrors ? (
            <Icon
              name={IconsList.SHIELD_EXCLAMATION}
              className="tw-text-yellow-500"
              size={IconSize.sm}
            />
          ) : null}
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id={`${node?.data?.id}-bottom`}
        isConnectable={true}
        className={`tw-w-2.5 tw-h-2.5 -tw-bottom-1.5 tw-bg-gray-700 hover:tw-bg-gray-500 hover:tw-border-gray-300 ${
          node.selected ? "tw-border-blue-500" : "tw-border-gray-400"
        }`}
        data-testid={`handle-${node?.data?.id}-bottom`}
      />
      <Handle
        type="source"
        position={Position.Right}
        id={`${node?.data?.id}-right`}
        isConnectable={true}
        className={`tw-w-2.5 tw-h-2.5 -tw-right-1.5 tw-bg-gray-700 hover:tw-bg-gray-500 hover:tw-border-gray-300 ${
          node.selected ? "tw-border-blue-500" : "tw-border-gray-400"
        }`}
        data-testid={`handle-${node?.data?.id}-right`}
      />
    </>
  );
};

export default memo(CustomResourceNode);
