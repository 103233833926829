import { type ReactElement } from "react";

import { IconsList } from "../../components";

type PageBannerTypes = "success" | "warning" | "danger" | "info";

export const PageBannerColors = {
  success: "tw-bg-green-800 tw-text-gray-50",
  warning: "tw-bg-yellow-800 tw-text-gray-50",
  danger: "tw-bg-red-800 tw-text-gray-50",
  info: "tw-bg-blue-800 tw-text-gray-50",
};

export const PageBannerIcons = {
  success: IconsList.CHECK_CIRCLE,
  warning: IconsList.WARNING,
  danger: IconsList.WARNING,
  info: IconsList.INFO_CIRCLE,
};

export interface PageBannerProps {
  type: PageBannerTypes;
  content: string | ReactElement;
  dataTestId: string;
}
