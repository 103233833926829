/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CustomResourceTemplate,
  CustomSupportedResourceType,
  CustomTemplateReq,
  SupportedTfDataTypes,
  UpdateCustomResourceTemplateRequest,
} from '../models/index';
import {
    CustomResourceTemplateFromJSON,
    CustomResourceTemplateToJSON,
    CustomSupportedResourceTypeFromJSON,
    CustomSupportedResourceTypeToJSON,
    CustomTemplateReqFromJSON,
    CustomTemplateReqToJSON,
    SupportedTfDataTypesFromJSON,
    SupportedTfDataTypesToJSON,
    UpdateCustomResourceTemplateRequestFromJSON,
    UpdateCustomResourceTemplateRequestToJSON,
} from '../models/index';

export interface DeleteCustomResourceTemplateRequest {
    uuid: string;
}

export interface GetCustomTemplateByResourceIdRequest {
    id: string;
}

export interface SaveCustomResourceTemplateForResourceTypeRequest {
    customTemplateReq: CustomTemplateReq;
}

export interface UpdateCustomTemplateRequest {
    uuid: string;
    updateCustomResourceTemplateRequest: UpdateCustomResourceTemplateRequest;
}

/**
 * 
 */
export class V1CustomResourceTemplateApi extends runtime.BaseAPI {

    /**
     * Delete custom template by ID
     */
    async deleteCustomResourceTemplateRaw(requestParameters: DeleteCustomResourceTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling deleteCustomResourceTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/custom-resource-template/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete custom template by ID
     */
    async deleteCustomResourceTemplate(requestParameters: DeleteCustomResourceTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCustomResourceTemplateRaw(requestParameters, initOverrides);
    }

    /**
     * Get custom template for a resource ID
     */
    async getCustomTemplateByResourceIdRaw(requestParameters: GetCustomTemplateByResourceIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomResourceTemplate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCustomTemplateByResourceId.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/custom-resource-template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomResourceTemplateFromJSON(jsonValue));
    }

    /**
     * Get custom template for a resource ID
     */
    async getCustomTemplateByResourceId(requestParameters: GetCustomTemplateByResourceIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomResourceTemplate> {
        const response = await this.getCustomTemplateByResourceIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List supported custom resource types
     */
    async getSupportedCustomResourceTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomSupportedResourceType>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/custom-supported-resource-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomSupportedResourceTypeFromJSON));
    }

    /**
     * List supported custom resource types
     */
    async getSupportedCustomResourceTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomSupportedResourceType>> {
        const response = await this.getSupportedCustomResourceTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     * List supported Terraform data types
     */
    async getSupportedTfDataTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SupportedTfDataTypes>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/supported-tf-datatypes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SupportedTfDataTypesFromJSON(jsonValue));
    }

    /**
     * List supported Terraform data types
     */
    async getSupportedTfDataTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SupportedTfDataTypes> {
        const response = await this.getSupportedTfDataTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Save custom template for a resource type
     */
    async saveCustomResourceTemplateForResourceTypeRaw(requestParameters: SaveCustomResourceTemplateForResourceTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomResourceTemplate>> {
        if (requestParameters.customTemplateReq === null || requestParameters.customTemplateReq === undefined) {
            throw new runtime.RequiredError('customTemplateReq','Required parameter requestParameters.customTemplateReq was null or undefined when calling saveCustomResourceTemplateForResourceType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/custom-resource-template`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomTemplateReqToJSON(requestParameters.customTemplateReq),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomResourceTemplateFromJSON(jsonValue));
    }

    /**
     * Save custom template for a resource type
     */
    async saveCustomResourceTemplateForResourceType(requestParameters: SaveCustomResourceTemplateForResourceTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomResourceTemplate> {
        const response = await this.saveCustomResourceTemplateForResourceTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update custom template by ID
     */
    async updateCustomTemplateRaw(requestParameters: UpdateCustomTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomResourceTemplate>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling updateCustomTemplate.');
        }

        if (requestParameters.updateCustomResourceTemplateRequest === null || requestParameters.updateCustomResourceTemplateRequest === undefined) {
            throw new runtime.RequiredError('updateCustomResourceTemplateRequest','Required parameter requestParameters.updateCustomResourceTemplateRequest was null or undefined when calling updateCustomTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/custom-resource-template/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCustomResourceTemplateRequestToJSON(requestParameters.updateCustomResourceTemplateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomResourceTemplateFromJSON(jsonValue));
    }

    /**
     * Update custom template by ID
     */
    async updateCustomTemplate(requestParameters: UpdateCustomTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomResourceTemplate> {
        const response = await this.updateCustomTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
