import { AWSIconsList, IconsList } from "../components/icons/icon-types";
import { SidebarMenuItem } from "../components/sidebar/sidebar-types";
import type { Crumb } from "../components/breadcrumb/breadcrumb-types";
import { Step } from "../components/stepper/stepper.types";
import { FlowOption } from "../components/flow-selection/flow-selection-types";
import { AppStackCreationFlow } from "../components/appstack-creation/appstack-creation-types";
import { TargetCompute } from "../apis/appcd";
import { ConnectionType } from "../apis/topology";

export const sidebar_menu_items: SidebarMenuItem[] = [
  {
    id: "repositories",
    title: "Repositories",
    icon: IconsList.REPOSITORY,
    isActive: false,
    link: "/repositories",
    otherActivationPath: ["/repositories/onboard"],
    analyticsEventId: "leftnav_repos",
  },
  {
    id: "appstack",
    title: "appStacks",
    icon: IconsList.LAYER_GROUP,
    isActive: false,
    link: "/appstacks",
    otherActivationPath: ["/appstacks/create"],
    analyticsEventId: "leftnav_stacks",
  },
  {
    id: "policy_management",
    title: "Policy Management",
    icon: IconsList.SHIELD_BOLT,
    isActive: false,
    link: "",
    submenuItem: [
      {
        id: "appstack_create_policies",
        isActive: false,
        link: "/appstacks/policies",
        title: "appStack Policies",
        analyticsEventId: "leftnav_stack_policies",
      },
    ],
  },
  {
    id: "profile_details",
    title: "Profile Details",
    icon: IconsList.USER_ALT,
    isActive: false,
    link: "/account-settings/profile",
    type: "ACCOUNT_SETTINGS",
    analyticsEventId: "leftnav_user_account_profile_update",
  },
  {
    id: "license_information",
    title: "License Information",
    icon: IconsList.MONEY_CHECK_DOLLAR,
    isActive: false,
    link: "/account-settings/license",
    type: "ACCOUNT_SETTINGS",
    analyticsEventId: "leftnav_user_account_license_info",
  },
  {
    id: "personal_access_tokens",
    title: "Personal Access Tokens",
    icon: IconsList.KEY,
    isActive: false,
    link: "/account-settings/pat",
    type: "ACCOUNT_SETTINGS",
    analyticsEventId: "leftnav_user_account_pat_info",
  },
];

export const breadcrumb_items: Record<string, Crumb> = {
  dashboard: { id: "dashboard", title: "Dashboard", link: "/dashboard" },
  appstacks: {
    id: "apptacks",
    title: "appStacks",
    link: "/appstacks",
  },
  all_appstacks: {
    id: "all-appstacks",
    title: "All appStacks",
    link: "/appstacks",
  },
  new_appstacks: {
    id: "new-app-stacks",
    title: "New appStack",
    link: "/appstacks/create",
  },
  create_new_version: {
    id: "create-new-version",
    title: "Create New Version",
  },
  duplicate_appstack: {
    id: "duplicate-appstack",
    title: "Duplicate appStack",
  },
  create_appstacks: {
    id: "new-app-stacks-create",
    title: "Create",
    link: "/appstacks/create",
  },
  deployments: {
    id: "deployments",
    title: "Deployments",
    link: "/deployments",
  },
  policies: {
    id: "policies",
    title: "Policies",
    link: "/deployments/policies",
  },
  repositories: {
    id: "repositories",
    title: "Repositories",
    link: "/repositories",
  },
  add_repositories: {
    id: "new-app-stacks",
    title: "Add Repositories",
  },
  configurations: {
    id: "configurations",
    title: "Configurations",
  },
  policy_management: {
    id: "policy-management",
    title: "Policy Management",
    link: "/appstacks/policies",
  },
  appstack_policies: {
    id: "appstack-policies",
    title: "appStack Policies",
  },
  home: {
    id: "home",
    title: "Home",
    link: "/",
  },
  account_settings: {
    id: "account-settings",
    title: "Account Settings",
    link: "/account-settings/",
  },
  profile_details: {
    id: "profile-details",
    title: "Profile Details",
  },
  license_information: {
    id: "license-information",
    title: "License Information",
  },
  personal_access_tokens: {
    id: "personal-access-tokens",
    title: "Personal Access Tokens",
  },
  generate_token: {
    id: "generate-token",
    title: "Generate Token",
  },
};

export const primary_color_palette = [
  "gray",
  "green",
  "blue",
  "yellow",
  "orange",
  "red",
  "pink",
  "purple",
];

export const AppStackCreationStepIdList = {
  REPO_SELECTION: "repos-selection",
  DEPLOYMENT_FILE_SELECTION: "deployment-file-selection",
  TARGET_COMPUTE: "target-compute-selection",
  // TARGET_IAC: "target-iac-selection", // TODO: use this when required from business
  POLICIES: "policies-selection",
  GENERAL_DETAILS: "general-details",
};

export const RepoOnboardingStepIdList = {
  CONFIGURE_PROVIDER: "configure-provider",
  CONFIGURE_PAT: "configure-pat",
  REPO_SELECTION: "repo-selection",
};

export const appStackCreationSteps: Step[] = [
  {
    label: "Select Repos",
    id: AppStackCreationStepIdList["REPO_SELECTION"],
    isOptional: false,
  },
  {
    label: "Select File to Import",
    id: AppStackCreationStepIdList["DEPLOYMENT_FILE_SELECTION"],
    isOptional: false,
  },
  {
    label: "Select Target Cloud Service",
    id: AppStackCreationStepIdList["TARGET_COMPUTE"],
    isOptional: false,
  },
  // TODO: use this when required from business
  // {
  //   label: "Select Target IaC",
  //   id: AppStackCreationStepIdList["TARGET_IAC"],
  //   isOptional: false,
  // },
  {
    label: "Select Policies",
    id: AppStackCreationStepIdList["POLICIES"],
    isOptional: false,
  },
  {
    label: "General Details",
    id: AppStackCreationStepIdList["GENERAL_DETAILS"],
    isOptional: false,
  },
];

export const repoOnboardingFromProviderConfigurationSteps: Step[] = [
  {
    label: "Configure Provider",
    id: RepoOnboardingStepIdList["CONFIGURE_PROVIDER"],
    isOptional: false,
  },
  {
    label: "Select Repositories",
    id: RepoOnboardingStepIdList["REPO_SELECTION"],
    isOptional: false,
  },
];

export const repoOnboardingFromPATSteps: Step[] = [
  {
    label: "Configure PAT",
    id: RepoOnboardingStepIdList["CONFIGURE_PAT"],
    isOptional: false,
  },
  {
    label: "Select Repositories",
    id: RepoOnboardingStepIdList["REPO_SELECTION"],
    isOptional: false,
  },
];

export const repoOnboardingSteps: Step[] = [
  {
    label: "Configure Provider",
    id: RepoOnboardingStepIdList["CONFIGURE_PROVIDER"],
    isOptional: false,
  },
  {
    label: "Select Repositories",
    id: RepoOnboardingStepIdList["REPO_SELECTION"],
    isOptional: false,
  },
];

export const paginationRecordsPerPageList = [10, 20, 50, 75, 100];

export const appstackCreationFlowOptions: FlowOption[] = [
  {
    id: AppStackCreationFlow.FROM_REPO,
    title: "appStack from source code",
    description:
      "Start by configuring source code repositories and let appCD generate deployment architecture for you.",
    icon: IconsList.REPOSITORY,
    analyticsEventId: "new_stack_code_scan",
  },
  {
    id: AppStackCreationFlow.FROM_SCRATCH,
    title: "appStack from scratch",
    description:
      "Create a blank appStack to build your own deployment architecture.",
    icon: IconsList.GRID_PLUS,
    analyticsEventId: "new_stack_blank_canvas",
  },
  {
    id: AppStackCreationFlow.FROM_DEPLOYMENT_FILES,
    title: "appStack from deployment file",
    description:
      "Already have a deployment file? Import existing deployment file and get started.",
    icon: IconsList.FILE_ARROW_RIGHT,
    analyticsEventId: "new_stack_deployment_files",
  },
];

// TODO: remove this list when backend starts sending this info
export const SCMProviderDetailsMapping: Record<
  string,
  {
    label: string;
    iconUrl: string;
    type: string;
    configured: boolean;
    configureAppUrl: string;
    patConfigurationDocUrl: string;
  }
> = {
  public_github: {
    label: "GitHub",
    iconUrl: "/images/scm-providers/github.png",
    type: "github",
    configured: true,
    configureAppUrl: "https://github.com/settings/applications/",
    patConfigurationDocUrl:
      "https://docs.github.com/en/authentication/keeping-your-account-and-data-secure/creating-a-personal-access-token",
  },
  public_gitlab: {
    label: "GitLab",
    iconUrl: "/images/scm-providers/gitlab.png",
    type: "gitlab",
    configured: true,
    configureAppUrl: "https://gitlab.com/-/user_settings/applications",
    patConfigurationDocUrl:
      "https://docs.gitlab.com/ee/user/profile/personal_access_tokens.html",
  },
  public_bitbucket: {
    label: "Bitbucket",
    iconUrl: "/images/scm-providers/bitbucket.png",
    type: "bitbucket",
    configured: true,
    configureAppUrl:
      "https://bitbucket.org/account/settings/app-authorizations/",
    patConfigurationDocUrl:
      "https://confluence.atlassian.com/bitbucketserver072/personal-access-tokens-1005335924.html",
  },
  azure_devops: {
    label: "Azure DevOps",
    iconUrl: "/images/scm-providers/azure-devops.png",
    type: "azuredev",
    configured: true,
    configureAppUrl:
      "https://learn.microsoft.com/en-us/azure/devops/organizations/accounts/use-personal-access-tokens-to-authenticate?view=azure-devops&tabs=Windows",
    patConfigurationDocUrl:
      "https://learn.microsoft.com/en-us/azure/devops/organizations/accounts/use-personal-access-tokens-to-authenticate?view=azure-devops&tabs=Windows#create-a-pat",
  },
};

export interface TargetComputeProperties {
  targetIac: string[];
  label: string;
  icon: string;
  associatedPolicyProvider: string[];
}

export const TargetComputePropertiesMap: Record<
  TargetCompute,
  TargetComputeProperties
> = {
  "aws-ecs": {
    targetIac: ["Terraform"],
    label: "AWS ECS",
    icon: AWSIconsList.aws_ecs,
    associatedPolicyProvider: ["aws"],
  },
  k8s: {
    targetIac: ["Helm", "Terraform"],
    label: "AWS EKS",
    icon: AWSIconsList.aws_eks,
    associatedPolicyProvider: ["aws", "k8s"],
  },
  "aks-tf": {
    targetIac: ["Helm", "Terraform"],
    label: "Azure AKS",
    icon: AWSIconsList.azurerm_kubernetes_cluster,
    associatedPolicyProvider: ["azure", "k8s"],
  },
  "aws-lambda": {
    targetIac: ["Terraform"],
    label: "AWS Lambda",
    icon: AWSIconsList.aws_lambda,
    associatedPolicyProvider: ["aws"],
  },
};

export const defaultGitIconURL =
  "https://git-scm.com/images/logos/downloads/Git-Icon-1788C.png";

export const localFileTypes = ["localfile", "file"];

export const appcdPATExpirationOptions = [
  {
    label: "1 day",
    value: "1",
  },
  {
    label: "7 days",
    value: "7",
  },
  {
    label: "30 days",
    value: "30",
  },
  {
    label: "60 days",
    value: "60",
  },
  {
    label: "90 days",
    value: "90",
  },
  {
    label: "180 days",
    value: "180",
  },
  {
    label: "1 Year",
    value: "365",
  },
  {
    label: "Custom",
    value: "CUSTOM",
  },
];

export const IMPORT_MAX_FILE_SIZE = 10485760; //10MB
export const validIaCFileExtensions = ["json", "tfstate"];
export const validTopologyFileExtensions = ["json"];
export enum IaCFileTypes {
  tf = "hcl",
  hcl = "hcl",
  tfstate = "hcl",
  yml = "yaml",
  yaml = "yaml",
  json = "json",
  sh = "shell",
  md = "markdown",
}
export const providersPrefixesForPolicyFilter = ["azurerm_", "aws_", "helm_"];
export const targetComputeResourceTypes = ["aws_ecs", "aws_lambda"];
export const topologyResourceConnectionTypes: { [key: string]: any } = {
  [ConnectionType.Trigger]: {
    icon: IconsList.TRIGGER_CONNECTION,
    label: "Trigger",
  },
  [ConnectionType.Iam]: {
    icon: IconsList.IAM_CONNECTION,
    label: "IAM",
  },
  [ConnectionType.Data]: {
    icon: IconsList.LINK,
    label: "Reference",
  },
};

export enum EnvironmentType {
  DEV = "dev",
  PROD = "prod",
}

export const unsupportedResourceTypes = [
  "aws_unsupported",
  "azurerm_unsupported",
];
