import type {
  Topology,
  TopologyResourceWithPosition,
  TopologyResourceConnection,
  TopologyResourceValidations,
  SupportedResourceType,
  TopologyResourceWithMisconfigurations,
  NewTopologyResourceConnectionRequest,
  ResourcePosition,
  ResourceIdWithPosition,
  TopologyDiff,
  ProviderWiseSupportedResourceType,
  GetResourceTemplateForResourceTypeRequest,
  CustomResourceTemplate,
  UpdateResourceConnectionRequest,
  TopologyResourceConnectionData,
} from "../../apis/topology";
import type { AppStack, CloudProvider } from "../../apis/appcd";

export type PropertiesType = { [key: string]: any };

export type ResourceListType = SupportedResourceType[];

export type ResourceErrors = {
  policyViolationErrors?: number;
  validationErrors?: number;
  errorFields?: any[];
};

export type TopologyErrorsType = {
  topologyId: string;
  totalErrors: number;
  totalValidationErrors: number;
  resourcesWithErrors: {
    [key: string]: ResourceErrors;
  };
  isValidated: boolean;
};

export type Resource = TopologyResourceWithPosition & {
  hidden?: boolean;
  expanded?: boolean;
  updateExpanded?: (expanded: boolean) => void;
  selected?: boolean;
  errors?: ResourceErrors;
  diffType?: "created" | "deleted";
  external_resource?: boolean;
};

export type ResourceConnection = TopologyResourceConnection & {
  data: {
    hidden?: boolean;
    connectionTypes: Array<
      TopologyResourceConnectionData & {
        diffType?: "created" | "deleted";
        onConnectionTypeClick?: (connection_data: any) => void;
        selected_resource_id?: string;
      }
    >;
  };
};

export interface SupportedResourceListProps {
  resourcesList: ProviderWiseSupportedResourceType;
  onPanelClose: () => void;
  onDragStart: (event: any, node_id: string) => void;
  onResourceTabChange?: (
    resourceType: keyof ProviderWiseSupportedResourceType
  ) => void;
  cloudService: string;
  activeResourceTab?: keyof ProviderWiseSupportedResourceType;
  onAddCustomResource?: () => void;
  onActiveCustomResourceAction?: (
    resource: SupportedResourceType,
    action: string
  ) => void;
}

export interface ResourcePropertiesFormProps {
  resourcePropertiesTemplate: TopologyResourceValidations & {
    attributes: {
      disabled?: boolean;
    };
  };
  resource: TopologyResourceWithMisconfigurations;
  onPanelClose: () => void;
  onUpdateResourcePropertiesValues: (
    topologyResourceWithPosition: TopologyResourceWithPosition &
      TopologyResourceConnectionData
  ) => void;
  defaultSelectedTabId?: string;
  resourceHasErrors?: boolean;
  showResourcePropertiesErrors?: boolean;
  onEditCustomResource?: (fetchTemplate?: boolean) => void;
  markResourceAsExternal?: (resourceId: string) => Promise<void>;
}

export interface CompareTopologyProps {
  appStackVersions?: AppStack[];
  graphData: Topology;
  getTopologyDiff: (newAppStackId: string) => Promise<TopologyDiff | undefined>;
  setShowTopologyDiff: (showTopologyDiff: boolean) => void;
}

export interface TopologyGraphProps {
  showTopologyDiff: boolean;
  setShowTopologyDiff: (showTopologyDiff: boolean) => void;
  getTopologyDiff: (newAppStackId: string) => Promise<TopologyDiff | undefined>;
  appStackVersions?: AppStack[];
  graphData: Topology;
  resourcePropertiesTemplates: {
    [resourceType: string]: TopologyResourceValidations;
  };
  fetchResourcePropertiesTemplate: (
    reqBody: GetResourceTemplateForResourceTypeRequest
  ) => void;
  fetchResourcePropertiesValues: (
    topologyId: string,
    resourceId: string
  ) => void;
  fetchResourceConnectionPropertiesValues: (
    topologyId: string,
    resourceConnectionId: string
  ) => any;
  fetchResourceConnectionTypes: (
    sourceResourceType: string,
    targetResourceType: string
  ) => any;
  updateResourcePropertiesValues: (
    topologyId: string,
    resourceId: string,
    topologyResourceWithPosition: TopologyResourceWithPosition
  ) => void;
  updateResourceConnectionPropertiesValues: (
    topologyId: string,
    resourceConnectionId: string,
    updateResourceConnectionRequest: UpdateResourceConnectionRequest
  ) => void;
  updateResourcePosition: (
    topologyId: string,
    resourceId: string,
    resourcePosition: ResourcePosition
  ) => void;
  updateBulkResourcePosition: (
    topologyId: string,
    resourceIdWithPosition: ResourceIdWithPosition[]
  ) => void;
  resourcesList?: ProviderWiseSupportedResourceType;
  createTopologyResource?: (
    topologyId: string,
    topologyResourceWithPosition: TopologyResourceWithPosition,
    resourceCreatedCallback?: (
      resource: TopologyResourceWithMisconfigurations
    ) => void
  ) => void;
  createTopologyResourceConnection?: (
    topologyId: string,
    newTopologyResourceConnectionRequest: NewTopologyResourceConnectionRequest,
    resourceConnectionCreatedCallback?: (
      resourceConnection: TopologyResourceConnection
    ) => void
  ) => void;
  deleteTopologyResource?: (
    topologyId: string,
    resourceId: string,
    resourceType: string
  ) => void;
  deleteTopologyResourceConnection?: (
    topologyId: string,
    resourceConnection: TopologyResourceConnection
  ) => Promise<void>;
  topologyErrors?: TopologyErrorsType;
  validateTopology?: (
    topologyId: string,
    resourcesToVerify: TopologyResourceWithPosition[]
  ) => void;
  refetchTopologyGraphData?: () => void;
  provider: CloudProvider;
  refetchResourcesList?: () => void;
  showGlobalResourceTagsDrawer: boolean;
  setShowGlobalResourceTagsDrawer: (
    showGlobalResourceTagsDrawer: boolean
  ) => void;
  globalResourceTags: Record<string, any>;
  onSubmitGlobalResourceTags: (tags: object) => void;
  markResourceAsExternal: (
    resourceId: string
  ) => Promise<TopologyResourceWithMisconfigurations | undefined>;
}

export interface CustomResourceDrawerProps {
  open: boolean;
  provider: CloudProvider;
  customResourceToEdit:
    | (CustomResourceTemplate & { resourceId?: string })
    | null;
  onClose: (shouldRefreshResourceList?: boolean) => void;
}

export enum CustomResourceCreationModes {
  "FROM_SCRATCH" = "FROM_SCRATCH", // when custom resource template creation is stated from scratch
  "FROM_UNSUPPORTED_RESOURCE" = "FROM_UNSUPPORTED_RESOURCE", // when custom resource template creation is initiated from an unsupported resource
  "EDIT_MODE" = "EDIT_MODE", // when custom resource template is being edited
}

export enum TopologyPageReferrer {
  "QUICK_APPSTACK_CREATE" = "QUICK_APPSTACK_CREATE",
}

export type TopologyPageMeta = {
  referrer?: TopologyPageReferrer;
};
