import React from "react";

import { Icon, IconSize } from "../../components";

import {
  ButtonProps,
  ButtonSize,
  ButtonVariant,
  OutlinedButtonVariant,
  IconVariant,
} from "./button-types";

const Button = ({
  label,
  leftIcon,
  rightIcon,
  variant = "primary",
  size = "md",
  type = "button",
  disabled = false,
  onClick = () => { },
  className = "",
  dataTestId = "",
  outlined = false,
}: ButtonProps) => {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={`${outlined && variant === "secondary" // remove this condition once we have outlined design for all variants
        ? OutlinedButtonVariant[variant]
        : ButtonVariant[variant]
        } ${ButtonSize[size]} ${disabled ? "tw-opacity-50 tw-cursor-not-allowed" : ""
        } tw-rounded-md tw-font-semibold tw-flex tw-justify-center tw-items-center tw-gap-1.5 hover:tw-bg-opacity-90 ${className}`}
      data-testid={dataTestId}
    >
      {leftIcon && (
        <Icon
          name={leftIcon}
          size={IconSize[size]}
          className={`${IconVariant[variant]}`}
        />
      )}
      {label}
      {rightIcon && (
        <Icon
          name={rightIcon}
          size={IconSize[size]}
          className={`${IconVariant[variant]}`}
        />
      )}
    </button>
  );
};

export default Button;
