import React from "react";
import { Formik, Form } from "formik";

import { Button, TextArea, Link, IconsList } from "../../components";

const SubmitFeedback = ({
  onSubmit,
  onSubmitCallback,
}: {
  onSubmit: (description: string, rating: string) => void;
  onSubmitCallback: () => void;
}) => {
  return (
    <div className="tw-flex tw-flex-col tw-gap-5">
      <span className="tw-text-sm tw-font-normal tw-text-white">
        Have feedback or feature requests to improve the appCD's experience?
        Share it with us in detail, why would you need a certain feature and how
        would you use it.
      </span>
      <Formik
        initialValues={{
          description: "",
          rating: "0",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          await onSubmit(values.description, values.rating);
          setSubmitting(false);
          onSubmitCallback && onSubmitCallback();
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => {
          const ratingClasses =
            "tw-rounded-full tw-p-1.5 tw-border tw-cursor-pointer tw-w-9 tw-h-9 tw-text-2xl tw-flex tw-items-center tw-justify-center";
          const ratingIsSelectedClasses =
            "tw-border-blue-500 tw-bg-blue-500 tw-bg-opacity-30";
          const ratingIsNotSelectedClasses =
            "tw-border-gray-600 hover:tw-border-gray-500 tw-bg-gray-800 hover:tw-bg-blue-500 hover:tw-bg-opacity-10";

          return (
            <Form className="tw-flex tw-flex-col tw-gap-5">
              <div>
                <TextArea
                  name="description"
                  placeholder="I think having this feature will improve my workflow for..."
                  label="Description"
                  className="tw-text-white"
                  dataTestId="description"
                />
              </div>
              <div className="tw-flex tw-items-center tw-gap-2">
                <span
                  className={`${ratingClasses} ${
                    values.rating === "1"
                      ? ratingIsSelectedClasses
                      : ratingIsNotSelectedClasses
                  }`}
                  onClick={() => setFieldValue("rating", "1")}
                >
                  🤩
                </span>
                <span
                  className={`${ratingClasses} ${
                    values.rating === "2"
                      ? ratingIsSelectedClasses
                      : ratingIsNotSelectedClasses
                  }`}
                  onClick={() => setFieldValue("rating", "2")}
                >
                  🙂
                </span>
                <span
                  className={`${ratingClasses} ${
                    values.rating === "3"
                      ? ratingIsSelectedClasses
                      : ratingIsNotSelectedClasses
                  }`}
                  onClick={() => setFieldValue("rating", "3")}
                >
                  🙁
                </span>
                <span
                  className={`${ratingClasses} ${
                    values.rating === "4"
                      ? ratingIsSelectedClasses
                      : ratingIsNotSelectedClasses
                  }`}
                  onClick={() => setFieldValue("rating", "4")}
                >
                  😭
                </span>
              </div>
              <div className="tw-flex tw-items-center tw-justify-between tw-mt-5">
                <span className="tw-text-sm tw-font-normal tw-text-gray-200">
                  You can also reach out to us at{" "}
                  <Link
                    href="mailto:support@appcd.com"
                    text="support@appcd.com"
                    dataTestId="email"
                  />
                </span>
                <Button
                  type="submit"
                  label={
                    isSubmitting ? "Submitting Feedback..." : "Submit Feedback"
                  }
                  leftIcon={isSubmitting ? IconsList.SPINNER : undefined}
                  disabled={
                    (values.description === "" && values.rating === "0") ||
                    isSubmitting
                  }
                  dataTestId="submit-feedback-button"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SubmitFeedback;
