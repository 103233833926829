/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ConnectionType = {
    Trigger: 'TRIGGER',
    Data: 'DATA',
    Iam: 'IAM',
    Brokeniam: 'BROKENIAM'
} as const;
export type ConnectionType = typeof ConnectionType[keyof typeof ConnectionType];


export function ConnectionTypeFromJSON(json: any): ConnectionType {
    return ConnectionTypeFromJSONTyped(json, false);
}

export function ConnectionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectionType {
    return json as ConnectionType;
}

export function ConnectionTypeToJSON(value?: ConnectionType | null): any {
    return value as any;
}

