import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import type { Tab, TabHandle, TabId, TabsProps } from "./tabs-types";
import { Icon, IconSize } from "../../components";
import { isFunction } from "../../utils";

const Tabs = forwardRef<TabHandle, TabsProps>(
  ({ tabs, defaultSelectedTabId = tabs[0].id, wrapperClassName = "" }, ref) => {
    const [activeTabId, setActiveTabId] = useState(defaultSelectedTabId);

    useImperativeHandle(ref, () => ({
      changeTab(tabId: TabId) {
        setActiveTabId(tabId);
      },
    }));

    useEffect(() => {
      if (defaultSelectedTabId) {
        setActiveTabId(defaultSelectedTabId);
      }
    }, [defaultSelectedTabId]);

    return (
      <>
        <ul
          className={`tw-flex tw-w-full tw-border-b tw-border-gray-700 ${wrapperClassName}`}
          data-testid="tabs-container"
        >
          {tabs.map((tab: Tab) => (
            <li
              key={`tab_${tab.id}`}
              className={`tw-px-4 tw-py-2.5 tw-text-sm tw-text-white tw-cursor-pointer tw-flex tw-gap-2 tw-items-center ${
                activeTabId === tab.id
                  ? "tw-border-b-3 tw-border-b-blue-500 tw-font-semibold"
                  : "tw-font-normal"
              }`}
              onClick={() => {
                setActiveTabId(tab.id);
                isFunction(tab.onTabChange) && tab.onTabChange?.(tab.id);
              }}
              data-testid={`tab-${tab.id}`}
            >
              {tab.icon && (
                <span>
                  <Icon name={tab.icon} size={IconSize.sm} />
                </span>
              )}
              <span data-testid={`tab-${tab.title}-title`}>{tab.title}</span>
              {tab.titleSuffix && <span>{tab.titleSuffix}</span>}
            </li>
          ))}
        </ul>
        <div className="tw-min-h-full">
          {tabs.find((tab) => tab.id === activeTabId)?.content}
        </div>
      </>
    );
  }
);

export default Tabs;
