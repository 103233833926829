import type { Dispatch, SetStateAction } from "react";
import type { FormikErrors, FormikValues } from "formik";

import { AWSIconsList, IconsList } from "../icons";

import type {
  AppStack,
  AppStackMetadata,
  Branch,
  CloudProvider,
  Repository,
  TargetComputeMetadata,
} from "../../apis/appcd";

export interface StepContentProps {
  formValues: FormikValues;
  appStackCreationMethod: AppStackCreationMethod;
  appStackCreationFlow: AppStackCreationFlow;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<AppStackDetailsForm>>;
  errors: FormikErrors<AppStackDetailsForm>;
  activeStepId: string;
  onPageClose: () => void;
  repositoriesList: Repository[];
  isStepSubmitAttempted: boolean;
  appStackMeta: AppStackMetadata;
  isSubmitting?: boolean;
  isPolicySelectionInitialRenderComplete: boolean;
  repoOffboardingWarningType?: RepoOffboardingWarningType | null;
  appStackDetails: AppStack;
  setIsPolicySelectionInitialRenderComplete: Dispatch<SetStateAction<boolean>>;
}

export interface AppStackCreationCloseConfirmDialogProps {
  closeConfirmationDialog: boolean;
  appStackCreationMethod: AppStackCreationMethod;
  appStackCreationFlow: AppStackCreationFlow;
  existingAppStackName: string;
  setCloseConfirmationDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface TargetComputeSelectionProps {
  onClose: () => void;
  formValues: FormikValues;
  setFieldValue: (field: string, value: any) => void;
  appStackCreationMethod: AppStackCreationMethod;
  appStackMeta: AppStackMetadata;
  repoOffboardingWarningType?: RepoOffboardingWarningType | null;
  setIsPolicySelectionInitialRenderComplete: Dispatch<SetStateAction<boolean>>;
  setShouldPolicySelectionReset: Dispatch<SetStateAction<boolean>>;
}

export interface DeploymentFileSelectionProps {
  onClose: () => void;
  formValues: FormikValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<AppStackDetailsForm>>;
  errors: FormikErrors<AppStackDetailsForm>;
}

export interface RepoSelectionProps {
  onClose: () => void;
  repositoriesList: Repository[] | null;
  formValues: FormikValues;
  errors: FormikErrors<AppStackDetailsForm>;
  isStepSubmitAttempted: boolean;
  setFieldValue: (field: string, value: any) => void;
  appStackCreationMethod: AppStackCreationMethod;
  isSubmitting?: boolean;
  repoOffboardingWarningType?: RepoOffboardingWarningType | null;
  appStackDetails: AppStack;
}

export interface TargetIacSelectionProps {
  onClose?: () => void;
  formValues: FormikValues;
  appStackCreationMethod: AppStackCreationMethod;
  repoOffboardingWarningType?: RepoOffboardingWarningType | null;
}

export interface PoliciesSelectionProps {
  onClose: () => void;
  formValues: FormikValues;
  appStackCreationMethod: AppStackCreationMethod;
  isPolicySelectionInitialRenderComplete: boolean;
  repoOffboardingWarningType?: RepoOffboardingWarningType | null;
  shouldPolicySelectionReset: boolean;
  setFieldValue: (field: string, value: any) => void;
  setIsPolicySelectionInitialRenderComplete: Dispatch<SetStateAction<boolean>>;
  setShouldPolicySelectionReset: Dispatch<SetStateAction<boolean>>;
}

export interface GeneralDetailsProps {
  onClose: () => void;
  formValues: FormikValues;
  appStackCreationMethod: AppStackCreationMethod;
  appStackCreationFlow: AppStackCreationFlow;
  repositoriesList: Repository[];
  repoOffboardingWarningType?: RepoOffboardingWarningType | null;
}

export interface FooterProps {
  disableBack?: boolean;
  nextButtonText?: string;
  disableNext?: boolean;
  showBackButtonIcon?: boolean;
  backButtonText?: string;
  hideBackButton?: boolean;
  showLeftButton?: boolean;
  leftButtonText?: string;
  isSubmitting?: boolean;
  onNext: () => void;
  onBack: () => void;
  onLeftButtonClick?: () => void;
}

export interface RepoDetailsFormProps {
  activeRepositoryDetails: Repository;
  activeRepositoryId: string;
  branchesList: {
    [key: string]: Branch[];
  };
  appStackCreationMethod: AppStackCreationMethod;
  formValues: FormikValues;
}

export interface RepoListProps {
  visibleRepoList: Repository[];
  activeRepositoryId: string;
  repositoriesList: Repository[];
  filteredRepositoriesList: Repository[];
  activePaginationPage: number;
  searchValue: string;
  repositoryTypeFilter: string[];
  recordPerPageCount: number;
  formValues: FormikValues;
  errors: FormikErrors<AppStackDetailsForm>;
  isStepSubmitAttempted: boolean;
  isRepoSelectionDisabled: boolean;
  appStackCreationMethod: AppStackCreationMethod;
  appStackDetails: AppStack;
  onRepositoryClick: (repositoryId: string) => void;
  setActivePaginationPage: (page: number) => void;
  setRecordPerPageCount: (count: number) => void;
}

export interface RepoTableFiltersProps {
  setSearchValue: Dispatch<SetStateAction<string>>;
  setRepositoryTypeFilter: Dispatch<SetStateAction<string[]>>;
}

export interface ContentHeaderProps {
  title: string;
  subtitle?: string;
  tagContent?: string;
  repoOffboardingWarningType?: RepoOffboardingWarningType | null;
  onClose?: () => void;
}

export enum RepositoryType {
  PUBLIC = "Public",
  PRIVATE = "Private",
}

export const emptyComponent = {
  name: "",
  analysisPath: "",
  language: "",
  containerImageReference: "",
  containerPath: "",
  branch: "",
};

export type AppStackDetailsForm = {
  name: string;
  description: string;
  labels: string[];
  coreConfig: {
    targetCompute: string;
    targetIac: string[];
    provider: string;
    policySettings: {
      policies: string[];
      frameworks: string[];
      categories: string[];
      providers: string[];
    };
  };
  repositories: {
    [key: string]: {
      isSelected: boolean;
      provider: string;
      components: (typeof emptyComponent)[];
    };
  };
  iacFile: any;
  iacFileSelect: boolean;
  policyFilterProviders: string[];
};

export enum CloudProviderServiceDisplayName {
  "aws" = "Amazon Web Services",
  "azure" = "Azure Services",
  "gcp" = "GCP Services",
  "k8s" = "Kubernetes Services",
}

export enum TargetIacIconsMap {
  "Terraform" = IconsList.TERRAFORM,
  "Helm" = IconsList.HELM,
  "CloudFormation" = AWSIconsList.cloud_formation,
}

export enum PolicyProvidersIconsMap {
  "aws" = AWSIconsList.aws,
  "azure" = AWSIconsList.azure,
  "k8s" = AWSIconsList.azurerm_kubernetes_cluster,
}

export type TargetComputeMap = {
  [K in CloudProvider]: TargetComputeMetadata[];
};

export enum AppStackCreationFlow {
  "FROM_REPO" = "FROM_REPO", // when appstack is created from a repository source code
  "FROM_SCRATCH" = "FROM_SCRATCH", // when appstack is created from scratch
  "FROM_DEPLOYMENT_FILES" = "FROM_DEPLOYMENT_FILES", // when appstack is created from deployment files
}

export enum AppStackCreationMethod {
  "NEW" = "NEW", // new appstack creation flow
  "VERSION_CREATION" = "VERSION_CREATION", // appstack version creation flow
  "DUPLICATE_APPSTACK" = "DUPLICATE_APPSTACK", // appstack version creation flow
}

export enum RepoOffboardingWarningType {
  "VERSION_CREATION" = "VERSION_CREATION",
  "DUPLICATE_APPSTACK" = "DUPLICATE_APPSTACK",
}
