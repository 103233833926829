export interface SpinnerProps {
	className?: string;
	dataTestId?: string;
	type?: keyof typeof SpinnerType;
}

export enum SpinnerType {
	BARS = "BARS",
	RING = "RING",
}
