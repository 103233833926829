/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Indicator = {
    LIBRARY_HIBERNATE: 'LIBRARY:HIBERNATE',
    LIBRARY_JPA: 'LIBRARY:JPA',
    LIBRARY_JAX_RS: 'LIBRARY:JAX_RS',
    LIBRARY_SPRING: 'LIBRARY:SPRING',
    LIBRARY_SPRING_KAFKA: 'LIBRARY:SPRING:KAFKA',
    LIBRARY_SPRING_REDIS: 'LIBRARY:SPRING:REDIS',
    LIBRARY_JDBC: 'LIBRARY:JDBC',
    LIBRARY_AWS_JAVA_SDK: 'LIBRARY:AWS_JAVA_SDK',
    LIBRARY_AZURE_JAVA_SDK: 'LIBRARY:AZURE_JAVA_SDK',
    LIBRARY_AZURE_JAVA_RESOURCEMANAGER: 'LIBRARY:AZURE_JAVA_RESOURCEMANAGER',
    LIBRARY_BOTO_3: 'LIBRARY:BOTO_3',
    LIBRARY_FAST_API: 'LIBRARY:FAST_API',
    LIBRARY_FLASK: 'LIBRARY:FLASK',
    LIBRARY_SQLALCHEMY: 'LIBRARY:SQLALCHEMY',
    DATABASE_ELASTICSEARCH: 'DATABASE:ELASTICSEARCH',
    CACHE_REDIS: 'CACHE:REDIS',
    CACHE_MEMCACHED: 'CACHE:MEMCACHED',
    DATABASE: 'DATABASE',
    SERVICE_EGRESS: 'SERVICE:EGRESS',
    SERVICE_INGRESS: 'SERVICE:INGRESS',
    SERVICE_GRPC: 'SERVICE:GRPC',
    EVENTS_KAFKA: 'EVENTS:KAFKA',
    EVENTS_AMQP_RABBITMQ: 'EVENTS:AMQP:RABBITMQ',
    EVENTS_AMQP: 'EVENTS:AMQP',
    DATABASE_MSSQL: 'DATABASE:MSSQL',
    DATABASE_MYSQL: 'DATABASE:MYSQL',
    DATABASE_ORACLE: 'DATABASE:ORACLE',
    DATABASE_POSTGRES: 'DATABASE:POSTGRES',
    DATABASE_SQLITE: 'DATABASE:SQLITE',
    DATABASE_MONGODB: 'DATABASE:MONGODB',
    CLOUD_AWS_ACCESSANALYZER: 'CLOUD:AWS:ACCESSANALYZER',
    CLOUD_AWS_ACCOUNT: 'CLOUD:AWS:ACCOUNT',
    CLOUD_AWS_ACM: 'CLOUD:AWS:ACM',
    CLOUD_AWS_ACM_PCA: 'CLOUD:AWS:ACM_PCA',
    CLOUD_AWS_ALEXAFORBUSINESS: 'CLOUD:AWS:ALEXAFORBUSINESS',
    CLOUD_AWS_AMP: 'CLOUD:AWS:AMP',
    CLOUD_AWS_AMPLIFY: 'CLOUD:AWS:AMPLIFY',
    CLOUD_AWS_AMPLIFYBACKEND: 'CLOUD:AWS:AMPLIFYBACKEND',
    CLOUD_AWS_AMPLIFYUIBUILDER: 'CLOUD:AWS:AMPLIFYUIBUILDER',
    CLOUD_AWS_APIGATEWAY: 'CLOUD:AWS:APIGATEWAY',
    CLOUD_AWS_APIGATEWAYMANAGEMENTAPI: 'CLOUD:AWS:APIGATEWAYMANAGEMENTAPI',
    CLOUD_AWS_APIGATEWAYV2: 'CLOUD:AWS:APIGATEWAYV2',
    CLOUD_AWS_APPCONFIG: 'CLOUD:AWS:APPCONFIG',
    CLOUD_AWS_APPCONFIGDATA: 'CLOUD:AWS:APPCONFIGDATA',
    CLOUD_AWS_APPFABRIC: 'CLOUD:AWS:APPFABRIC',
    CLOUD_AWS_APPFLOW: 'CLOUD:AWS:APPFLOW',
    CLOUD_AWS_APPINTEGRATIONS: 'CLOUD:AWS:APPINTEGRATIONS',
    CLOUD_AWS_APPLICATION_AUTOSCALING: 'CLOUD:AWS:APPLICATION_AUTOSCALING',
    CLOUD_AWS_APPLICATION_INSIGHTS: 'CLOUD:AWS:APPLICATION_INSIGHTS',
    CLOUD_AWS_APPLICATIONCOSTPROFILER: 'CLOUD:AWS:APPLICATIONCOSTPROFILER',
    CLOUD_AWS_APPMESH: 'CLOUD:AWS:APPMESH',
    CLOUD_AWS_APPRUNNER: 'CLOUD:AWS:APPRUNNER',
    CLOUD_AWS_APPSTREAM: 'CLOUD:AWS:APPSTREAM',
    CLOUD_AWS_APPSYNC: 'CLOUD:AWS:APPSYNC',
    CLOUD_AWS_ARC_ZONAL_SHIFT: 'CLOUD:AWS:ARC_ZONAL_SHIFT',
    CLOUD_AWS_ATHENA: 'CLOUD:AWS:ATHENA',
    CLOUD_AWS_AUDITMANAGER: 'CLOUD:AWS:AUDITMANAGER',
    CLOUD_AWS_AUTOSCALING: 'CLOUD:AWS:AUTOSCALING',
    CLOUD_AWS_AUTOSCALING_PLANS: 'CLOUD:AWS:AUTOSCALING_PLANS',
    CLOUD_AWS_BACKUP: 'CLOUD:AWS:BACKUP',
    CLOUD_AWS_BACKUP_GATEWAY: 'CLOUD:AWS:BACKUP_GATEWAY',
    CLOUD_AWS_BACKUPSTORAGE: 'CLOUD:AWS:BACKUPSTORAGE',
    CLOUD_AWS_BATCH: 'CLOUD:AWS:BATCH',
    CLOUD_AWS_BEDROCK: 'CLOUD:AWS:BEDROCK',
    CLOUD_AWS_BEDROCK_RUNTIME: 'CLOUD:AWS:BEDROCK_RUNTIME',
    CLOUD_AWS_BILLINGCONDUCTOR: 'CLOUD:AWS:BILLINGCONDUCTOR',
    CLOUD_AWS_BRAKET: 'CLOUD:AWS:BRAKET',
    CLOUD_AWS_BUDGETS: 'CLOUD:AWS:BUDGETS',
    CLOUD_AWS_CE: 'CLOUD:AWS:CE',
    CLOUD_AWS_CHIME: 'CLOUD:AWS:CHIME',
    CLOUD_AWS_CHIME_SDK_IDENTITY: 'CLOUD:AWS:CHIME_SDK_IDENTITY',
    CLOUD_AWS_CHIME_SDK_MEDIA_PIPELINES: 'CLOUD:AWS:CHIME_SDK_MEDIA_PIPELINES',
    CLOUD_AWS_CHIME_SDK_MEETINGS: 'CLOUD:AWS:CHIME_SDK_MEETINGS',
    CLOUD_AWS_CHIME_SDK_MESSAGING: 'CLOUD:AWS:CHIME_SDK_MESSAGING',
    CLOUD_AWS_CHIME_SDK_VOICE: 'CLOUD:AWS:CHIME_SDK_VOICE',
    CLOUD_AWS_CLEANROOMS: 'CLOUD:AWS:CLEANROOMS',
    CLOUD_AWS_CLOUD9: 'CLOUD:AWS:CLOUD9',
    CLOUD_AWS_CLOUDCONTROL: 'CLOUD:AWS:CLOUDCONTROL',
    CLOUD_AWS_CLOUDDIRECTORY: 'CLOUD:AWS:CLOUDDIRECTORY',
    CLOUD_AWS_CLOUDFORMATION: 'CLOUD:AWS:CLOUDFORMATION',
    CLOUD_AWS_CLOUDFRONT: 'CLOUD:AWS:CLOUDFRONT',
    CLOUD_AWS_CLOUDHSM: 'CLOUD:AWS:CLOUDHSM',
    CLOUD_AWS_CLOUDHSMV2: 'CLOUD:AWS:CLOUDHSMV2',
    CLOUD_AWS_CLOUDSEARCH: 'CLOUD:AWS:CLOUDSEARCH',
    CLOUD_AWS_CLOUDSEARCHDOMAIN: 'CLOUD:AWS:CLOUDSEARCHDOMAIN',
    CLOUD_AWS_CLOUDTRAIL: 'CLOUD:AWS:CLOUDTRAIL',
    CLOUD_AWS_CLOUDTRAIL_DATA: 'CLOUD:AWS:CLOUDTRAIL_DATA',
    CLOUD_AWS_CLOUDWATCH: 'CLOUD:AWS:CLOUDWATCH',
    CLOUD_AWS_CODEARTIFACT: 'CLOUD:AWS:CODEARTIFACT',
    CLOUD_AWS_CODEBUILD: 'CLOUD:AWS:CODEBUILD',
    CLOUD_AWS_CODECATALYST: 'CLOUD:AWS:CODECATALYST',
    CLOUD_AWS_CODECOMMIT: 'CLOUD:AWS:CODECOMMIT',
    CLOUD_AWS_CODEDEPLOY: 'CLOUD:AWS:CODEDEPLOY',
    CLOUD_AWS_CODEGURU_REVIEWER: 'CLOUD:AWS:CODEGURU_REVIEWER',
    CLOUD_AWS_CODEGURU_SECURITY: 'CLOUD:AWS:CODEGURU_SECURITY',
    CLOUD_AWS_CODEGURUPROFILER: 'CLOUD:AWS:CODEGURUPROFILER',
    CLOUD_AWS_CODEPIPELINE: 'CLOUD:AWS:CODEPIPELINE',
    CLOUD_AWS_CODESTAR: 'CLOUD:AWS:CODESTAR',
    CLOUD_AWS_CODESTAR_CONNECTIONS: 'CLOUD:AWS:CODESTAR_CONNECTIONS',
    CLOUD_AWS_CODESTAR_NOTIFICATIONS: 'CLOUD:AWS:CODESTAR_NOTIFICATIONS',
    CLOUD_AWS_COGNITO_IDENTITY: 'CLOUD:AWS:COGNITO_IDENTITY',
    CLOUD_AWS_COGNITO_IDP: 'CLOUD:AWS:COGNITO_IDP',
    CLOUD_AWS_COGNITO_SYNC: 'CLOUD:AWS:COGNITO_SYNC',
    CLOUD_AWS_COMPREHEND: 'CLOUD:AWS:COMPREHEND',
    CLOUD_AWS_COMPREHENDMEDICAL: 'CLOUD:AWS:COMPREHENDMEDICAL',
    CLOUD_AWS_COMPUTE_OPTIMIZER: 'CLOUD:AWS:COMPUTE_OPTIMIZER',
    CLOUD_AWS_CONFIG: 'CLOUD:AWS:CONFIG',
    CLOUD_AWS_CONNECT: 'CLOUD:AWS:CONNECT',
    CLOUD_AWS_CONNECT_CONTACT_LENS: 'CLOUD:AWS:CONNECT_CONTACT_LENS',
    CLOUD_AWS_CONNECTCAMPAIGNS: 'CLOUD:AWS:CONNECTCAMPAIGNS',
    CLOUD_AWS_CONNECTCASES: 'CLOUD:AWS:CONNECTCASES',
    CLOUD_AWS_CONNECTPARTICIPANT: 'CLOUD:AWS:CONNECTPARTICIPANT',
    CLOUD_AWS_CONTROLTOWER: 'CLOUD:AWS:CONTROLTOWER',
    CLOUD_AWS_CUR: 'CLOUD:AWS:CUR',
    CLOUD_AWS_CUSTOMER_PROFILES: 'CLOUD:AWS:CUSTOMER_PROFILES',
    CLOUD_AWS_DATABREW: 'CLOUD:AWS:DATABREW',
    CLOUD_AWS_DATAEXCHANGE: 'CLOUD:AWS:DATAEXCHANGE',
    CLOUD_AWS_DATAPIPELINE: 'CLOUD:AWS:DATAPIPELINE',
    CLOUD_AWS_DATASYNC: 'CLOUD:AWS:DATASYNC',
    CLOUD_AWS_DATAZONE: 'CLOUD:AWS:DATAZONE',
    CLOUD_AWS_DAX: 'CLOUD:AWS:DAX',
    CLOUD_AWS_DETECTIVE: 'CLOUD:AWS:DETECTIVE',
    CLOUD_AWS_DEVICEFARM: 'CLOUD:AWS:DEVICEFARM',
    CLOUD_AWS_DEVOPS_GURU: 'CLOUD:AWS:DEVOPS_GURU',
    CLOUD_AWS_DIRECTCONNECT: 'CLOUD:AWS:DIRECTCONNECT',
    CLOUD_AWS_DISCOVERY: 'CLOUD:AWS:DISCOVERY',
    CLOUD_AWS_DLM: 'CLOUD:AWS:DLM',
    CLOUD_AWS_DMS: 'CLOUD:AWS:DMS',
    CLOUD_AWS_DOCDB: 'CLOUD:AWS:DOCDB',
    CLOUD_AWS_DOCDB_ELASTIC: 'CLOUD:AWS:DOCDB_ELASTIC',
    CLOUD_AWS_DRS: 'CLOUD:AWS:DRS',
    CLOUD_AWS_DS: 'CLOUD:AWS:DS',
    CLOUD_AWS_DYNAMODB: 'CLOUD:AWS:DYNAMODB',
    CLOUD_AWS_DYNAMODBSTREAMS: 'CLOUD:AWS:DYNAMODBSTREAMS',
    CLOUD_AWS_EBS: 'CLOUD:AWS:EBS',
    CLOUD_AWS_EC2: 'CLOUD:AWS:EC2',
    CLOUD_AWS_EC2_INSTANCE_CONNECT: 'CLOUD:AWS:EC2_INSTANCE_CONNECT',
    CLOUD_AWS_ECR: 'CLOUD:AWS:ECR',
    CLOUD_AWS_ECR_PUBLIC: 'CLOUD:AWS:ECR_PUBLIC',
    CLOUD_AWS_ECS: 'CLOUD:AWS:ECS',
    CLOUD_AWS_EFS: 'CLOUD:AWS:EFS',
    CLOUD_AWS_EKS: 'CLOUD:AWS:EKS',
    CLOUD_AWS_ELASTIC_INFERENCE: 'CLOUD:AWS:ELASTIC_INFERENCE',
    CLOUD_AWS_ELASTICACHE: 'CLOUD:AWS:ELASTICACHE',
    CLOUD_AWS_ELASTICBEANSTALK: 'CLOUD:AWS:ELASTICBEANSTALK',
    CLOUD_AWS_ELASTICTRANSCODER: 'CLOUD:AWS:ELASTICTRANSCODER',
    CLOUD_AWS_ELB: 'CLOUD:AWS:ELB',
    CLOUD_AWS_ELBV2: 'CLOUD:AWS:ELBV2',
    CLOUD_AWS_EMR: 'CLOUD:AWS:EMR',
    CLOUD_AWS_EMR_CONTAINERS: 'CLOUD:AWS:EMR_CONTAINERS',
    CLOUD_AWS_EMR_SERVERLESS: 'CLOUD:AWS:EMR_SERVERLESS',
    CLOUD_AWS_ENTITYRESOLUTION: 'CLOUD:AWS:ENTITYRESOLUTION',
    CLOUD_AWS_ES: 'CLOUD:AWS:ES',
    CLOUD_AWS_EVENTBRIDGE: 'CLOUD:AWS:EVENTBRIDGE',
    CLOUD_AWS_EVIDENTLY: 'CLOUD:AWS:EVIDENTLY',
    CLOUD_AWS_FINSPACE: 'CLOUD:AWS:FINSPACE',
    CLOUD_AWS_FINSPACE_DATA: 'CLOUD:AWS:FINSPACE_DATA',
    CLOUD_AWS_FIREHOSE: 'CLOUD:AWS:FIREHOSE',
    CLOUD_AWS_FIS: 'CLOUD:AWS:FIS',
    CLOUD_AWS_FMS: 'CLOUD:AWS:FMS',
    CLOUD_AWS_FORECAST: 'CLOUD:AWS:FORECAST',
    CLOUD_AWS_FORECASTQUERY: 'CLOUD:AWS:FORECASTQUERY',
    CLOUD_AWS_FRAUDDETECTOR: 'CLOUD:AWS:FRAUDDETECTOR',
    CLOUD_AWS_FSX: 'CLOUD:AWS:FSX',
    CLOUD_AWS_GAMELIFT: 'CLOUD:AWS:GAMELIFT',
    CLOUD_AWS_GLACIER: 'CLOUD:AWS:GLACIER',
    CLOUD_AWS_GLOBALACCELERATOR: 'CLOUD:AWS:GLOBALACCELERATOR',
    CLOUD_AWS_GLUE: 'CLOUD:AWS:GLUE',
    CLOUD_AWS_GRAFANA: 'CLOUD:AWS:GRAFANA',
    CLOUD_AWS_GREENGRASS: 'CLOUD:AWS:GREENGRASS',
    CLOUD_AWS_GREENGRASSV2: 'CLOUD:AWS:GREENGRASSV2',
    CLOUD_AWS_GROUNDSTATION: 'CLOUD:AWS:GROUNDSTATION',
    CLOUD_AWS_GUARDDUTY: 'CLOUD:AWS:GUARDDUTY',
    CLOUD_AWS_HEALTH: 'CLOUD:AWS:HEALTH',
    CLOUD_AWS_HEALTHLAKE: 'CLOUD:AWS:HEALTHLAKE',
    CLOUD_AWS_HONEYCODE: 'CLOUD:AWS:HONEYCODE',
    CLOUD_AWS_IAM: 'CLOUD:AWS:IAM',
    CLOUD_AWS_IDENTITYSTORE: 'CLOUD:AWS:IDENTITYSTORE',
    CLOUD_AWS_IMAGEBUILDER: 'CLOUD:AWS:IMAGEBUILDER',
    CLOUD_AWS_IMPORTEXPORT: 'CLOUD:AWS:IMPORTEXPORT',
    CLOUD_AWS_INSPECTOR: 'CLOUD:AWS:INSPECTOR',
    CLOUD_AWS_INSPECTOR2: 'CLOUD:AWS:INSPECTOR2',
    CLOUD_AWS_INTERNETMONITOR: 'CLOUD:AWS:INTERNETMONITOR',
    CLOUD_AWS_IOT: 'CLOUD:AWS:IOT',
    CLOUD_AWS_IOT_DATA: 'CLOUD:AWS:IOT_DATA',
    CLOUD_AWS_IOT_JOBS_DATA: 'CLOUD:AWS:IOT_JOBS_DATA',
    CLOUD_AWS_IOT_ROBORUNNER: 'CLOUD:AWS:IOT_ROBORUNNER',
    CLOUD_AWS_IOT1CLICK_DEVICES: 'CLOUD:AWS:IOT1CLICK_DEVICES',
    CLOUD_AWS_IOT1CLICK_PROJECTS: 'CLOUD:AWS:IOT1CLICK_PROJECTS',
    CLOUD_AWS_IOTANALYTICS: 'CLOUD:AWS:IOTANALYTICS',
    CLOUD_AWS_IOTDEVICEADVISOR: 'CLOUD:AWS:IOTDEVICEADVISOR',
    CLOUD_AWS_IOTEVENTS: 'CLOUD:AWS:IOTEVENTS',
    CLOUD_AWS_IOTEVENTS_DATA: 'CLOUD:AWS:IOTEVENTS_DATA',
    CLOUD_AWS_IOTFLEETHUB: 'CLOUD:AWS:IOTFLEETHUB',
    CLOUD_AWS_IOTFLEETWISE: 'CLOUD:AWS:IOTFLEETWISE',
    CLOUD_AWS_IOTSECURETUNNELING: 'CLOUD:AWS:IOTSECURETUNNELING',
    CLOUD_AWS_IOTSITEWISE: 'CLOUD:AWS:IOTSITEWISE',
    CLOUD_AWS_IOTTHINGSGRAPH: 'CLOUD:AWS:IOTTHINGSGRAPH',
    CLOUD_AWS_IOTTWINMAKER: 'CLOUD:AWS:IOTTWINMAKER',
    CLOUD_AWS_IOTWIRELESS: 'CLOUD:AWS:IOTWIRELESS',
    CLOUD_AWS_IVS: 'CLOUD:AWS:IVS',
    CLOUD_AWS_IVS_REALTIME: 'CLOUD:AWS:IVS_REALTIME',
    CLOUD_AWS_IVSCHAT: 'CLOUD:AWS:IVSCHAT',
    CLOUD_AWS_KAFKA: 'CLOUD:AWS:KAFKA',
    CLOUD_AWS_KAFKACONNECT: 'CLOUD:AWS:KAFKACONNECT',
    CLOUD_AWS_KENDRA: 'CLOUD:AWS:KENDRA',
    CLOUD_AWS_KENDRA_RANKING: 'CLOUD:AWS:KENDRA_RANKING',
    CLOUD_AWS_KEYSPACES: 'CLOUD:AWS:KEYSPACES',
    CLOUD_AWS_KINESIS: 'CLOUD:AWS:KINESIS',
    CLOUD_AWS_KINESIS_VIDEO_ARCHIVED_MEDIA: 'CLOUD:AWS:KINESIS_VIDEO_ARCHIVED_MEDIA',
    CLOUD_AWS_KINESIS_VIDEO_MEDIA: 'CLOUD:AWS:KINESIS_VIDEO_MEDIA',
    CLOUD_AWS_KINESIS_VIDEO_SIGNALING: 'CLOUD:AWS:KINESIS_VIDEO_SIGNALING',
    CLOUD_AWS_KINESIS_VIDEO_WEBRTC_STORAGE: 'CLOUD:AWS:KINESIS_VIDEO_WEBRTC_STORAGE',
    CLOUD_AWS_KINESISANALYTICS: 'CLOUD:AWS:KINESISANALYTICS',
    CLOUD_AWS_KINESISANALYTICSV2: 'CLOUD:AWS:KINESISANALYTICSV2',
    CLOUD_AWS_KINESISVIDEO: 'CLOUD:AWS:KINESISVIDEO',
    CLOUD_AWS_KMS: 'CLOUD:AWS:KMS',
    CLOUD_AWS_LAKEFORMATION: 'CLOUD:AWS:LAKEFORMATION',
    CLOUD_AWS_LAMBDA: 'CLOUD:AWS:LAMBDA',
    CLOUD_AWS_LAMBDA_FUNCTION: 'CLOUD:AWS:LAMBDA:FUNCTION',
    CLOUD_AWS_LAMBDA_FUNCTION_TRIGGER: 'CLOUD:AWS:LAMBDA:FUNCTION:TRIGGER',
    CLOUD_AWS_LAMBDA_FUNCTION_TRIGGER_APIGATEWAY: 'CLOUD:AWS:LAMBDA:FUNCTION:TRIGGER_APIGATEWAY',
    CLOUD_AWS_LAMBDA_FUNCTION_TRIGGER_CLOUDWATCH_ALARM: 'CLOUD:AWS:LAMBDA:FUNCTION:TRIGGER_CLOUDWATCH_ALARM',
    CLOUD_AWS_LAMBDA_FUNCTION_TRIGGER_CLOUDWATCH_LOGS: 'CLOUD:AWS:LAMBDA:FUNCTION:TRIGGER_CLOUDWATCH_LOGS',
    CLOUD_AWS_LAMBDA_FUNCTION_TRIGGER_DYNAMODB: 'CLOUD:AWS:LAMBDA:FUNCTION:TRIGGER_DYNAMODB',
    CLOUD_AWS_LAMBDA_FUNCTION_TRIGGER_KINESIS: 'CLOUD:AWS:LAMBDA:FUNCTION:TRIGGER_KINESIS',
    CLOUD_AWS_LAMBDA_FUNCTION_TRIGGER_S3: 'CLOUD:AWS:LAMBDA:FUNCTION:TRIGGER_S3',
    CLOUD_AWS_LAMBDA_FUNCTION_TRIGGER_SNS: 'CLOUD:AWS:LAMBDA:FUNCTION:TRIGGER_SNS',
    CLOUD_AWS_LAMBDA_FUNCTION_TRIGGER_SQS: 'CLOUD:AWS:LAMBDA:FUNCTION:TRIGGER_SQS',
    CLOUD_AWS_LEX_MODELS: 'CLOUD:AWS:LEX_MODELS',
    CLOUD_AWS_LEX_RUNTIME: 'CLOUD:AWS:LEX_RUNTIME',
    CLOUD_AWS_LEXV2_MODELS: 'CLOUD:AWS:LEXV2_MODELS',
    CLOUD_AWS_LEXV2_RUNTIME: 'CLOUD:AWS:LEXV2_RUNTIME',
    CLOUD_AWS_LICENSE_MANAGER: 'CLOUD:AWS:LICENSE_MANAGER',
    CLOUD_AWS_LICENSE_MANAGER_LINUX_SUBSCRIPTIONS: 'CLOUD:AWS:LICENSE_MANAGER_LINUX_SUBSCRIPTIONS',
    CLOUD_AWS_LICENSE_MANAGER_USER_SUBSCRIPTIONS: 'CLOUD:AWS:LICENSE_MANAGER_USER_SUBSCRIPTIONS',
    CLOUD_AWS_LIGHTSAIL: 'CLOUD:AWS:LIGHTSAIL',
    CLOUD_AWS_LOCATION: 'CLOUD:AWS:LOCATION',
    CLOUD_AWS_LOGS: 'CLOUD:AWS:LOGS',
    CLOUD_AWS_LOOKOUTEQUIPMENT: 'CLOUD:AWS:LOOKOUTEQUIPMENT',
    CLOUD_AWS_LOOKOUTMETRICS: 'CLOUD:AWS:LOOKOUTMETRICS',
    CLOUD_AWS_LOOKOUTVISION: 'CLOUD:AWS:LOOKOUTVISION',
    CLOUD_AWS_M2: 'CLOUD:AWS:M2',
    CLOUD_AWS_MACHINELEARNING: 'CLOUD:AWS:MACHINELEARNING',
    CLOUD_AWS_MACIE: 'CLOUD:AWS:MACIE',
    CLOUD_AWS_MACIE2: 'CLOUD:AWS:MACIE2',
    CLOUD_AWS_MANAGEDBLOCKCHAIN: 'CLOUD:AWS:MANAGEDBLOCKCHAIN',
    CLOUD_AWS_MANAGEDBLOCKCHAIN_QUERY: 'CLOUD:AWS:MANAGEDBLOCKCHAIN_QUERY',
    CLOUD_AWS_MARKETPLACE_CATALOG: 'CLOUD:AWS:MARKETPLACE_CATALOG',
    CLOUD_AWS_MARKETPLACE_ENTITLEMENT: 'CLOUD:AWS:MARKETPLACE_ENTITLEMENT',
    CLOUD_AWS_MARKETPLACECOMMERCEANALYTICS: 'CLOUD:AWS:MARKETPLACECOMMERCEANALYTICS',
    CLOUD_AWS_MEDIACONNECT: 'CLOUD:AWS:MEDIACONNECT',
    CLOUD_AWS_MEDIACONVERT: 'CLOUD:AWS:MEDIACONVERT',
    CLOUD_AWS_MEDIALIVE: 'CLOUD:AWS:MEDIALIVE',
    CLOUD_AWS_MEDIAPACKAGE: 'CLOUD:AWS:MEDIAPACKAGE',
    CLOUD_AWS_MEDIAPACKAGE_VOD: 'CLOUD:AWS:MEDIAPACKAGE_VOD',
    CLOUD_AWS_MEDIAPACKAGEV2: 'CLOUD:AWS:MEDIAPACKAGEV2',
    CLOUD_AWS_MEDIASTORE: 'CLOUD:AWS:MEDIASTORE',
    CLOUD_AWS_MEDIASTORE_DATA: 'CLOUD:AWS:MEDIASTORE_DATA',
    CLOUD_AWS_MEDIATAILOR: 'CLOUD:AWS:MEDIATAILOR',
    CLOUD_AWS_MEDICAL_IMAGING: 'CLOUD:AWS:MEDICAL_IMAGING',
    CLOUD_AWS_MEMORYDB: 'CLOUD:AWS:MEMORYDB',
    CLOUD_AWS_METERINGMARKETPLACE: 'CLOUD:AWS:METERINGMARKETPLACE',
    CLOUD_AWS_MGH: 'CLOUD:AWS:MGH',
    CLOUD_AWS_MGN: 'CLOUD:AWS:MGN',
    CLOUD_AWS_MIGRATION_HUB_REFACTOR_SPACES: 'CLOUD:AWS:MIGRATION_HUB_REFACTOR_SPACES',
    CLOUD_AWS_MIGRATIONHUB_CONFIG: 'CLOUD:AWS:MIGRATIONHUB_CONFIG',
    CLOUD_AWS_MIGRATIONHUBORCHESTRATOR: 'CLOUD:AWS:MIGRATIONHUBORCHESTRATOR',
    CLOUD_AWS_MIGRATIONHUBSTRATEGY: 'CLOUD:AWS:MIGRATIONHUBSTRATEGY',
    CLOUD_AWS_MOBILE: 'CLOUD:AWS:MOBILE',
    CLOUD_AWS_MQ: 'CLOUD:AWS:MQ',
    CLOUD_AWS_MTURK: 'CLOUD:AWS:MTURK',
    CLOUD_AWS_MWAA: 'CLOUD:AWS:MWAA',
    CLOUD_AWS_NEPTUNE: 'CLOUD:AWS:NEPTUNE',
    CLOUD_AWS_NEPTUNEDATA: 'CLOUD:AWS:NEPTUNEDATA',
    CLOUD_AWS_NETWORK_FIREWALL: 'CLOUD:AWS:NETWORK_FIREWALL',
    CLOUD_AWS_NETWORKMANAGER: 'CLOUD:AWS:NETWORKMANAGER',
    CLOUD_AWS_NIMBLE: 'CLOUD:AWS:NIMBLE',
    CLOUD_AWS_OAM: 'CLOUD:AWS:OAM',
    CLOUD_AWS_OMICS: 'CLOUD:AWS:OMICS',
    CLOUD_AWS_OPENSEARCH: 'CLOUD:AWS:OPENSEARCH',
    CLOUD_AWS_OPENSEARCHSERVERLESS: 'CLOUD:AWS:OPENSEARCHSERVERLESS',
    CLOUD_AWS_OPSWORKS: 'CLOUD:AWS:OPSWORKS',
    CLOUD_AWS_OPSWORKSCM: 'CLOUD:AWS:OPSWORKSCM',
    CLOUD_AWS_ORGANIZATIONS: 'CLOUD:AWS:ORGANIZATIONS',
    CLOUD_AWS_OSIS: 'CLOUD:AWS:OSIS',
    CLOUD_AWS_OUTPOSTS: 'CLOUD:AWS:OUTPOSTS',
    CLOUD_AWS_PANORAMA: 'CLOUD:AWS:PANORAMA',
    CLOUD_AWS_PAYMENT_CRYPTOGRAPHY: 'CLOUD:AWS:PAYMENT_CRYPTOGRAPHY',
    CLOUD_AWS_PAYMENT_CRYPTOGRAPHY_DATA: 'CLOUD:AWS:PAYMENT_CRYPTOGRAPHY_DATA',
    CLOUD_AWS_PCA_CONNECTOR_AD: 'CLOUD:AWS:PCA_CONNECTOR_AD',
    CLOUD_AWS_PERSONALIZE: 'CLOUD:AWS:PERSONALIZE',
    CLOUD_AWS_PERSONALIZE_EVENTS: 'CLOUD:AWS:PERSONALIZE_EVENTS',
    CLOUD_AWS_PERSONALIZE_RUNTIME: 'CLOUD:AWS:PERSONALIZE_RUNTIME',
    CLOUD_AWS_PI: 'CLOUD:AWS:PI',
    CLOUD_AWS_PINPOINT: 'CLOUD:AWS:PINPOINT',
    CLOUD_AWS_PINPOINT_EMAIL: 'CLOUD:AWS:PINPOINT_EMAIL',
    CLOUD_AWS_PINPOINT_SMS_VOICE: 'CLOUD:AWS:PINPOINT_SMS_VOICE',
    CLOUD_AWS_PINPOINT_SMS_VOICE_V2: 'CLOUD:AWS:PINPOINT_SMS_VOICE_V2',
    CLOUD_AWS_PIPES: 'CLOUD:AWS:PIPES',
    CLOUD_AWS_POLLY: 'CLOUD:AWS:POLLY',
    CLOUD_AWS_PRICING: 'CLOUD:AWS:PRICING',
    CLOUD_AWS_PRIVATENETWORKS: 'CLOUD:AWS:PRIVATENETWORKS',
    CLOUD_AWS_PROTON: 'CLOUD:AWS:PROTON',
    CLOUD_AWS_QLDB: 'CLOUD:AWS:QLDB',
    CLOUD_AWS_QLDB_SESSION: 'CLOUD:AWS:QLDB_SESSION',
    CLOUD_AWS_QUICKSIGHT: 'CLOUD:AWS:QUICKSIGHT',
    CLOUD_AWS_RAM: 'CLOUD:AWS:RAM',
    CLOUD_AWS_RBIN: 'CLOUD:AWS:RBIN',
    CLOUD_AWS_RDS: 'CLOUD:AWS:RDS',
    CLOUD_AWS_RDS_DATA: 'CLOUD:AWS:RDS_DATA',
    CLOUD_AWS_REDSHIFT: 'CLOUD:AWS:REDSHIFT',
    CLOUD_AWS_REDSHIFT_DATA: 'CLOUD:AWS:REDSHIFT_DATA',
    CLOUD_AWS_REDSHIFT_SERVERLESS: 'CLOUD:AWS:REDSHIFT_SERVERLESS',
    CLOUD_AWS_REKOGNITION: 'CLOUD:AWS:REKOGNITION',
    CLOUD_AWS_RESILIENCEHUB: 'CLOUD:AWS:RESILIENCEHUB',
    CLOUD_AWS_RESOURCE_EXPLORER_2: 'CLOUD:AWS:RESOURCE_EXPLORER_2',
    CLOUD_AWS_RESOURCE_GROUPS: 'CLOUD:AWS:RESOURCE_GROUPS',
    CLOUD_AWS_RESOURCEGROUPSTAGGINGAPI: 'CLOUD:AWS:RESOURCEGROUPSTAGGINGAPI',
    CLOUD_AWS_ROBOMAKER: 'CLOUD:AWS:ROBOMAKER',
    CLOUD_AWS_ROLESANYWHERE: 'CLOUD:AWS:ROLESANYWHERE',
    CLOUD_AWS_ROUTE53: 'CLOUD:AWS:ROUTE53',
    CLOUD_AWS_ROUTE53_RECOVERY_CLUSTER: 'CLOUD:AWS:ROUTE53_RECOVERY_CLUSTER',
    CLOUD_AWS_ROUTE53_RECOVERY_CONTROL_CONFIG: 'CLOUD:AWS:ROUTE53_RECOVERY_CONTROL_CONFIG',
    CLOUD_AWS_ROUTE53_RECOVERY_READINESS: 'CLOUD:AWS:ROUTE53_RECOVERY_READINESS',
    CLOUD_AWS_ROUTE53DOMAINS: 'CLOUD:AWS:ROUTE53DOMAINS',
    CLOUD_AWS_ROUTE53RESOLVER: 'CLOUD:AWS:ROUTE53RESOLVER',
    CLOUD_AWS_RUM: 'CLOUD:AWS:RUM',
    CLOUD_AWS_S3: 'CLOUD:AWS:S3',
    CLOUD_AWS_S3CONTROL: 'CLOUD:AWS:S3CONTROL',
    CLOUD_AWS_S3OUTPOSTS: 'CLOUD:AWS:S3OUTPOSTS',
    CLOUD_AWS_SAGEMAKER: 'CLOUD:AWS:SAGEMAKER',
    CLOUD_AWS_SAGEMAKER_A2I_RUNTIME: 'CLOUD:AWS:SAGEMAKER_A2I_RUNTIME',
    CLOUD_AWS_SAGEMAKER_EDGE: 'CLOUD:AWS:SAGEMAKER_EDGE',
    CLOUD_AWS_SAGEMAKER_FEATURESTORE_RUNTIME: 'CLOUD:AWS:SAGEMAKER_FEATURESTORE_RUNTIME',
    CLOUD_AWS_SAGEMAKER_GEOSPATIAL: 'CLOUD:AWS:SAGEMAKER_GEOSPATIAL',
    CLOUD_AWS_SAGEMAKER_METRICS: 'CLOUD:AWS:SAGEMAKER_METRICS',
    CLOUD_AWS_SAGEMAKER_RUNTIME: 'CLOUD:AWS:SAGEMAKER_RUNTIME',
    CLOUD_AWS_SAVINGSPLANS: 'CLOUD:AWS:SAVINGSPLANS',
    CLOUD_AWS_SCHEDULER: 'CLOUD:AWS:SCHEDULER',
    CLOUD_AWS_SCHEMAS: 'CLOUD:AWS:SCHEMAS',
    CLOUD_AWS_SDB: 'CLOUD:AWS:SDB',
    CLOUD_AWS_SECRETSMANAGER: 'CLOUD:AWS:SECRETSMANAGER',
    CLOUD_AWS_SECURITYHUB: 'CLOUD:AWS:SECURITYHUB',
    CLOUD_AWS_SECURITYLAKE: 'CLOUD:AWS:SECURITYLAKE',
    CLOUD_AWS_SERVERLESSREPO: 'CLOUD:AWS:SERVERLESSREPO',
    CLOUD_AWS_SERVICE_QUOTAS: 'CLOUD:AWS:SERVICE_QUOTAS',
    CLOUD_AWS_SERVICECATALOG: 'CLOUD:AWS:SERVICECATALOG',
    CLOUD_AWS_SERVICECATALOG_APPREGISTRY: 'CLOUD:AWS:SERVICECATALOG_APPREGISTRY',
    CLOUD_AWS_SERVICEDISCOVERY: 'CLOUD:AWS:SERVICEDISCOVERY',
    CLOUD_AWS_SES: 'CLOUD:AWS:SES',
    CLOUD_AWS_SESV2: 'CLOUD:AWS:SESV2',
    CLOUD_AWS_SHIELD: 'CLOUD:AWS:SHIELD',
    CLOUD_AWS_SIGNER: 'CLOUD:AWS:SIGNER',
    CLOUD_AWS_SIMSPACEWEAVER: 'CLOUD:AWS:SIMSPACEWEAVER',
    CLOUD_AWS_SMS: 'CLOUD:AWS:SMS',
    CLOUD_AWS_SMS_VOICE: 'CLOUD:AWS:SMS_VOICE',
    CLOUD_AWS_SNOW_DEVICE_MANAGEMENT: 'CLOUD:AWS:SNOW_DEVICE_MANAGEMENT',
    CLOUD_AWS_SNOWBALL: 'CLOUD:AWS:SNOWBALL',
    CLOUD_AWS_SNS: 'CLOUD:AWS:SNS',
    CLOUD_AWS_SQS: 'CLOUD:AWS:SQS',
    CLOUD_AWS_SSM: 'CLOUD:AWS:SSM',
    CLOUD_AWS_SSM_CONTACTS: 'CLOUD:AWS:SSM_CONTACTS',
    CLOUD_AWS_SSM_INCIDENTS: 'CLOUD:AWS:SSM_INCIDENTS',
    CLOUD_AWS_SSM_SAP: 'CLOUD:AWS:SSM_SAP',
    CLOUD_AWS_SSO: 'CLOUD:AWS:SSO',
    CLOUD_AWS_SSO_ADMIN: 'CLOUD:AWS:SSO_ADMIN',
    CLOUD_AWS_SSO_OIDC: 'CLOUD:AWS:SSO_OIDC',
    CLOUD_AWS_STEPFUNCTIONS: 'CLOUD:AWS:STEPFUNCTIONS',
    CLOUD_AWS_STORAGEGATEWAY: 'CLOUD:AWS:STORAGEGATEWAY',
    CLOUD_AWS_STS: 'CLOUD:AWS:STS',
    CLOUD_AWS_SUPPORT: 'CLOUD:AWS:SUPPORT',
    CLOUD_AWS_SUPPORT_APP: 'CLOUD:AWS:SUPPORT_APP',
    CLOUD_AWS_SWF: 'CLOUD:AWS:SWF',
    CLOUD_AWS_SYNTHETICS: 'CLOUD:AWS:SYNTHETICS',
    CLOUD_AWS_TEXTRACT: 'CLOUD:AWS:TEXTRACT',
    CLOUD_AWS_TIMESTREAM_QUERY: 'CLOUD:AWS:TIMESTREAM_QUERY',
    CLOUD_AWS_TIMESTREAM_WRITE: 'CLOUD:AWS:TIMESTREAM_WRITE',
    CLOUD_AWS_TNB: 'CLOUD:AWS:TNB',
    CLOUD_AWS_TRANSCRIBE: 'CLOUD:AWS:TRANSCRIBE',
    CLOUD_AWS_TRANSFER: 'CLOUD:AWS:TRANSFER',
    CLOUD_AWS_TRANSLATE: 'CLOUD:AWS:TRANSLATE',
    CLOUD_AWS_VERIFIEDPERMISSIONS: 'CLOUD:AWS:VERIFIEDPERMISSIONS',
    CLOUD_AWS_VOICE_ID: 'CLOUD:AWS:VOICE_ID',
    CLOUD_AWS_VPC_LATTICE: 'CLOUD:AWS:VPC_LATTICE',
    CLOUD_AWS_WAF: 'CLOUD:AWS:WAF',
    CLOUD_AWS_WAF_REGIONAL: 'CLOUD:AWS:WAF_REGIONAL',
    CLOUD_AWS_WAFV2: 'CLOUD:AWS:WAFV2',
    CLOUD_AWS_WELLARCHITECTED: 'CLOUD:AWS:WELLARCHITECTED',
    CLOUD_AWS_WISDOM: 'CLOUD:AWS:WISDOM',
    CLOUD_AWS_WORKDOCS: 'CLOUD:AWS:WORKDOCS',
    CLOUD_AWS_WORKLINK: 'CLOUD:AWS:WORKLINK',
    CLOUD_AWS_WORKMAIL: 'CLOUD:AWS:WORKMAIL',
    CLOUD_AWS_WORKMAILMESSAGEFLOW: 'CLOUD:AWS:WORKMAILMESSAGEFLOW',
    CLOUD_AWS_WORKSPACES: 'CLOUD:AWS:WORKSPACES',
    CLOUD_AWS_WORKSPACES_WEB: 'CLOUD:AWS:WORKSPACES_WEB',
    CLOUD_AWS_XRAY: 'CLOUD:AWS:XRAY',
    CLOUD_AZURE_ACTIVE_DIRECTORY_EXTERNAL_IDENTITIES: 'CLOUD:AZURE:ACTIVE_DIRECTORY_EXTERNAL_IDENTITIES',
    CLOUD_AZURE_ADVISOR: 'CLOUD:AZURE:ADVISOR',
    CLOUD_AZURE_AI_ANOMALY_DETECTOR: 'CLOUD:AZURE:AI_ANOMALY_DETECTOR',
    CLOUD_AZURE_AI_BOT_SERVICE: 'CLOUD:AZURE:AI_BOT_SERVICE',
    CLOUD_AZURE_AI_CONTENT_SAFETY: 'CLOUD:AZURE:AI_CONTENT_SAFETY',
    CLOUD_AZURE_AI_CUSTOM_VISION: 'CLOUD:AZURE:AI_CUSTOM_VISION',
    CLOUD_AZURE_AI_DOCUMENT_INTELLIGENCE: 'CLOUD:AZURE:AI_DOCUMENT_INTELLIGENCE',
    CLOUD_AZURE_AI_IMMERSIVE_READER: 'CLOUD:AZURE:AI_IMMERSIVE_READER',
    CLOUD_AZURE_AI_LANGUAGE: 'CLOUD:AZURE:AI_LANGUAGE',
    CLOUD_AZURE_AI_METRICS_ADVISOR: 'CLOUD:AZURE:AI_METRICS_ADVISOR',
    CLOUD_AZURE_AI_PERSONALIZER: 'CLOUD:AZURE:AI_PERSONALIZER',
    CLOUD_AZURE_AI_SEARCH: 'CLOUD:AZURE:AI_SEARCH',
    CLOUD_AZURE_AI_SERVICES: 'CLOUD:AZURE:AI_SERVICES',
    CLOUD_AZURE_AI_SERVICES_SPEECH: 'CLOUD:AZURE:AI_SERVICES:SPEECH',
    CLOUD_AZURE_AI_SERVICES_TEXT_ANALYTICS: 'CLOUD:AZURE:AI_SERVICES:TEXT_ANALYTICS',
    CLOUD_AZURE_AI_STUDIO: 'CLOUD:AZURE:AI_STUDIO',
    CLOUD_AZURE_AI_TRANSLATOR: 'CLOUD:AZURE:AI_TRANSLATOR',
    CLOUD_AZURE_AI_VIDEO_INDEXER: 'CLOUD:AZURE:AI_VIDEO_INDEXER',
    CLOUD_AZURE_ANALYSIS_SERVICES: 'CLOUD:AZURE:ANALYSIS_SERVICES',
    CLOUD_AZURE_API_MANAGEMENT: 'CLOUD:AZURE:API_MANAGEMENT',
    CLOUD_AZURE_APPLICATION_GATEWAY: 'CLOUD:AZURE:APPLICATION_GATEWAY',
    CLOUD_AZURE_APP_CENTER: 'CLOUD:AZURE:APP_CENTER',
    CLOUD_AZURE_APP_CONFIGURATION: 'CLOUD:AZURE:APP_CONFIGURATION',
    CLOUD_AZURE_APP_SERVICE: 'CLOUD:AZURE:APP_SERVICE',
    CLOUD_AZURE_ARC: 'CLOUD:AZURE:ARC',
    CLOUD_AZURE_ARCHIVE_STORAGE: 'CLOUD:AZURE:ARCHIVE_STORAGE',
    CLOUD_AZURE_ARTIFACTS: 'CLOUD:AZURE:ARTIFACTS',
    CLOUD_AZURE_AUTOMANAGE: 'CLOUD:AZURE:AUTOMANAGE',
    CLOUD_AZURE_AUTOMATION: 'CLOUD:AZURE:AUTOMATION',
    CLOUD_AZURE_AVERE_VFXT_FOR_AZURE: 'CLOUD:AZURE:AVERE_VFXT_FOR_AZURE',
    CLOUD_AZURE_BACKUP: 'CLOUD:AZURE:BACKUP',
    CLOUD_AZURE_BASTION: 'CLOUD:AZURE:BASTION',
    CLOUD_AZURE_BATCH: 'CLOUD:AZURE:BATCH',
    CLOUD_AZURE_BLOB_STORAGE: 'CLOUD:AZURE:BLOB_STORAGE',
    CLOUD_AZURE_BLUEPRINTS: 'CLOUD:AZURE:BLUEPRINTS',
    CLOUD_AZURE_BOARDS: 'CLOUD:AZURE:BOARDS',
    CLOUD_AZURE_CACHE_FOR_REDIS: 'CLOUD:AZURE:CACHE_FOR_REDIS',
    CLOUD_AZURE_CHAOS_STUDIO: 'CLOUD:AZURE:CHAOS_STUDIO',
    CLOUD_AZURE_CLOUD_SERVICES: 'CLOUD:AZURE:CLOUD_SERVICES',
    CLOUD_AZURE_CLOUD_SHELL: 'CLOUD:AZURE:CLOUD_SHELL',
    CLOUD_AZURE_COMMUNICATIONS_GATEWAY: 'CLOUD:AZURE:COMMUNICATIONS_GATEWAY',
    CLOUD_AZURE_COMMUNICATION_SERVICES: 'CLOUD:AZURE:COMMUNICATION_SERVICES',
    CLOUD_AZURE_COMMUNICATION_SERVICES_CALL_AUTOMATION: 'CLOUD:AZURE:COMMUNICATION_SERVICES:CALL_AUTOMATION',
    CLOUD_AZURE_COMMUNICATION_SERVICES_CHAT: 'CLOUD:AZURE:COMMUNICATION_SERVICES:CHAT',
    CLOUD_AZURE_COMMUNICATION_SERVICES_EMAIL: 'CLOUD:AZURE:COMMUNICATION_SERVICES:EMAIL',
    CLOUD_AZURE_COMMUNICATION_SERVICES_IDENTITY: 'CLOUD:AZURE:COMMUNICATION_SERVICES:IDENTITY',
    CLOUD_AZURE_COMMUNICATION_SERVICES_PHONE_NUMBERS: 'CLOUD:AZURE:COMMUNICATION_SERVICES:PHONE_NUMBERS',
    CLOUD_AZURE_COMMUNICATION_SERVICES_ROOMS: 'CLOUD:AZURE:COMMUNICATION_SERVICES:ROOMS',
    CLOUD_AZURE_COMMUNICATION_SERVICES_SMS: 'CLOUD:AZURE:COMMUNICATION_SERVICES:SMS',
    CLOUD_AZURE_CONFIDENTIAL_LEDGER: 'CLOUD:AZURE:CONFIDENTIAL_LEDGER',
    CLOUD_AZURE_CONTAINER_APPS: 'CLOUD:AZURE:CONTAINER_APPS',
    CLOUD_AZURE_CONTAINER_INSTANCES: 'CLOUD:AZURE:CONTAINER_INSTANCES',
    CLOUD_AZURE_CONTAINER_REGISTRY: 'CLOUD:AZURE:CONTAINER_REGISTRY',
    CLOUD_AZURE_CONTAINER_STORAGE: 'CLOUD:AZURE:CONTAINER_STORAGE',
    CLOUD_AZURE_CONTENT_DELIVERY_NETWORK: 'CLOUD:AZURE:CONTENT_DELIVERY_NETWORK',
    CLOUD_AZURE_CONTENT_PROTECTION: 'CLOUD:AZURE:CONTENT_PROTECTION',
    CLOUD_AZURE_COSMOS_DB: 'CLOUD:AZURE:COSMOS_DB',
    CLOUD_AZURE_CYCLECLOUD: 'CLOUD:AZURE:CYCLECLOUD',
    CLOUD_AZURE_DATABASE_FOR_MARIADB: 'CLOUD:AZURE:DATABASE_FOR_MARIADB',
    CLOUD_AZURE_DATABASE_FOR_MYSQL: 'CLOUD:AZURE:DATABASE_FOR_MYSQL',
    CLOUD_AZURE_DATABASE_FOR_POSTGRESQL: 'CLOUD:AZURE:DATABASE_FOR_POSTGRESQL',
    CLOUD_AZURE_DATABASE_MIGRATION_SERVICE: 'CLOUD:AZURE:DATABASE_MIGRATION_SERVICE',
    CLOUD_AZURE_DATABRICKS: 'CLOUD:AZURE:DATABRICKS',
    CLOUD_AZURE_DATA_BOX: 'CLOUD:AZURE:DATA_BOX',
    CLOUD_AZURE_DATA_CATALOG: 'CLOUD:AZURE:DATA_CATALOG',
    CLOUD_AZURE_DATA_EXPLORER: 'CLOUD:AZURE:DATA_EXPLORER',
    CLOUD_AZURE_DATA_FACTORY: 'CLOUD:AZURE:DATA_FACTORY',
    CLOUD_AZURE_DATA_LAKE_ANALYTICS: 'CLOUD:AZURE:DATA_LAKE_ANALYTICS',
    CLOUD_AZURE_DATA_LAKE_STORAGE: 'CLOUD:AZURE:DATA_LAKE_STORAGE',
    CLOUD_AZURE_DATA_LAKE_STORAGE_GEN1: 'CLOUD:AZURE:DATA_LAKE_STORAGE_GEN1',
    CLOUD_AZURE_DATA_SCIENCE_VIRTUAL_MACHINES: 'CLOUD:AZURE:DATA_SCIENCE_VIRTUAL_MACHINES',
    CLOUD_AZURE_DATA_SHARE: 'CLOUD:AZURE:DATA_SHARE',
    CLOUD_AZURE_DDOS_PROTECTION: 'CLOUD:AZURE:DDOS_PROTECTION',
    CLOUD_AZURE_DEDICATED_HOST: 'CLOUD:AZURE:DEDICATED_HOST',
    CLOUD_AZURE_DEDICATED_HSM: 'CLOUD:AZURE:DEDICATED_HSM',
    CLOUD_AZURE_DEFENDER_EXTERNAL_ATTACK_SURFACE_MANAGEMENT: 'CLOUD:AZURE:DEFENDER_EXTERNAL_ATTACK_SURFACE_MANAGEMENT',
    CLOUD_AZURE_DEPLOYMENT_ENVIRONMENTS: 'CLOUD:AZURE:DEPLOYMENT_ENVIRONMENTS',
    CLOUD_AZURE_DEVOPS: 'CLOUD:AZURE:DEVOPS',
    CLOUD_AZURE_DEVOPS_TOOL_INTEGRATIONS: 'CLOUD:AZURE:DEVOPS_TOOL_INTEGRATIONS',
    CLOUD_AZURE_DEVTEST_LABS: 'CLOUD:AZURE:DEVTEST_LABS',
    CLOUD_AZURE_DIGITAL_TWINS: 'CLOUD:AZURE:DIGITAL_TWINS',
    CLOUD_AZURE_DISK_STORAGE: 'CLOUD:AZURE:DISK_STORAGE',
    CLOUD_AZURE_DNS: 'CLOUD:AZURE:DNS',
    CLOUD_AZURE_ELASTIC_CLOUD: 'CLOUD:AZURE:ELASTIC_CLOUD',
    CLOUD_AZURE_ELASTIC_SAN: 'CLOUD:AZURE:ELASTIC_SAN',
    CLOUD_AZURE_ENCODING: 'CLOUD:AZURE:ENCODING',
    CLOUD_AZURE_EVENT_GRID: 'CLOUD:AZURE:EVENT_GRID',
    CLOUD_AZURE_EVENT_HUBS: 'CLOUD:AZURE:EVENT_HUBS',
    CLOUD_AZURE_EXPRESSROUTE: 'CLOUD:AZURE:EXPRESSROUTE',
    CLOUD_AZURE_FILES: 'CLOUD:AZURE:FILES',
    CLOUD_AZURE_FIREWALL: 'CLOUD:AZURE:FIREWALL',
    CLOUD_AZURE_FIREWALL_MANAGER: 'CLOUD:AZURE:FIREWALL_MANAGER',
    CLOUD_AZURE_FLUID_RELAY: 'CLOUD:AZURE:FLUID_RELAY',
    CLOUD_AZURE_FRONT_DOOR: 'CLOUD:AZURE:FRONT_DOOR',
    CLOUD_AZURE_FUNCTIONS: 'CLOUD:AZURE:FUNCTIONS',
    CLOUD_AZURE_GITHUB_ADVANCED_SECURITY_FOR_DEVOPS: 'CLOUD:AZURE:GITHUB_ADVANCED_SECURITY_FOR_DEVOPS',
    CLOUD_AZURE_HDINSIGHT: 'CLOUD:AZURE:HDINSIGHT',
    CLOUD_AZURE_HDINSIGHT_ON_KUBERNETES_SERVICE: 'CLOUD:AZURE:HDINSIGHT_ON_KUBERNETES_SERVICE',
    CLOUD_AZURE_HEALTH_BOT: 'CLOUD:AZURE:HEALTH_BOT',
    CLOUD_AZURE_HEALTH_DATA_SERVICES: 'CLOUD:AZURE:HEALTH_DATA_SERVICES',
    CLOUD_AZURE_HPC_CACHE: 'CLOUD:AZURE:HPC_CACHE',
    CLOUD_AZURE_INFORMATION_PROTECTION: 'CLOUD:AZURE:INFORMATION_PROTECTION',
    CLOUD_AZURE_INTERNET_ANALYZER: 'CLOUD:AZURE:INTERNET_ANALYZER',
    CLOUD_AZURE_IOT_CENTRAL: 'CLOUD:AZURE:IOT_CENTRAL',
    CLOUD_AZURE_IOT_EDGE: 'CLOUD:AZURE:IOT_EDGE',
    CLOUD_AZURE_IOT_HUB: 'CLOUD:AZURE:IOT_HUB',
    CLOUD_AZURE_IOT_OPERATIONS: 'CLOUD:AZURE:IOT_OPERATIONS',
    CLOUD_AZURE_KEY_VAULT: 'CLOUD:AZURE:KEY_VAULT',
    CLOUD_AZURE_KEY_VAULT_CERTIFICATES: 'CLOUD:AZURE:KEY_VAULT:CERTIFICATES',
    CLOUD_AZURE_KEY_VAULT_KEYS: 'CLOUD:AZURE:KEY_VAULT:KEYS',
    CLOUD_AZURE_KEY_VAULT_SECRETS: 'CLOUD:AZURE:KEY_VAULT:SECRETS',
    CLOUD_AZURE_KINECT_DK: 'CLOUD:AZURE:KINECT_DK',
    CLOUD_AZURE_KUBERNETES_FLEET_MANAGER: 'CLOUD:AZURE:KUBERNETES_FLEET_MANAGER',
    CLOUD_AZURE_KUBERNETES_SERVICE: 'CLOUD:AZURE:KUBERNETES_SERVICE',
    CLOUD_AZURE_KUBERNETES_SERVICE_EDGE_ESSENTIALS: 'CLOUD:AZURE:KUBERNETES_SERVICE_EDGE_ESSENTIALS',
    CLOUD_AZURE_LAB_SERVICES: 'CLOUD:AZURE:LAB_SERVICES',
    CLOUD_AZURE_LANGUAGE_UNDERSTANDING: 'CLOUD:AZURE:LANGUAGE_UNDERSTANDING',
    CLOUD_AZURE_LIGHTHOUSE: 'CLOUD:AZURE:LIGHTHOUSE',
    CLOUD_AZURE_LINUX_VIRTUAL_MACHINES: 'CLOUD:AZURE:LINUX_VIRTUAL_MACHINES',
    CLOUD_AZURE_LIVE_AND_ON_DEMAND_STREAMING: 'CLOUD:AZURE:LIVE_AND_ON_DEMAND_STREAMING',
    CLOUD_AZURE_LOAD_BALANCER: 'CLOUD:AZURE:LOAD_BALANCER',
    CLOUD_AZURE_LOAD_TESTING: 'CLOUD:AZURE:LOAD_TESTING',
    CLOUD_AZURE_LOGIC_APPS: 'CLOUD:AZURE:LOGIC_APPS',
    CLOUD_AZURE_MACHINE_LEARNING: 'CLOUD:AZURE:MACHINE_LEARNING',
    CLOUD_AZURE_MANAGED_APPLICATIONS: 'CLOUD:AZURE:MANAGED_APPLICATIONS',
    CLOUD_AZURE_MANAGED_CONFIDENTIAL_CONSORTIUM_FRAMEWORK: 'CLOUD:AZURE:MANAGED_CONFIDENTIAL_CONSORTIUM_FRAMEWORK',
    CLOUD_AZURE_MANAGED_GRAFANA: 'CLOUD:AZURE:MANAGED_GRAFANA',
    CLOUD_AZURE_MANAGED_INSTANCE_FOR_APACHE_CASSANDRA: 'CLOUD:AZURE:MANAGED_INSTANCE_FOR_APACHE_CASSANDRA',
    CLOUD_AZURE_MANAGED_LUSTRE: 'CLOUD:AZURE:MANAGED_LUSTRE',
    CLOUD_AZURE_MAPS: 'CLOUD:AZURE:MAPS',
    CLOUD_AZURE_MEDIA_PLAYER: 'CLOUD:AZURE:MEDIA_PLAYER',
    CLOUD_AZURE_MEDIA_SERVICES: 'CLOUD:AZURE:MEDIA_SERVICES',
    CLOUD_AZURE_MICROSOFT_ATTESTATION: 'CLOUD:AZURE:MICROSOFT_ATTESTATION',
    CLOUD_AZURE_MICROSOFT_COPILOT_FOR_AZURE: 'CLOUD:AZURE:MICROSOFT_COPILOT_FOR_AZURE',
    CLOUD_AZURE_MICROSOFT_COST_MANAGEMENT: 'CLOUD:AZURE:MICROSOFT_COST_MANAGEMENT',
    CLOUD_AZURE_MICROSOFT_DATA_MANAGER_FOR_AGRICULTURE: 'CLOUD:AZURE:MICROSOFT_DATA_MANAGER_FOR_AGRICULTURE',
    CLOUD_AZURE_MICROSOFT_DEFENDER_FOR_CLOUD: 'CLOUD:AZURE:MICROSOFT_DEFENDER_FOR_CLOUD',
    CLOUD_AZURE_MICROSOFT_DEV_BOX: 'CLOUD:AZURE:MICROSOFT_DEV_BOX',
    CLOUD_AZURE_MICROSOFT_ENERGY_DATA_SERVICES: 'CLOUD:AZURE:MICROSOFT_ENERGY_DATA_SERVICES',
    CLOUD_AZURE_MICROSOFT_ENTRA_DOMAIN_SERVICES: 'CLOUD:AZURE:MICROSOFT_ENTRA_DOMAIN_SERVICES',
    CLOUD_AZURE_MICROSOFT_ENTRA_ID: 'CLOUD:AZURE:MICROSOFT_ENTRA_ID',
    CLOUD_AZURE_MICROSOFT_FABRIC: 'CLOUD:AZURE:MICROSOFT_FABRIC',
    CLOUD_AZURE_MICROSOFT_GENOMICS: 'CLOUD:AZURE:MICROSOFT_GENOMICS',
    CLOUD_AZURE_MICROSOFT_GRAPH_DATA_CONNECT: 'CLOUD:AZURE:MICROSOFT_GRAPH_DATA_CONNECT',
    CLOUD_AZURE_MICROSOFT_PLAYWRIGHT_TESTING: 'CLOUD:AZURE:MICROSOFT_PLAYWRIGHT_TESTING',
    CLOUD_AZURE_MICROSOFT_PORTAL: 'CLOUD:AZURE:MICROSOFT_PORTAL',
    CLOUD_AZURE_MICROSOFT_PURVIEW: 'CLOUD:AZURE:MICROSOFT_PURVIEW',
    CLOUD_AZURE_MICROSOFT_SENTINEL: 'CLOUD:AZURE:MICROSOFT_SENTINEL',
    CLOUD_AZURE_MIGRATE: 'CLOUD:AZURE:MIGRATE',
    CLOUD_AZURE_MOBILE_APP: 'CLOUD:AZURE:MOBILE_APP',
    CLOUD_AZURE_MODULAR_DATACENTER: 'CLOUD:AZURE:MODULAR_DATACENTER',
    CLOUD_AZURE_MONITOR: 'CLOUD:AZURE:MONITOR',
    CLOUD_AZURE_NAT_GATEWAY: 'CLOUD:AZURE:NAT_GATEWAY',
    CLOUD_AZURE_NETAPP_FILES: 'CLOUD:AZURE:NETAPP_FILES',
    CLOUD_AZURE_NETWORK_FUNCTION_MANAGER: 'CLOUD:AZURE:NETWORK_FUNCTION_MANAGER',
    CLOUD_AZURE_NETWORK_WATCHER: 'CLOUD:AZURE:NETWORK_WATCHER',
    CLOUD_AZURE_NOTIFICATION_HUBS: 'CLOUD:AZURE:NOTIFICATION_HUBS',
    CLOUD_AZURE_OBJECT_ANCHORS: 'CLOUD:AZURE:OBJECT_ANCHORS',
    CLOUD_AZURE_OPENAI_SERVICE: 'CLOUD:AZURE:OPENAI_SERVICE',
    CLOUD_AZURE_OPEN_DATASETS: 'CLOUD:AZURE:OPEN_DATASETS',
    CLOUD_AZURE_OPERATOR_INSIGHTS: 'CLOUD:AZURE:OPERATOR_INSIGHTS',
    CLOUD_AZURE_OPERATOR_NEXUS: 'CLOUD:AZURE:OPERATOR_NEXUS',
    CLOUD_AZURE_OPERATOR_SERVICE_MANAGER: 'CLOUD:AZURE:OPERATOR_SERVICE_MANAGER',
    CLOUD_AZURE_ORBITAL_GROUND_STATION: 'CLOUD:AZURE:ORBITAL_GROUND_STATION',
    CLOUD_AZURE_PIPELINES: 'CLOUD:AZURE:PIPELINES',
    CLOUD_AZURE_POLICY: 'CLOUD:AZURE:POLICY',
    CLOUD_AZURE_POWER_BI_EMBEDDED: 'CLOUD:AZURE:POWER_BI_EMBEDDED',
    CLOUD_AZURE_PRIVATE_5G_CORE: 'CLOUD:AZURE:PRIVATE_5G_CORE',
    CLOUD_AZURE_PRIVATE_LINK: 'CLOUD:AZURE:PRIVATE_LINK',
    CLOUD_AZURE_QNA_MAKER: 'CLOUD:AZURE:QNA_MAKER',
    CLOUD_AZURE_QUANTUM: 'CLOUD:AZURE:QUANTUM',
    CLOUD_AZURE_QUEUE_STORAGE: 'CLOUD:AZURE:QUEUE_STORAGE',
    CLOUD_AZURE_RED_HAT_OPENSHIFT: 'CLOUD:AZURE:RED_HAT_OPENSHIFT',
    CLOUD_AZURE_REMOTE_RENDERING: 'CLOUD:AZURE:REMOTE_RENDERING',
    CLOUD_AZURE_REPOS: 'CLOUD:AZURE:REPOS',
    CLOUD_AZURE_RESOURCE_MANAGER: 'CLOUD:AZURE:RESOURCE_MANAGER',
    CLOUD_AZURE_RESOURCE_MANAGER_TEMPLATES: 'CLOUD:AZURE:RESOURCE_MANAGER_TEMPLATES',
    CLOUD_AZURE_RESOURCE_MOVER: 'CLOUD:AZURE:RESOURCE_MOVER',
    CLOUD_AZURE_ROUTE_SERVER: 'CLOUD:AZURE:ROUTE_SERVER',
    CLOUD_AZURE_RTOS: 'CLOUD:AZURE:RTOS',
    CLOUD_AZURE_SDKS: 'CLOUD:AZURE:SDKS',
    CLOUD_AZURE_SERVICE_BUS: 'CLOUD:AZURE:SERVICE_BUS',
    CLOUD_AZURE_SERVICE_FABRIC: 'CLOUD:AZURE:SERVICE_FABRIC',
    CLOUD_AZURE_SERVICE_HEALTH: 'CLOUD:AZURE:SERVICE_HEALTH',
    CLOUD_AZURE_SIGNALR_SERVICE: 'CLOUD:AZURE:SIGNALR_SERVICE',
    CLOUD_AZURE_SITE_RECOVERY: 'CLOUD:AZURE:SITE_RECOVERY',
    CLOUD_AZURE_SPATIAL_ANCHORS: 'CLOUD:AZURE:SPATIAL_ANCHORS',
    CLOUD_AZURE_SPEAKER_RECOGNITION: 'CLOUD:AZURE:SPEAKER_RECOGNITION',
    CLOUD_AZURE_SPEECH_TO_TEXT: 'CLOUD:AZURE:SPEECH_TO_TEXT',
    CLOUD_AZURE_SPEECH_TRANSLATION: 'CLOUD:AZURE:SPEECH_TRANSLATION',
    CLOUD_AZURE_SPHERE: 'CLOUD:AZURE:SPHERE',
    CLOUD_AZURE_SPOT_VIRTUAL_MACHINES: 'CLOUD:AZURE:SPOT_VIRTUAL_MACHINES',
    CLOUD_AZURE_SPRING_APPS: 'CLOUD:AZURE:SPRING_APPS',
    CLOUD_AZURE_SQL: 'CLOUD:AZURE:SQL',
    CLOUD_AZURE_SQL_DATABASE: 'CLOUD:AZURE:SQL_DATABASE',
    CLOUD_AZURE_SQL_EDGE: 'CLOUD:AZURE:SQL_EDGE',
    CLOUD_AZURE_SQL_MANAGED_INSTANCE: 'CLOUD:AZURE:SQL_MANAGED_INSTANCE',
    CLOUD_AZURE_SQL_SERVER_ON_VIRTUAL_MACHINES: 'CLOUD:AZURE:SQL_SERVER_ON_VIRTUAL_MACHINES',
    CLOUD_AZURE_STACK: 'CLOUD:AZURE:STACK',
    CLOUD_AZURE_STACK_EDGE: 'CLOUD:AZURE:STACK_EDGE',
    CLOUD_AZURE_STACK_HCI: 'CLOUD:AZURE:STACK_HCI',
    CLOUD_AZURE_STACK_HUB: 'CLOUD:AZURE:STACK_HUB',
    CLOUD_AZURE_STATIC_WEB_APPS: 'CLOUD:AZURE:STATIC_WEB_APPS',
    CLOUD_AZURE_STORAGE_ACCOUNTS: 'CLOUD:AZURE:STORAGE_ACCOUNTS',
    CLOUD_AZURE_STORAGE_EXPLORER: 'CLOUD:AZURE:STORAGE_EXPLORER',
    CLOUD_AZURE_STREAM_ANALYTICS: 'CLOUD:AZURE:STREAM_ANALYTICS',
    CLOUD_AZURE_SYNAPSE_ANALYTICS: 'CLOUD:AZURE:SYNAPSE_ANALYTICS',
    CLOUD_AZURE_SYNAPSE_ANALYTICS_ACCESS_CONTROL: 'CLOUD:AZURE:SYNAPSE_ANALYTICS:ACCESS_CONTROL',
    CLOUD_AZURE_SYNAPSE_ANALYTICS_ARTIFACTS: 'CLOUD:AZURE:SYNAPSE_ANALYTICS:ARTIFACTS',
    CLOUD_AZURE_SYNAPSE_ANALYTICS_MANAGED_PRIVATE_ENDPOINTS: 'CLOUD:AZURE:SYNAPSE_ANALYTICS:MANAGED_PRIVATE_ENDPOINTS',
    CLOUD_AZURE_SYNAPSE_ANALYTICS_MONITORING: 'CLOUD:AZURE:SYNAPSE_ANALYTICS:MONITORING',
    CLOUD_AZURE_SYNAPSE_ANALYTICS_SPARK: 'CLOUD:AZURE:SYNAPSE_ANALYTICS:SPARK',
    CLOUD_AZURE_TABLE_STORAGE: 'CLOUD:AZURE:TABLE_STORAGE',
    CLOUD_AZURE_TEST_PLANS: 'CLOUD:AZURE:TEST_PLANS',
    CLOUD_AZURE_TEXT_TO_SPEECH: 'CLOUD:AZURE:TEXT_TO_SPEECH',
    CLOUD_AZURE_TIME_SERIES_INSIGHTS: 'CLOUD:AZURE:TIME_SERIES_INSIGHTS',
    CLOUD_AZURE_TRAFFIC_MANAGER: 'CLOUD:AZURE:TRAFFIC_MANAGER',
    CLOUD_AZURE_UPDATE_MANAGEMENT_CENTER: 'CLOUD:AZURE:UPDATE_MANAGEMENT_CENTER',
    CLOUD_AZURE_VIRTUAL_DESKTOP: 'CLOUD:AZURE:VIRTUAL_DESKTOP',
    CLOUD_AZURE_VIRTUAL_MACHINES: 'CLOUD:AZURE:VIRTUAL_MACHINES',
    CLOUD_AZURE_VIRTUAL_MACHINE_SCALE_SETS: 'CLOUD:AZURE:VIRTUAL_MACHINE_SCALE_SETS',
    CLOUD_AZURE_VIRTUAL_NETWORK: 'CLOUD:AZURE:VIRTUAL_NETWORK',
    CLOUD_AZURE_VIRTUAL_NETWORK_MANAGER: 'CLOUD:AZURE:VIRTUAL_NETWORK_MANAGER',
    CLOUD_AZURE_VIRTUAL_WAN: 'CLOUD:AZURE:VIRTUAL_WAN',
    CLOUD_AZURE_VISUAL_STUDIO: 'CLOUD:AZURE:VISUAL_STUDIO',
    CLOUD_AZURE_VISUAL_STUDIO_CODE: 'CLOUD:AZURE:VISUAL_STUDIO_CODE',
    CLOUD_AZURE_VMWARE_SOLUTION: 'CLOUD:AZURE:VMWARE_SOLUTION',
    CLOUD_AZURE_VM_IMAGE_BUILDER: 'CLOUD:AZURE:VM_IMAGE_BUILDER',
    CLOUD_AZURE_VPN_GATEWAY: 'CLOUD:AZURE:VPN_GATEWAY',
    CLOUD_AZURE_WEB_APPLICATION_FIREWALL: 'CLOUD:AZURE:WEB_APPLICATION_FIREWALL',
    CLOUD_AZURE_WEB_APP_FOR_CONTAINERS: 'CLOUD:AZURE:WEB_APP_FOR_CONTAINERS',
    CLOUD_AZURE_WEB_PUBSUB: 'CLOUD:AZURE:WEB_PUBSUB',
    CLOUD_AZURE_WINDOWS_10_IOT_CORE_SERVICES: 'CLOUD:AZURE:WINDOWS_10_IOT_CORE_SERVICES',
    CLOUD_AZURE_WINDOWS_FOR_IOT: 'CLOUD:AZURE:WINDOWS_FOR_IOT',
    CLOUD_AZURE_WINDOWS_VIRTUAL_MACHINES: 'CLOUD:AZURE:WINDOWS_VIRTUAL_MACHINES'
} as const;
export type Indicator = typeof Indicator[keyof typeof Indicator];


export function IndicatorFromJSON(json: any): Indicator {
    return IndicatorFromJSONTyped(json, false);
}

export function IndicatorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Indicator {
    return json as Indicator;
}

export function IndicatorToJSON(value?: Indicator | null): any {
    return value as any;
}

