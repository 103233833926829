import React, { type FC } from "react";
import * as Yup from "yup";
import { Formik, Form, FieldArray, FieldArrayRenderProps } from "formik";

import { Button, IconButton, TextBox, IconsList } from "../../components";
import type { ResourceTagsFormProps } from "./global-resource-tags-types";
import { tagValidationSchema } from "../../utils/resource-tags-handler";

const ResourceTagsForm: FC<ResourceTagsFormProps> = ({
  initialTags = {},
  onSubmit,
  onClose,
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        tags:
          Object.keys(initialTags)?.length > 0
            ? Object.entries(initialTags).map(([key, value]) => ({
                key,
                value,
              }))
            : [{ key: "", value: "" }],
      }}
      onSubmit={(values) => {
        const result = values.tags
          .filter((tag) => tag.key.trim() !== "" || tag.value.trim() !== "")
          .reduce((acc, tag) => {
            acc[tag.key as string] = tag.value as string;
            return acc;
          }, {} as Record<string, string>);
        onSubmit(result);
      }}
      validationSchema={Yup.object().shape({
        tags: Yup.array().of(tagValidationSchema()),
      })}
    >
      {({ values, isValid, setFieldValue, errors }) => (
        <Form>
          <div className="tw-p-4 tw-pb-20 tw-overflow-auto">
            <FieldArray name="tags">
              {({ push, remove }: FieldArrayRenderProps) =>
                values?.tags?.map((tag, index) => (
                  <div
                    key={index}
                    className={`${index === 0 ? "" : "tw-mt-2"}`}
                  >
                    <div
                      className="tw-flex tw-gap-2 tw-items-start"
                      key={index}
                    >
                      <TextBox
                        name={`tags[${index}].key`}
                        dataTestId={`tag-key-${index}`}
                        label="Key"
                        backgroundVariant="gray-700"
                      />
                      <TextBox
                        name={`tags[${index}].value`}
                        dataTestId={`tag-value-${index}`}
                        label="Value"
                        backgroundVariant="gray-700"
                      />
                      <div className="tw-mt-6">
                        <IconButton
                          icon={IconsList.DELETE}
                          dataTestId={`delete-tag-${index}`}
                          variant="secondary"
                          outlined
                          onClick={() => {
                            if (values.tags.length === 1) {
                              setFieldValue(`tags[${index}].key`, "");
                              setFieldValue(`tags[${index}].value`, "");
                            } else {
                              remove(index);
                            }
                          }}
                        />
                      </div>
                    </div>
                    {index === values.tags.length - 1 && (
                      <Button
                        leftIcon={IconsList.ADD}
                        dataTestId={`add-tag-${index}`}
                        variant="secondary"
                        label="New Tag"
                        className="tw-mt-2"
                        onClick={() => push({ key: "", value: "" })}
                      />
                    )}
                  </div>
                ))
              }
            </FieldArray>
          </div>
          <div className="tw-flex tw-gap-2 tw-p-4 tw-bg-gray-800 tw-border-t tw-border-gray-700 tw-fixed tw-bottom-0 tw-w-full">
            <Button
              dataTestId={`save-resource-tags`}
              label="Save"
              variant="primary"
              className="tw-flex-1"
              type="submit"
              disabled={!isValid}
            />
            <Button
              dataTestId={`cancel-resource-tags`}
              label="Cancel"
              variant="secondary"
              className="tw-flex-1"
              onClick={onClose}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ResourceTagsForm;
