import React from "react";
import { Formik, Form } from "formik";

import { Button, TextArea, Link, IconsList } from "../../components";

const SubmitTroubleshootingPackage = ({
  onSubmit,
  onSubmitCallback,
}: {
  onSubmit: (comment: string) => void;
  onSubmitCallback: () => void;
}) => {
  return (
    <div className="tw-flex tw-flex-col tw-gap-5">
      <span className="tw-text-sm tw-font-normal tw-text-white">
        Note: We will collect the debug logs and send it to the support team to
        debug the issue further. We will reach out to you if required.
      </span>
      <Formik
        initialValues={{
          comment: "",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          await onSubmit(values.comment);
          setSubmitting(false);
          onSubmitCallback && onSubmitCallback();
        }}
      >
        {({ isSubmitting }) => {
          return (
            <Form className="tw-flex tw-flex-col tw-gap-5">
              <div>
                <TextArea
                  name="comment"
                  placeholder="Add your comment here..."
                  label="Comment"
                  className="tw-text-white"
                  isOptional
                  dataTestId="submit-troubleshooting-package-textarea"
                />
              </div>
              <div className="tw-flex tw-items-center tw-justify-between tw-mt-5">
                <span className="tw-text-sm tw-font-normal tw-text-gray-200">
                  You can also reach out to us at{" "}
                  <Link
                    href="mailto:support@appcd.com"
                    text="support@appcd.com"
                    dataTestId="submit-troubleshooting-package-email"
                  />
                </span>
                <Button
                  type="submit"
                  label={isSubmitting ? "Submitting..." : "Submit"}
                  leftIcon={isSubmitting ? IconsList.SPINNER : undefined}
                  disabled={isSubmitting}
                  dataTestId="submit-troubleshooting-package-submit-button"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SubmitTroubleshootingPackage;
