import React, { type FC } from "react";
import { Icon, IconSize, IconsList } from "../../icons";
import logo from "../../../images/logo.svg";
import { Link } from "gatsby";
import { NavigationType } from "../sidebar-types";
interface SidebarHeaderProps {
  toggleSidebarCollapse: () => void;
  navigationType: NavigationType;
}

const SidebarHeader: FC<SidebarHeaderProps> = ({
  toggleSidebarCollapse,
  navigationType,
}) => {
  return (
    <>
      {navigationType === "DEFAULT" ? (
        <div className="tw-p-3 tw-overflow-hidden">
          <Link
            to="/appstacks"
            className="tw-float-left tw-mr-3 tw-w-8 tw-h-8"
            data-testid="appcd-logo"
          >
            <img className="tw-inline tw-rounded" src={logo} alt="appCD_logo" />
          </Link>
          <div className="tw-flex tw-justify-between tw-items-center tw-flex-1 tw-h-full">
            <Link
              to="/appstacks"
              className="tw-text-gray-50 tw-text-sm tw-font-bold tw-cursor-pointer"
              data-testid="appcd-logo-text"
            >
              appCD
            </Link>

            <div
              onClick={toggleSidebarCollapse}
              className="tw-text-gray-400 hover:tw-text-gray-300"
              data-testid="sidebar-toggle-action"
            >
              <Icon
                name={IconsList.SIDEBAR_TOGGLE}
                size={IconSize.md}
                className="tw-w-6 tw-h-5 tw-cursor-pointer tw-duration-150"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="tw-mb-2 tw-p-3 tw-flex tw-gap-3 tw-items-center">
          <Link
            to="/appstacks"
            className="tw-text-gray-300 hover:tw-text-gray-200"
            data-testid="sidebar-back-action"
          >
            <Icon
              name={IconsList.ARROW_LEFT}
              size={IconSize.md}
              className=" tw-w-7 tw-h-7 tw-cursor-pointer tw-duration-150"
            />
          </Link>
          <span className="tw-text-sm tw-font-semibold">Account Settings</span>
        </div>
      )}
    </>
  );
};

export default SidebarHeader;
