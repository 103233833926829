import React, { useState, memo, Fragment } from "react";

import { TableProps } from "./table-types";
import TableHeader from "./table-header";
import Row from "./row";
import { Pagination, Spinner } from "../../components";

const Table = ({
  columns,
  rows,
  isLoading = false,
  noRecordsMessage = "No Records Found",
  disablePagination = false,
  paginationData,
  dataTestId,
  tableClassName = "tw-text-gray-400",
}: TableProps) => {
  const [currentPage, setCurrentPage] = useState(
    paginationData?.currentPage ?? 1
  );
  const [recordPerPage, setRecordPerPage] = useState(
    paginationData?.recordPerPage ?? 10
  );
  return (
    <table
      className={`tw-relative tw-w-full tw-text-sm tw-text-left ${tableClassName}`}
    >
      <TableHeader data={columns} dataTestId={dataTestId} />
      {!isLoading ? (
        rows?.length === 0 && noRecordsMessage ? (
          <tbody className="tw-text-center ">
            <tr>
              <td
                colSpan={columns.length}
                className="tw-p-4"
                data-testid={`${dataTestId}-no-records`}
              >
                {noRecordsMessage}
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {(!disablePagination
              ? recordPerPage <= rows?.length
                ? rows?.slice(
                    (currentPage - 1) * recordPerPage,
                    currentPage * recordPerPage
                  )
                : rows
              : rows
            )?.map((row_data) => (
              <Fragment key={`row_${row_data.id}`}>
                <Row row_data={row_data} dataTestId={dataTestId} />
                {row_data?.isExpandable && row_data?.isExpanded && (
                  <tr>
                    <td colSpan={row_data?.data?.length}>
                      {row_data?.expandedContent}
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
        )
      ) : (
        <tbody className="tw-text-gray-300">
          <tr>
            <td colSpan={columns.length} className="tw-p-4">
              <div
                className="tw-flex tw-gap-2 tw-items-center tw-justify-center"
                data-testid={`${dataTestId}-loading`}
              >
                <Spinner />
                <span>Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>
      )}
      {!disablePagination && (
        <tfoot className="tw-sticky tw--bottom-px">
          <tr>
            <td colSpan={columns.length}>
              <Pagination
                currentPage={currentPage}
                totalRecordCount={rows.length}
                recordPerPage={recordPerPage}
                siblingCount={paginationData?.siblingCount}
                showRowPerPage={paginationData?.showRowPerPage}
                showGoToPage={paginationData?.showGoToPage}
                onPageChange={(page) => setCurrentPage(page)}
                onRowPerPageChange={(recordPerPage) => {
                  setRecordPerPage(recordPerPage);
                  paginationData?.onRowPerPageChange &&
                    paginationData?.onRowPerPageChange(recordPerPage);
                }}
                onGoToPageChange={(page) => setCurrentPage(page)}
                dataTestId={dataTestId}
              />
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  );
};

export default memo(Table);
