export enum DrawerSize {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
}

export enum DrawerPosition {
  Left = "left",
  Right = "right",
}

export const DrawerOpenMap = {
  [DrawerSize.xs]: "tw-translate-x-100",
  [DrawerSize.sm]: "tw-translate-x-100",
  [DrawerSize.md]: "tw-translate-x-225",
  [DrawerSize.lg]: "tw-translate-x-320",
};

export const DrawerCloseMap = {
  [DrawerSize.xs]: "tw--translate-x-100",
  [DrawerSize.sm]: "tw--translate-x-100",
  [DrawerSize.md]: "tw--translate-x-225",
  [DrawerSize.lg]: "tw--translate-x-320",
};

export const DrawerWidthMap = {
  [DrawerSize.xs]: "tw-w-80",
  [DrawerSize.sm]: "tw-w-100",
  [DrawerSize.md]: "tw-w-225",
  [DrawerSize.lg]: "tw-w-320",
};

export interface DrawerProps {
  open: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  size?: keyof typeof DrawerSize;
  isResizable?: boolean;
  disableBackdropClick?: boolean;
  position?: keyof typeof DrawerPosition;
  isFullScreenDrawer?: boolean;
  minResizableWidth?: number;
  maxResizableWidth?: number;
  dataTestId: string;
}
