import React, { useEffect, useState, useRef, useCallback } from "react";
import { Formik, Form, type FormikProps, setNestedObjectValues } from "formik";

import type { TopologyResourceWithMisconfigurations } from "../../apis/topology";

import type {
  ResourcePropertiesFormProps,
  PropertiesType,
} from "./topology-graph-types";
import {
  Button,
  TextBox,
  Icon,
  IconsList,
  AWSIcon,
  AWSIconsList,
  IconSize,
  Tabs,
  Tag,
  Banner,
  ScrollToError,
  Spinner,
  ToggleSwitch,
} from "../../components";

import { BannerTypes } from "../../components/banner/banner-types";

import {
  attributePropertiesToFormikValues,
  formikValuesToAttributeProperties,
  formikValuesValidationSchema,
  fuzzySearch,
  trackAmplitudeEvent,
} from "../../utils";
import { unsupportedResourceTypes } from "../../config/constants";

import { PolicyViolationsTab, ResourcePropertiesFormElements } from "./";

// TODO: Remove direct store use inside this component if possible
import { useSetAtom } from "jotai";
import { dialogAtom } from "../../stores/page.store";
import ResourceTagsForm from "../global-resource-tags/resource-tags-form";

const ResourcePropertiesForm = ({
  resourcePropertiesTemplate,
  resource,
  onPanelClose,
  onUpdateResourcePropertiesValues,
  defaultSelectedTabId,
  resourceHasErrors,
  showResourcePropertiesErrors,
  onEditCustomResource = () => {},
  markResourceAsExternal,
}: ResourcePropertiesFormProps) => {
  const [showPolicyViolationBanner, setShowPolicyViolationBanner] =
    useState(false);
  const [resourceTemplateAttributes, setResourceTemplate] = useState(
    [] as PropertiesType[]
  );
  const [resourceErrorCount, setResourceErrorCount] = useState(0);
  const [filteredResourceTemplate, setFilteredResourceTemplate] = useState(
    [] as PropertiesType[]
  );
  const [resourceTemplateLayouts, setResourceTemplateLayout] = useState(
    [] as PropertiesType[]
  );
  const [sectionsExpanded, setSectionsExpanded] = useState<{
    [key: string]: boolean;
  }>({});

  const setDialogData = useSetAtom(dialogAtom);

  const onAttributeSearch = useCallback(
    (attributeSearchTerm: string) => {
      setFilteredResourceTemplate(
        fuzzySearch(attributeSearchTerm, resourceTemplateAttributes, [
          "label",
          "key",
        ])
      );
    },
    [resourceTemplateAttributes]
  );

  const formikRef = useRef<
    FormikProps<{
      [field: string]: any;
    }>
  >(null);

  const validateForm = useCallback(
    async (
      formikObj: FormikProps<{
        [field: string]: any;
      }>,
      showResourcePropertiesErrors?: boolean
    ) => {
      const errors = await formikObj?.validateForm(formikObj.values);
      const errorCount = Object.keys(errors).length;
      if (errorCount > 0) {
        if (showResourcePropertiesErrors) {
          formikObj?.setTouched(setNestedObjectValues(errors, true));
          setResourceErrorCount(0);
        } else {
          setResourceErrorCount(errorCount);
        }
      } else {
        setResourceErrorCount(0);
      }
    },
    [setResourceErrorCount]
  );

  // TODO: move this to other file for reuse and keeping context separate from this component
  const showTFVarsDialog = (
    field_props: any,
    resource_attribute_obj: any,
    updateTFVar?: boolean
  ) => {
    setDialogData({
      open: true,
      size: "sm",
      dataTestId: "tf-vars-update-popup-dialog-opened",
      titleContent: (
        <div className="tw-flex tw-items-center tw-justify-between tw-gap-3">
          <span className="tw-text-white tw-text-base tw-font-semibold">
            {updateTFVar ? "Update" : "Create"} TF Var: {field_props.label}
          </span>
          <button
            onClick={() =>
              setDialogData({
                open: false,
                dataTestId: `tf-vars-update-popup-dialog-closed`,
              })
            }
            type="button"
          >
            <Icon
              name={IconsList.CLOSE}
              className="tw-text-gray-300 hover:tw-text-gray-200 tw-cursor-pointer"
            />
          </button>
        </div>
      ),
      bodyContent: (
        <div className="tw-w-100 tw-max-h-96 tw-flex tw-flex-col tw-gap-2 tw-overflow-scroll">
          <Formik
            initialValues={{
              ...attributePropertiesToFormikValues(
                resourceTemplateAttributes,
                resource._configuration
              ),
              tf_var_variable_name: updateTFVar
                ? (resource as any)?.tfVars?.[resource_attribute_obj.key]
                : field_props.name,
            }}
            // apply validation only for the selected variable Default value
            validationSchema={formikValuesValidationSchema([
              ...resourceTemplateAttributes
                .filter((attr) => attr.key === resource_attribute_obj.key)
                .map((attr) => {
                  return {
                    ...attr,
                    validation: {
                      ...attr.validation,
                      required: false, // Default value is not a required field
                    },
                  };
                }),
              {
                key: "tf_var_variable_name",
                type: "text",
                validation: {
                  dataType: "string",
                  required: true,
                },
              },
            ])}
            onSubmit={async (values, { setSubmitting }) => {
              trackAmplitudeEvent(
                updateTFVar ? "tf_var_update" : "tf_var_create"
              );
              const { tf_var_variable_name, ...configuration_values } = values;
              await onUpdateResourcePropertiesValues?.({
                ...resource,
                _configuration: formikValuesToAttributeProperties(
                  configuration_values,
                  resourceTemplateAttributes
                ),
                tfVars: {
                  ...resource?.tfVars,
                  [resource_attribute_obj.key]: tf_var_variable_name,
                },
              });
              setSubmitting(false);
              setDialogData({
                open: false,
                dataTestId: `tf-vars-update-popup-dialog-closed`,
              });
            }}
          >
            {({
              values,
              isSubmitting,
              errors,
              touched,
              setSubmitting,
              setFieldValue,
            }) => {
              if (isSubmitting) {
                return (
                  <div className="tw-flex tw-gap-2 tw-items-center tw-justify-center tw-text-gray-300">
                    <Spinner />
                  </div>
                );
              } else {
                return (
                  <Form>
                    <TextBox
                      name="tf_var_variable_name"
                      label={"Variable Name"}
                      placeholder={"Enter variable name"}
                      backgroundVariant="gray-800"
                      required={true}
                      dataTestId="tf_var_variable_name"
                    />
                    <div className="tw-mt-3">
                      <ResourcePropertiesFormElements
                        resource_attribute_obj={{
                          ...resource_attribute_obj,
                          label: "Default Value",
                          validation: {
                            required: false,
                          },
                        }}
                        values={values}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        showTFVarsDialog={(
                          field_props: any,
                          updateTFVar?: boolean
                        ) => {
                          showTFVarsDialog(
                            field_props,
                            resource_attribute_obj,
                            updateTFVar
                          );
                        }}
                        hideTFVar={true}
                        selectMenuPosition="fixed"
                      />
                    </div>
                    <div
                      className={`tw-mt-4 tw-flex tw-gap-3 ${
                        updateTFVar ? "tw-justify-between" : "tw-justify-end"
                      }`}
                    >
                      {updateTFVar && (
                        <Button
                          label="Delete Variable"
                          leftIcon={IconsList.DELETE}
                          variant="secondary"
                          size="sm"
                          onClick={async () => {
                            setSubmitting(true);
                            trackAmplitudeEvent("tf_var_delete");
                            await onUpdateResourcePropertiesValues?.({
                              ...resource,
                              tfVars: {
                                ...resource?.tfVars,
                                [resource_attribute_obj.key]: undefined,
                              },
                            });
                            setSubmitting(false);
                            setDialogData({
                              open: false,
                              dataTestId: `tf-vars-update-popup-dialog-closed`,
                            });
                          }}
                          dataTestId="tf-vars-delete"
                        />
                      )}
                      <Button
                        type="submit"
                        label={updateTFVar ? "Update" : "Create"}
                        variant="primary"
                        dataTestId={`tf-vars-create`}
                      />
                    </div>
                  </Form>
                );
              }
            }}
          </Formik>
        </div>
      ),
    });
  };

  const sectionCollapsibleHeading = (
    sectionData: PropertiesType,
    index: number
  ) => {
    return (
      <div
        className="tw-px-4 tw-py-3 tw-bg-gray-500 tw-bg-opacity-30 tw-cursor-pointer tw-flex tw-justify-between tw-items-center tw-gap-4 tw-border-b tw-border-gray-600"
        onClick={() =>
          setSectionsExpanded({
            ...sectionsExpanded,
            [`section_${index}`]: !sectionsExpanded[`section_${index}`],
          })
        }
      >
        <div className="tw-text-sm tw-font-semibold">{sectionData.label}</div>
        <div className="tw-text-gray-400">
          <Icon
            name={
              sectionsExpanded[`section_${index}`]
                ? IconsList.CARET_UP
                : IconsList.CARET_DOWN
            }
          />
        </div>
      </div>
    );
  };

  const resourcePropertiesFormikForm = () => {
    return (
      <Formik
        innerRef={formikRef}
        initialValues={attributePropertiesToFormikValues(
          resourceTemplateAttributes,
          resource._configuration
        )}
        enableReinitialize
        validationSchema={formikValuesValidationSchema(
          resourceTemplateAttributes.filter(
            (attr: PropertiesType) =>
              (resource as any)?.tfVars?.[attr.key] === undefined
          )
        )}
        onSubmit={async (values, { setSubmitting }) => {
          trackAmplitudeEvent("resource_attribute_edit");
          const resourcePropertiesReqBody = {
            ...resource,
            _configuration: {
              ...resource._configuration,
              // override the values that are changed
              ...formikValuesToAttributeProperties(
                values,
                resourceTemplateAttributes
              ),
            },
          };

          const updated_resource_properties: TopologyResourceWithMisconfigurations =
            (await onUpdateResourcePropertiesValues?.(
              resourcePropertiesReqBody
            )) as unknown as TopologyResourceWithMisconfigurations;

          setShowPolicyViolationBanner(
            updated_resource_properties?.misconfigurations &&
              updated_resource_properties?.misconfigurations?.length > 0
              ? true
              : false
          );
          setSubmitting(false);
        }}
      >
        {({ values, isSubmitting, errors, touched, setFieldValue }) => {
          return (
            <Form>
              <ScrollToError />
              {resourceTemplateLayouts?.[0]?.sections?.map(
                (sectionData: PropertiesType, section_index: number) => {
                  if (!sectionData?.attributes) {
                    return null;
                  }

                  return (
                    <React.Fragment
                      key={`${
                        sectionData.type === "section"
                          ? `section_${sectionData.label}`
                          : `attributes_${section_index}`
                      }`}
                    >
                      {sectionData.type === "section" &&
                        sectionCollapsibleHeading(sectionData, section_index)}
                      {(sectionData.type === "section" &&
                        sectionsExpanded[`section_${section_index}`]) ||
                      sectionData.type === "attributes"
                        ? sectionData?.attributes?.map(
                            (
                              resource_attribute: PropertiesType,
                              resource_attribute_index: number
                            ) => {
                              let resource_attribute_obj =
                                filteredResourceTemplate?.find(
                                  (attribute) =>
                                    attribute.key === resource_attribute
                                );

                              if (
                                resource_attribute_obj?.key &&
                                (resource as any)?.overrideAttributes?.[
                                  resource_attribute_obj.key
                                ]
                              ) {
                                resource_attribute_obj = {
                                  ...resource_attribute_obj,
                                  ...(resource as any)?.overrideAttributes?.[
                                    resource_attribute_obj.key
                                  ],
                                };
                              }

                              // Check if resource_attribute_obj is not empty after overrideAttributes
                              if (!resource_attribute_obj) {
                                return null;
                              }

                              const tf_var_value = (resource as any)?.tfVars?.[
                                resource_attribute_obj.key
                              ];

                              return (
                                <div
                                  key={`field_${resource_attribute_obj.key}`}
                                  className={`tw-px-4 tw-pb-4 ${
                                    resource_attribute_index === 0
                                      ? "tw-pt-4"
                                      : ""
                                  } ${
                                    sectionData.type === "attributes"
                                      ? "tw-pt-4 tw-border-b tw-border-gray-600"
                                      : ""
                                  }`}
                                >
                                  <ResourcePropertiesFormElements
                                    template_attributes={
                                      filteredResourceTemplate
                                    }
                                    resource_attribute_obj={
                                      resource_attribute_obj
                                    }
                                    tfVarValue={tf_var_value}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    showTFVarsDialog={(
                                      field_props: any,
                                      updateTFVar?: boolean
                                    ) => {
                                      showTFVarsDialog(
                                        field_props,
                                        resource_attribute_obj,
                                        updateTFVar
                                      );
                                    }}
                                    hideTFVar={resource_attribute_obj.hideTFVar}
                                    setFieldValue={setFieldValue}
                                  />
                                </div>
                              );
                            }
                          )
                        : null}
                    </React.Fragment>
                  );
                }
              )}
              <div className="tw-sticky tw-bottom-0 tw-bg-gray-800 tw-p-4 tw-flex-col tw-border-t tw-border-gray-700">
                <div className="tw-flex tw-gap-2 tw-items-center">
                  <div className="tw-basis-1/2">
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      label={isSubmitting ? "Saving..." : "Save"}
                      className="tw-w-full"
                      dataTestId="save-resource-configuration"
                    />
                  </div>
                  <div className="tw-basis-1/2">
                    <Button
                      variant="secondary"
                      type="button"
                      disabled={isSubmitting}
                      label={"Cancel"}
                      className="tw-w-full"
                      onClick={onPanelClose}
                      dataTestId="cancel-resource-configuration"
                    />
                  </div>
                </div>
                {unsupportedResourceTypes.includes(resource.resourceType) ? (
                  <div className="tw-flex-col tw-gap-2 tw-justify-end tw-w-full tw-pt-8">
                    <Button
                      className="tw-bg-gray-700 tw-w-full"
                      label="Add to Custom Resources"
                      variant="secondary"
                      type="button"
                      onClick={() => {
                        onPanelClose();
                        onEditCustomResource();
                      }}
                      dataTestId="add-to-custom-resources"
                    />
                    <div className="tw-flex tw-gap-1 tw-items-center ">
                      <Icon
                        name={IconsList.INFO_CIRCLE}
                        size={IconSize.xs}
                        className="tw-text-gray-300"
                      />
                      <span className="tw-text-gray-300 tw-text-xssm">
                        You can save this resource & use it later in the
                        topologies.
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  useEffect(() => {
    const updatedResourceTemplateAttributes =
      resourcePropertiesTemplate?.attributes?.map((resource_attribute: any) => {
        const updated_resource_attribute = resource?.references?.find(
          (reference_resource_attribute) =>
            reference_resource_attribute.key === resource_attribute.key
        );
        if (updated_resource_attribute) {
          return {
            ...updated_resource_attribute,
            disabled: true,
            hideTFVar: true,
          };
        } else {
          return resource_attribute;
        }
      });
    setResourceTemplate(updatedResourceTemplateAttributes);
    setFilteredResourceTemplate(updatedResourceTemplateAttributes);
    setResourceTemplateLayout(resourcePropertiesTemplate?.layouts);
    setSectionsExpanded({});
  }, [resource]);

  useEffect(() => {
    if (formikRef.current) {
      if (resourceHasErrors) {
        validateForm(formikRef.current, showResourcePropertiesErrors);
      } else {
        setResourceErrorCount(0);
      }
    }
  }, [resourceHasErrors, showResourcePropertiesErrors, formikRef.current]);

  useEffect(() => {
    if (resourceHasErrors) {
      const expandedSections: {
        [key: string]: boolean;
      } = {};
      resourceTemplateLayouts?.[0]?.sections?.forEach(
        (section: PropertiesType, section_index: number) => {
          if (section.type === "section") {
            expandedSections[`section_${section_index}`] = true;
          }
        }
      );
      setSectionsExpanded(expandedSections);
    }
  }, [resourceHasErrors, resourceTemplateLayouts, resource]);

  return (
    <div className="tw-bg-gray-800 tw-min-h-full">
      <div className="tw-flex tw-gap-3 tw-justify-between tw-items-center tw-p-4">
        <div className="tw-flex tw-gap-2 tw-items-center">
          <div className="tw-basis-6">
            <AWSIcon
              name={
                AWSIconsList[
                  (resource?.resourceType === "aws_external"
                    ? (resource?._configuration as { [key: string]: any })
                        ?.resource_type
                    : resource?.resourceType) as keyof typeof AWSIconsList
                ]
              }
              size={IconSize.lg}
              fallbackPlaceholder={resource.resourceType}
            />
          </div>
          <span className="tw-font-semibold tw-text-white tw-text-base">
            Configure {resource.resourceTypeLabel}
          </span>
        </div>
        <div className="tw-flex tw-gap-3">
          <div
            onClick={onPanelClose}
            className="tw-cursor-pointer tw-text-gray-300 hover:tw-text-gray-200"
            data-testid="close-resource-properties-form"
          >
            <Icon name={IconsList.CLOSE} />
          </div>
        </div>
      </div>
      {resourceTemplateAttributes?.length > 0 ? (
        <Tabs
          defaultSelectedTabId={defaultSelectedTabId}
          tabs={[
            {
              id: "details",
              title: "Details",
              content: (
                <>
                  {resourceErrorCount > 0 && (
                    <div className="tw-px-3 tw-pt-3">
                      <Banner
                        type={BannerTypes.DANGER}
                        dataTestId="errors-banner"
                      >
                        <span className="tw-text-sm">
                          We found {resourceErrorCount} configuration errors.
                        </span>
                      </Banner>
                    </div>
                  )}
                  {resource._configuration && (
                    <>
                      {unsupportedResourceTypes.includes(
                        resource.resourceType
                      ) ? (
                        <div className="tw-p-4 tw-border-b tw-border-gray-600">
                          <Banner
                            type={BannerTypes.INFO}
                            dataTestId="unsupported-resource-banner"
                          >
                            <span className="tw-text-sm tw-font-normal">
                              appCD does not have support for this resource at
                              this moment. However, save this resource as custom
                              resource.
                            </span>
                          </Banner>
                        </div>
                      ) : null}
                      <div
                        className={`tw-p-4 ${
                          filteredResourceTemplate?.length > 0 ? "" : "tw-pb-2"
                        }`}
                      >
                        <Formik
                          initialValues={{
                            search: "",
                          }}
                          onSubmit={() => {}}
                        >
                          <Form>
                            <TextBox
                              name="search"
                              placeholder="Search"
                              backgroundVariant="gray-800"
                              onChange={(e) => {
                                onAttributeSearch(e.target.value);
                              }}
                              prefix={
                                <Icon
                                  name={IconsList.SEARCH}
                                  size={IconSize.sm}
                                  className="tw-self-center tw-text-gray-500"
                                />
                              }
                              isClearable
                              dataTestId="search-resource-attributes"
                            />
                          </Form>
                        </Formik>
                      </div>
                      {resource?.resourceType !== "aws_external" && (
                        <div className="tw-px-4 tw-py-3 tw-bg-gray-500 tw-bg-opacity-30 tw-cursor-pointer tw-flex tw-justify-between tw-items-center tw-gap-4 tw-border-b tw-border-gray-600">
                          <div className="tw-text-sm tw-font-semibold">
                            Mark as External Resource
                          </div>
                          <div>
                            <Formik
                              initialValues={{
                                mark_external: false, // this value should always be a boolean
                              }}
                              onSubmit={() => {
                                /* Intentionally kept empty as it is a required prop in Formik, but we are not submitting the form. */
                              }}
                            >
                              {({ values, setFieldValue }) => {
                                return (
                                  <Form>
                                    <ToggleSwitch
                                      name={`mark_external`}
                                      value={
                                        values.mark_external as unknown as string
                                      }
                                      id={`mark_external`}
                                      dataTestId={`mark-external-switch`}
                                      onToggle={async (e) => {
                                        if (e.target.checked && resource.id) {
                                          const external_resource =
                                            await markResourceAsExternal?.(
                                              resource.id
                                            );
                                          if (external_resource) {
                                            trackAmplitudeEvent(
                                              "mark_external_resource_success"
                                            );
                                          } else {
                                            trackAmplitudeEvent(
                                              "mark_external_resource_failed"
                                            );
                                            setFieldValue(
                                              "mark_external",
                                              false
                                            );
                                          }
                                        }
                                      }}
                                    />
                                  </Form>
                                );
                              }}
                            </Formik>
                          </div>
                        </div>
                      )}
                      {resourcePropertiesFormikForm()}
                    </>
                  )}
                </>
              ),
            },
            {
              id: "resource_tags",
              title: "Resource Tags",
              content: (
                <div className="tw-min-h-full">
                  {resource?.["_configuration"] &&
                  Object.keys(resource?.["_configuration"] as any)?.includes(
                    "tags"
                  ) ? (
                    <>
                      <ResourceTagsForm
                        initialTags={
                          (resource?.["_configuration"] as any)?.tags ?? {}
                        }
                        onClose={onPanelClose}
                        onSubmit={(tags) => {
                          onUpdateResourcePropertiesValues?.({
                            ...resource,
                            _configuration: {
                              ...resource._configuration,
                              tags,
                            },
                          });
                          trackAmplitudeEvent("resource_tags_updated", {
                            resourceId: resource.id as string,
                            tags: tags,
                          });
                        }}
                      />
                    </>
                  ) : (
                    <div>
                      <div className="tw-flex tw-justify-center tw-gap-3 tw-flex-col tw-items-center  tw-text-gray-200 tw-text-sm tw-font-normal tw-mt-24">
                        <Icon
                          name={IconsList.INFO_CIRCLE}
                          size={IconSize["xl"]}
                        />
                        <p className="tw-w-56 tw-text-center">
                          This resource does not support resource tags.
                        </p>
                      </div>
                      <div className="tw-flex tw-gap-2 tw-p-4 tw-bg-gray-800 tw-border-t tw-border-gray-700 tw-fixed tw-bottom-0 tw-w-full">
                        <Button
                          dataTestId="resource-tags-save-btn"
                          label="Save"
                          variant="primary"
                          className="tw-flex-1"
                          type="submit"
                          disabled
                        />
                        <Button
                          dataTestId="resource-tags-cancel-btn"
                          label="Cancel"
                          variant="secondary"
                          className="tw-flex-1"
                          disabled
                        />
                      </div>
                    </div>
                  )}
                </div>
              ),
            },
            {
              id: "policy_violations",
              title: "Policy Violations",
              titleSuffix: (
                <Tag
                  title={"" + (resource?.misconfigurations?.length ?? 0)}
                  size="sm"
                  className={
                    resource?.misconfigurations &&
                    resource.misconfigurations?.length > 0
                      ? "tw-bg-yellow-500 tw-text-red-900"
                      : ""
                  }
                  dataTestId="policy-violations-count"
                />
              ),
              content: (
                <div className="tw-min-h-full">
                  {showPolicyViolationBanner &&
                    resource.misconfigurations &&
                    resource.misconfigurations?.length > 0 && (
                      <div className="tw-p-3">
                        <Banner
                          type={BannerTypes.WARNING}
                          dataTestId="policy-violation-warning"
                        >
                          <span className="tw-text-sm">
                            Configuration changes are saved, but they violate{" "}
                            {resource.misconfigurations?.length} policies.
                          </span>
                        </Banner>
                      </div>
                    )}
                  <PolicyViolationsTab
                    misconfigurations={resource.misconfigurations ?? []}
                  />
                </div>
              ),
            },
          ]}
        />
      ) : (
        <div className="tw-flex tw-flex-col tw-gap-4 tw-items-center tw-px-6 tw-py-20">
          <div className="tw-text-gray-200 tw-text-xssm tw-text-center">
            No Configuration found for given resource.
          </div>
          {/* Uncomment below line when we start giving support for editing Custom Resources */}
          {/* <div className="tw-text-gray-200 tw-text-xssm tw-text-center">
            To configure this resource, you need to add configuration variables
            to your resource template.
          </div>
          <Button
            onClick={() => {
              onPanelClose();
              onEditCustomResource(true);
            }}
            label="Configure Resource Template"
            variant="secondary"
            className="tw-w-fit"
            leftIcon={IconsList.GEAR}
            dataTestId="configure-resource-template"
          /> */}
        </div>
      )}
    </div>
  );
};

export default ResourcePropertiesForm;
