/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TopologyResourceConnection } from './TopologyResourceConnection';
import {
    TopologyResourceConnectionFromJSON,
    TopologyResourceConnectionFromJSONTyped,
    TopologyResourceConnectionToJSON,
} from './TopologyResourceConnection';

/**
 * 
 * @export
 * @interface IAMResourceConnectionsList
 */
export interface IAMResourceConnectionsList {
    /**
     * 
     * @type {Array<TopologyResourceConnection>}
     * @memberof IAMResourceConnectionsList
     */
    resourceConnections?: Array<TopologyResourceConnection>;
}

/**
 * Check if a given object implements the IAMResourceConnectionsList interface.
 */
export function instanceOfIAMResourceConnectionsList(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function IAMResourceConnectionsListFromJSON(json: any): IAMResourceConnectionsList {
    return IAMResourceConnectionsListFromJSONTyped(json, false);
}

export function IAMResourceConnectionsListFromJSONTyped(json: any, ignoreDiscriminator: boolean): IAMResourceConnectionsList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resourceConnections': !exists(json, 'resourceConnections') ? undefined : ((json['resourceConnections'] as Array<any>).map(TopologyResourceConnectionFromJSON)),
    };
}

export function IAMResourceConnectionsListToJSON(value?: IAMResourceConnectionsList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resourceConnections': value.resourceConnections === undefined ? undefined : ((value.resourceConnections as Array<any>).map(TopologyResourceConnectionToJSON)),
    };
}

