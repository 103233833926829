import type { Node } from "reactflow";

export const updateResourceDataInTopology = (
  resources: any,
  resourceToUpdateId: string,
  updatedResourceData: any,
  replace: boolean = false
) => {
  resources.every((resource: any, resource_index: number) => {
    if (resource.id === resourceToUpdateId) {
      if (replace) {
        resources[resource_index] = updatedResourceData;
      } else {
        resources[resource_index] = {
          ...resources[resource_index],
          ...updatedResourceData,
        };
      }

      return false;
    } else if (resource?.children?.length > 0) {
      resources[resource_index] = {
        ...resources[resource_index],
        children: updateResourceDataInTopology(
          resources[resource_index].children,
          resourceToUpdateId,
          updatedResourceData,
          replace
        ),
      };
    }

    return true;
  });

  return resources;
};

export const findResourceInTopology = (
  resources: any,
  resourceIdtoFind: string
) => {
  let resource: any = null;

  for (let i = 0; i < resources.length; i++) {
    if (resources[i].id === resourceIdtoFind) {
      resource = resources[i];
      break;
    } else if (resources[i]?.children?.length > 0) {
      resource = findResourceInTopology(
        resources[i].children,
        resourceIdtoFind
      );
      if (resource) {
        break;
      }
    }
  }

  return resource;
};

export const addResourceInTopology = (resources: any, resourceToAdd: any) => {
  resources.forEach((resource: any, resource_index: number) => {
    if (resource.id === resourceToAdd.group) {
      resources[resource_index] = {
        ...resources[resource_index],
        children: [...resources[resource_index].children, resourceToAdd],
      };
    } else if (resource?.children?.length > 0) {
      resources[resource_index] = {
        ...resources[resource_index],
        children: addResourceInTopology(
          resources[resource_index].children,
          resourceToAdd
        ),
      };
    }
  });

  return resources;
};

export const findChildNodeAbsolutePosition = (
  currentNode: Node,
  allNodes: Node[]
): { x: number; y: number } => {
  if (!currentNode?.parentNode) {
    return { x: currentNode.position.x, y: currentNode.position.y };
  }

  const parentNode = allNodes.find((n) => n.id === currentNode.parentNode);
  if (!parentNode) {
    return { x: currentNode.position.x, y: currentNode.position.y };
  }

  const parentPosition = findChildNodeAbsolutePosition(parentNode, allNodes);

  return {
    x: parentPosition.x + currentNode.position.x,
    y: parentPosition.y + currentNode.position.y,
  };
};

export const traverseEachResource = async (
  resources: any,
  currentResourceCallback: (resource: any) => any
) => {
  for (const resource of resources) {
    await currentResourceCallback(resource);
    if (resource?.children?.length > 0) {
      await traverseEachResource(resource?.children, currentResourceCallback);
    }
  }
};

export const traverseAndUpdateEachResource = (
  resources: any,
  updateResourceDataCallback: (resource: any) => any
) => {
  for (let i = 0; i < resources?.length; i++) {
    resources[i] = updateResourceDataCallback(resources[i]);
    if (resources[i]?.children?.length > 0) {
      resources[i] = {
        ...resources[i],
        children: traverseAndUpdateEachResource(
          resources[i].children,
          updateResourceDataCallback
        ),
      };
    }
  }

  return resources;
};
