import React, { memo } from "react";
import { NodeProps, Handle, Position } from "reactflow";
import {
  AWSIcon,
  AWSIconsList,
  Icon,
  IconSize,
  IconsList,
} from "../../components";

const CustomGroupNode = (node: NodeProps) => {
  return (
    <>
      <Handle
        type="source"
        position={Position.Top}
        id={`${node?.data?.id}-top`}
        isConnectable={true}
        className={`tw-w-2.5 tw-h-2.5 -tw-top-1.5 tw-bg-gray-700 hover:tw-bg-gray-500 hover:tw-border-gray-300 ${
          node.data?.expanded ? "tw-border-blue-500" : "tw-border-gray-400"
        }`}
        data-testid={`handle-${node?.data?.id}-top`}
      />
      <Handle
        type="source"
        position={Position.Left}
        id={`${node?.data?.id}-left`}
        isConnectable={true}
        className={`tw-w-2.5 tw-h-2.5 -tw-left-1.5 tw-bg-gray-700 hover:tw-bg-gray-500 hover:tw-border-gray-300 ${
          node.data?.expanded ? "tw-border-blue-500" : "tw-border-gray-400"
        }`}
        data-testid={`handle-${node?.data?.id}-left`}
      />
      <div
        className={`tw-flex tw-justify-between tw-items-center tw-gap-3 tw-p-3 tw-bg-gray-700 ${
          node.data?.expanded
            ? `tw-border-b tw-rounded-t-md ${
                node.data?.diffType === "created"
                  ? "tw-border-green-500"
                  : node.data?.diffType === "deleted"
                  ? "tw-border-red-500"
                  : node.data?.selected
                  ? "tw-border-blue-500"
                  : "tw-border-gray-600"
              }`
            : "tw-rounded-md tw-border tw-border-gray-600"
        }`}
        data-testid={`group-node-${node?.data?.id}`}
      >
        <div className="tw-flex tw-gap-2 tw-items-center">
          {node?.data?.icon && (
            <AWSIcon
              name={AWSIconsList[node?.data?.icon as keyof typeof AWSIconsList]}
              alt={node?.data?.label}
              size={IconSize.xl}
              fallbackPlaceholder={node?.data?.resourceType}
              className="tw-rounded"
            />
          )}
          <span
            className="tw-text-sm tw-text-white tw-font-semibold tw-break-all"
            data-testid={`${node?.data?.id}-label`}
          >
            {node?.data?.label}
          </span>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            node?.data?.updateExpanded?.(!node?.data?.expanded);
          }}
        >
          {node?.data?.expanded ? (
            <Icon
              name={IconsList.CARET_UP}
              className="tw-text-gray-200 hover:tw-text-white"
              size={IconSize.xl}
            />
          ) : (
            <Icon
              name={IconsList.CARET_DOWN}
              className="tw-text-gray-200 hover:tw-text-white"
              size={IconSize.xl}
            />
          )}
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id={`${node?.data?.id}-bottom`}
        isConnectable={true}
        className={`tw-w-2.5 tw-h-2.5 -tw-bottom-1.5 tw-bg-gray-700 hover:tw-bg-gray-500 hover:tw-border-gray-300 ${
          node.data?.expanded
            ? "tw-border-blue-500"
            : "tw-border-gray-400 -tw-bottom-3"
        }`}
        data-testid={`handle-${node?.data?.id}-bottom`}
      />
      <Handle
        type="source"
        position={Position.Right}
        id={`${node?.data?.id}-right`}
        isConnectable={true}
        className={`tw-w-2.5 tw-h-2.5 -tw-right-1.5 tw-bg-gray-700 hover:tw-bg-gray-500 hover:tw-border-gray-300 ${
          node.data?.expanded ? "tw-border-blue-500" : "tw-border-gray-400"
        }`}
        data-testid={`handle-${node?.data?.id}-right`}
      />
    </>
  );
};

export default memo(CustomGroupNode);
