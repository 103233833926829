import React, { type FC } from "react";
import { SelectBox, TextBox } from "../..";
import type { DefaultValueRendererForPrimitiveTypeProps } from "./custom-resource-types";
import {
  CustomResourceDataType,
  booleanOptions,
} from "../../../utils/custom-resource-handler";

//  Renders a default value input component based on the given data type.
const DefaultValueRendererForPrimitiveType: FC<
  DefaultValueRendererForPrimitiveTypeProps
> = ({ type, varIndex }) => {
  switch (type) {
    case CustomResourceDataType.STRING:
    case CustomResourceDataType.NUMBER:
      return (
        <TextBox
          labelVariant="gray200-medium"
          name={`variables.[${varIndex}].defaultVal`}
          label="Default Value"
          placeholder={
            type === CustomResourceDataType.NUMBER ? "e.g. 21" : "e.g. foo.com"
          } // TODO: Add relevant placeholder in example
          description="Note: Default value will be applied to all the instances of this resource that you create in future."
          isOptional
          type={type === CustomResourceDataType.NUMBER ? "number" : "text"}
          backgroundVariant="gray-800"
          dataTestId={`default-value-input-${varIndex}`}
        />
      );
    case CustomResourceDataType.BOOLEAN:
      return (
        <SelectBox
          labelVariant="gray200-medium"
          name={`variables.[${varIndex}].defaultVal`}
          label="Default Value"
          description="Note: Default value will be applied to all the instances of this resource that you create in future."
          isOptional
          options={booleanOptions}
          backgroundVariant="gray-800"
          dataTestId={`default-value-input-${varIndex}`}
        />
      );
    default:
      return null;
  }
};
export default DefaultValueRendererForPrimitiveType;
