/* tslint:disable */
/* eslint-disable */
/**
 * Virtual Interaction and Recommendation Assistant - VIRA
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CloudProvider } from './CloudProvider';
import {
    CloudProviderFromJSON,
    CloudProviderFromJSONTyped,
    CloudProviderToJSON,
} from './CloudProvider';
import type { TopologyResourceConnection } from './TopologyResourceConnection';
import {
    TopologyResourceConnectionFromJSON,
    TopologyResourceConnectionFromJSONTyped,
    TopologyResourceConnectionToJSON,
} from './TopologyResourceConnection';
import type { TopologyResourceWithPosition } from './TopologyResourceWithPosition';
import {
    TopologyResourceWithPositionFromJSON,
    TopologyResourceWithPositionFromJSONTyped,
    TopologyResourceWithPositionToJSON,
} from './TopologyResourceWithPosition';

/**
 * 
 * @export
 * @interface Topology
 */
export interface Topology {
    /**
     * 
     * @type {string}
     * @memberof Topology
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Topology
     */
    appstackId?: string;
    /**
     * 
     * @type {Array<TopologyResourceWithPosition>}
     * @memberof Topology
     */
    resources?: Array<TopologyResourceWithPosition>;
    /**
     * 
     * @type {Array<TopologyResourceConnection>}
     * @memberof Topology
     */
    resourceConnections?: Array<TopologyResourceConnection>;
    /**
     * 
     * @type {CloudProvider}
     * @memberof Topology
     */
    cloudProvider?: CloudProvider;
    /**
     * 
     * @type {string}
     * @memberof Topology
     */
    deploymentType?: string;
    /**
     * 
     * @type {object}
     * @memberof Topology
     */
    resourceTags?: object;
}

/**
 * Check if a given object implements the Topology interface.
 */
export function instanceOfTopology(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TopologyFromJSON(json: any): Topology {
    return TopologyFromJSONTyped(json, false);
}

export function TopologyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Topology {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'appstackId': !exists(json, 'appstackId') ? undefined : json['appstackId'],
        'resources': !exists(json, 'resources') ? undefined : ((json['resources'] as Array<any>).map(TopologyResourceWithPositionFromJSON)),
        'resourceConnections': !exists(json, 'resourceConnections') ? undefined : ((json['resourceConnections'] as Array<any>).map(TopologyResourceConnectionFromJSON)),
        'cloudProvider': !exists(json, 'cloudProvider') ? undefined : CloudProviderFromJSON(json['cloudProvider']),
        'deploymentType': !exists(json, 'deploymentType') ? undefined : json['deploymentType'],
        'resourceTags': !exists(json, 'resourceTags') ? undefined : json['resourceTags'],
    };
}

export function TopologyToJSON(value?: Topology | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'appstackId': value.appstackId,
        'resources': value.resources === undefined ? undefined : ((value.resources as Array<any>).map(TopologyResourceWithPositionToJSON)),
        'resourceConnections': value.resourceConnections === undefined ? undefined : ((value.resourceConnections as Array<any>).map(TopologyResourceConnectionToJSON)),
        'cloudProvider': CloudProviderToJSON(value.cloudProvider),
        'deploymentType': value.deploymentType,
        'resourceTags': value.resourceTags,
    };
}

